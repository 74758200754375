import { TerrainSizeProps } from "@/types";
import "./TerrainSize.css";
import { useState, useEffect, useImperativeHandle, forwardRef } from "react";
import TerrainAdjustment from "@/components/TerrainAdjustment";
import questionLineIcon from "@/assets/mapgen/questionLine.svg";

interface TerrainSizeOption {
  size: number;
  label: string;
  iconSize: string; // 用于控制图标大小的类名
}

function TerrainSize(
  {
    isDisable,
    serialNumber,
    callback,
    options = [
      { size: 500, label: "500 * 500m", iconSize: "w-7 h-7" },
      { size: 5000, label: "5000 * 5000m", iconSize: "w-10 h-10" },
    ],
    defaultSize = 5000,
    tourHandler,
  }: TerrainSizeProps & {
    options?: TerrainSizeOption[];
    defaultSize?: number;
    toggleHandler?: () => void;
  },
  ref: React.ForwardedRef<unknown>
) {
  const [active, setActive] = useState<number>(defaultSize);
  const [showOptions, setShowOptions] = useState(true);
  const is_3d = location.href.includes("create3d") ? true : false;

  useEffect(() => {
    setActive(defaultSize);
  }, [defaultSize]);

  useImperativeHandle(ref, () => ({
    showOption: () => {
      setShowOptions(true);
    },
  }));

  const clickHandler = (size: number) => {
    if (isDisable) return;
    setActive(size);
    callback(size);
  };

  return (
    <div className="bg p-6 rounded-md">
      <div className="flex justify-between text-lg mb-3">
        <div className="flex">
          <span className="serial-number mr-2">{serialNumber}</span>
          {!is_3d ? <h1>Choose map size</h1> : <h1>Advanced Options</h1>}
        </div>
        <img
          className="cursor-pointer"
          onClick={() => tourHandler?.()}
          src={questionLineIcon}
        />
      </div>
      {!is_3d && (
        <div className="flex mt-4 flex-wrap gap-4">
          {options.map((option) => (
            <div
              key={option.size}
              className={`terrain-size ${
                isDisable ? "cursor-not-allowed" : "cursor-pointer"
              } ${active === option.size ? "active" : ""}`}
              onClick={() => clickHandler(option.size)}
            >
              <div className={`${option.iconSize} mr-2`}></div>
              <span className="text-xs">{option.label}</span>
            </div>
          ))}
        </div>
      )}
      {is_3d && (
        <>
          <div
            className="flex items-center space-x-2 link mt-5 cursor-pointer options-toggle"
            onClick={() => {
              setShowOptions(!showOptions);
            }}
          >
            {/* <span>
              {showOptions ? "Hide advanced options" : "Show advanced options"}
            </span>
            <span className="scale-50">{showOptions ? "▼" : "▶"}</span> */}
          </div>
          <TerrainAdjustment
            showOptions={showOptions}
            onClose={() => setShowOptions(false)}
          />
        </>
      )}
    </div>
  );
}

export default forwardRef(TerrainSize);

import { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { DrawBoardRef } from "@/types";
import { message } from "antd";
import TagSelector from "@/components/TagSelector";
import DrawingBoard from "@/components/DrawingBoard/DrawingBoard";
import Warning from "@/components/Warning/Warning";
import backIcon from "@/assets/back.png";
import "./LabTag.css";
import { gen2DMap, gen3DMap } from "@/service/taskService";
import { blobToBase64, convertToString, loadBlobImage } from "@/lib/utils";



const generateBtnClass = "w-full mt-2 bg-gradient-to-r from-[#5FB3CE] to-[#132976] border-white rounded-md py-1 text-white";
const No3DMapText = "w-[512px] h-[512px] flex items-center justify-center text-white opacity-20";

function LabTag() {
    const drawBoardRef = useRef<DrawBoardRef>(null);
    const [mapView, setMapView] = useState<'2d' | '3d'>('2d');
    const [generateLoading, setGenerateLoading] = useState(false);
    const [is3DLoading, setIs3DLoading] = useState(false);
    const [img3dUrl, setImg3dUrl] = useState('');
    const [img2dUrl, setImg2dUrl] = useState<Blob | null>(null);
    const [warningVisible, setWarningVisible] = useState(false);
    const [selectedData, setSelectedData] = useState<any>(null);

    const generateMap = async (selectedData: any) => {
        if (!selectedData || selectedData.length === 0) {
            message.error('Please select at least one tag');
            return;
        }

        setSelectedData(selectedData);
        
        if(img2dUrl || img3dUrl){
            setWarningVisible(true);
            return;
        }

        if (mapView === '2d') {
            await handleGenerate2D(selectedData);
        } else {
            setGenerateLoading(true);
            try {
                const blob = await handleGenerate2D(selectedData);
                const base64 = await blobToBase64(blob);
                await handleGenerate3D(base64);
            } finally {
                setGenerateLoading(false);
            }
        }
    }

    const handleGenerate2D = async (selectedData: any) => {
        try {
            if (mapView === '2d') {
                setGenerateLoading(true);
            }
            let tags = convertToString(selectedData);
            const image = await gen2DMap(tags);
            setImg2dUrl(image.data);

            if (!image || !image.data) {
                message.error('Image generation failed');
                return;
            }

            const imgElement = await loadBlobImage(image.data);
            drawBoardRef.current?.boardDrawImage(imgElement);
            drawBoardRef.current?.addChooseImage();

            return image.data;
        } catch (err) {
            console.error('Generation error:', err);
            message.error('An error occurred during generation');
            return false;
        } finally {
            if (mapView === '2d') {
                setGenerateLoading(false);
            }
        }
    }

    const handleGenerate3D = async (base64?: string) => {
        setIs3DLoading(true);
        try {
            const data = {
                "imgpath": "",
                "imgcontent": mapView === "2d" ? drawBoardRef.current?.getImageData() : base64,
                "streaming": true
            }

            const res = await gen3DMap(data);
            if (res && res.data) {
                const base64Data = URL.createObjectURL(res.data);
                setImg3dUrl(base64Data);
                setMapView('3d');
            } else {
                message.error('3D image generation failed');
            }
        } catch (err) {
            console.error('3D generation error:', err);
            message.error('An error occurred during 3D generation');
        } finally {
            setIs3DLoading(false);
        }
    }

    const handleWarningCancel = () => {
        setWarningVisible(false);
    }

    const handleWarningContinue = async () => {
        setWarningVisible(false);
        setImg3dUrl('');
        setImg2dUrl(null);

        if (mapView === '2d') {
            await handleGenerate2D(selectedData);
        } else {
            setGenerateLoading(true);
            try {
                const blob = await handleGenerate2D(selectedData);
                const base64 = await blobToBase64(blob);
                await handleGenerate3D(base64);
            } finally {
                setGenerateLoading(false);
            }
        }
    }

    const handleViewChange = async (view: '2d' | '3d') => {
        setMapView(view);
        if(view === "2d" && img2dUrl){
            const imgElement = await loadBlobImage(img2dUrl);
            drawBoardRef.current?.boardDrawImage(imgElement);
            drawBoardRef.current?.addChooseImage();
        }
    }

    return (
        <div className="lab-tag">
            <div className=" flex items-center">
                <Link to="/labs" className="flex items-center cursor-pointer">
                    <img src={backIcon} alt="back" width={14} height={14} />
                    <span className="ml-2 text-link">Back</span>
                </Link>
                <h2 className=" ml-10">Tag to map</h2>
            </div>
            <div className="flex mt-10">
                <div className="flex flex-1 flex-col">
                    <TagSelector
                        serialNumber="1"
                        isDisable={false}
                        generateHandler={(selectedData) => { generateMap(selectedData) }}
                        isLoading={mapView === '2d' ? generateLoading : (generateLoading || is3DLoading)}
                    />
                </div>
                <div className="">
                    <div className="board sm:ml-6 relative">
                        <div className="flex justify-center mb-2">
                            <button
                                className={`px-4 py-1 rounded-l-full ${mapView === '2d'
                                    ? 'bg-gradient-to-r from-[#5FB3CE] to-[#132976] text-white'
                                    : 'bg-white bg-opacity-20 '
                                    }`}
                                onClick={() => handleViewChange('2d')}
                            >
                                2D map
                            </button>
                            <button
                                className={`px-4 py-1 rounded-r-full ${mapView === '3d'
                                    ? 'bg-gradient-to-r from-[#5FB3CE] to-[#132976] text-white'
                                    : 'bg-white bg-opacity-20 '
                                    }`}
                                onClick={() => handleViewChange('3d')}
                            >
                                3D map
                            </button>
                        </div>
                        {mapView === '2d' ? (
                            <>
                                <DrawingBoard
                                    ref={drawBoardRef}
                                    imageListIndex={() => { }}
                                    disableBrush={false}
                                    taskTempStatus={360}
                                    coord={[]}
                                    switchImg={() => { }}
                                    loadImage={() => { }}
                                />
                                <button
                                    className={generateBtnClass} disabled={generateLoading || is3DLoading}
                                    onClick={() => handleGenerate3D()}>
                                    {is3DLoading ? 'Generating...' : 'Generate 3D'}
                                </button>
                            </>
                        ) : (
                            img3dUrl ? <img src={img3dUrl} alt="" width={512} height={512} /> :
                                <span className={No3DMapText}>No 3D map generated</span>
                        )}
                    </div>

                </div>
            </div>
            <Warning
                isVisible={warningVisible}
                onCancel={handleWarningCancel}
                onContinue={() => handleWarningContinue()}
                text="Current operation will lose the generated image result"
            />
        </div>
    );
}

export default LabTag;
import ThreeScene from "@/components/ThreeScene/ThreeScene";
import Model3DTaskProgress from "@/components/Model3DTaskProgress";

interface ModelPreviewProps {
    currentTaskStatus: number;
    isModelFullscreen: boolean;
    modelUrl: string;
    size: number;
    from: string;
    task_id: string;
    onComplete: (isComplete: boolean, status: number, duration?: number) => void;
}

const ModelPreview: React.FC<ModelPreviewProps> = ({
    currentTaskStatus,
    isModelFullscreen,
    modelUrl,
    size,
    from,
    task_id,
    onComplete
}) => {
    const getCameraPosition = () => {
        console.log(from, size);

        const cameraPositions = from !== "mapgen"
            ? [
                [500, 400],
                [750, 600],
                [Infinity, 800]
            ]
            : [
                [700, 600],
                [1000, 800],
                [2000, 1600],
                [3000, 2200],
                [4000, 2800],
                [5000, 3200]
            ];

        const position = cameraPositions.find(([threshold]) => size <= threshold);
        return position ? position[1] : cameraPositions[cameraPositions.length - 1][1];
    };

    if (currentTaskStatus >= 210 && modelUrl) {
        return (
            <ThreeScene
                isFull={isModelFullscreen}
                modelUrl={modelUrl}
                cameraAngle={75}
                cameraPosition={getCameraPosition()}
            />
        );
    }

    if (currentTaskStatus > 0 && currentTaskStatus < 210) {
        return (
            <div className="flex justify-center items-center flex-col text-sm h-full select-none">
                <Model3DTaskProgress
                    taskId={task_id}
                    onComplete={(isSuccess: boolean, status: number, duration: number) => { onComplete(isSuccess, status, duration) }}
                />
            </div>
        );
    }

    return (
        <div className="flex justify-center items-center flex-col text-sm h-full select-none">
            <span>
                {currentTaskStatus > 240 && currentTaskStatus < 250
                    ? "3D model generation failed"
                    : "Loading..."}
            </span>
        </div>
    );
};

export default ModelPreview;
import { MapgenTypeEnums } from "@/types";

// export const MAP_STYLE_TERRAIN: Record<string, number> = {
//   "Forest Wetland": 0,
//   "Snow Mountain": 1,
//   "Gobi Desert": 2,
// };

// export const MAP_STYLE_TOWN: Record<string, number> = {
//   "Water Village": 0,
//   "Medieval Village": 1,
//   "Wooden House": 2,
//   "Farm House": 3,
// };

// 地形类型枚举
export enum TerrainType {
  FOREST_WETLAND = 0,
  SNOW_MOUNTAIN = 1,
  GOBI_DESERT = 2
}

// 城镇类型枚举
export enum TownType {
  WATER_VILLAGE = 0,
  MEDIEVAL_VILLAGE = 1,
  WOODEN_HOUSE = 2,
  FARM_HOUSE = 3
}

// 地形名称映射
export const TERRAIN_NAMES: Record<number, string> = {
  [TerrainType.FOREST_WETLAND]: "Forest Wetland",
  [TerrainType.SNOW_MOUNTAIN]: "Snow Mountain",
  [TerrainType.GOBI_DESERT]: "Gobi Desert"
};

// 城镇名称映射
export const TOWN_NAMES: Record<number, string> = {
  [TownType.WATER_VILLAGE]: "Water Village",
  [TownType.MEDIEVAL_VILLAGE]: "Medieval Village",
  [TownType.WOODEN_HOUSE]: "Wooden House",
  [TownType.FARM_HOUSE]: "Farm House"
};


export const ZOOM_5000 = 0.208;

export const ZOOM_1000 = 1.04;

export const ZOOM_750 = 1.38;

export const ZOOM_500 = 2;

export enum MapSize {
  TINY = 500,
  SMALL = 750,
  MEDIUM = 1000,
  LARGE = 5000
}
export const DEFAULT_MAP_SIZE = 1000;

export const MAP_ZOOM: Record<number, number> = {
  [MapSize.TINY]: ZOOM_500,
  [MapSize.SMALL]: ZOOM_750,
  [MapSize.MEDIUM]: ZOOM_1000,
  [MapSize.LARGE]: ZOOM_5000,
}

export const MAP_ZOOM_SIZE: Record<number, MapgenTypeEnums> = {
  [MapSize.TINY]: MapgenTypeEnums.xtiny,
  [MapSize.SMALL]: MapgenTypeEnums.tiny,
  [MapSize.MEDIUM]: MapgenTypeEnums.tiny,
  [MapSize.LARGE]: MapgenTypeEnums.medium,
}
import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getTaskList, getVillageFile } from '@/service/taskService';
import { Task } from "@/types";
import { getLocalToken, reverseSort } from "@/lib/utils";
import { message } from 'antd';
import { AxiosResponse } from 'axios';
import TaskCard from '@/components/TaskCard/TaskCard';
import { MapStyleTerrain, mapStyleTown } from "@/types";
import "./Home.css";
import addIcon from "@/assets/add.png";

function Home() {
    const navigate = useNavigate();
    const [tasks, setTasks] = useState<Array<Task>>([]);
    const [showModal, setShowModal] = useState(0);

    useEffect(() => {
        getTasks();
    }, []);

    const getTasks = async () => {
        try {
            let response: AxiosResponse = await getTaskList();
            let sortTasks: Array<Task> = reverseSort(response.data.tasks);
            const firstThree = sortTasks.slice(0, 3);
            setTasks(firstThree);

        } catch (error: any) {
            if (error.status === 403) {
                setShowModal(showModal + 1);
            } else {
                message.error("Failed to get the task list or failed to get the village coordinates.");
            }
        }
    }

    const viewHandle = (task_id: string, status: number) => {
        if (status === 241 || status === 242) {
            message.warning("Model generation failed and cannot be previewed!");
            return
        }
        localStorage.setItem('task_id', task_id);
        const navigationParams = {
            param: "home",
            task_id: task_id
        };
        getVillageFile(task_id).then((res) => {
            // 创建反向映射
            const terrainKeyByValue = Object.entries(MapStyleTerrain)
                .reduce((acc, [key, value]) => ({ ...acc, [value]: key }), {} as Record<number, string>);

            const townKeyByValue = Object.entries(mapStyleTown)
                .reduce((acc, [key, value]) => ({ ...acc, [value]: key }), {} as Record<number, string>);

            if (res.data.map_type === 2) {
                Object.assign(navigationParams, {
                    from: "mapgen",
                    size: res.data.map_size,
                    biome: terrainKeyByValue[res.data.map_style_terrain],
                    town: townKeyByValue[res.data.map_style_town]
                });
            } else {
                Object.assign(navigationParams, {
                    from: "myscenes",
                    size: res.data.map_size
                });
            }
            console.log(navigationParams);
            
            navigate("/home/preview", { state: navigationParams });
        }).catch((error) => {
            Object.assign(navigationParams, {
                from: "myscenes",
                size: 1000
            });
            navigate("/home/preview", { state: navigationParams });
            console.log(error);
        });
    }
    return (
        <section>
            <div className="flex justify-between mb-4">
                <span>Home</span>
                {/* <input type="text" /> */}
            </div>
            <div className='flex space-x-6'>
                <Link to="/home/scene"
                    replace
                    className="flex flex-col justify-center items-center w-[17rem] h-[17rem] rounded-md create cursor-pointer">
                    <img src={addIcon} alt="" width={24} height={24} />
                    <span className=" text-lg">Create a new scene</span>
                </Link>
                {
                    tasks.length > 0 && tasks.map(task => (<TaskCard
                        key={task.task_id}
                        status={task.status}
                        taskID={task.task_id}
                        imgSrc={import.meta.env.VITE_APP_BASE_URL + import.meta.env.VITE_APP_ASSET_FILE + task.task_id + `/mini_map.png?token=${getLocalToken()}`}
                        viewClick={() => { viewHandle(task.task_id, task.status) }}
                        deleteClick={() => { }}
                        coord={task.coord}
                        pageType='home'
                    />))
                }
            </div>
        </section>
    );
};

export default Home;
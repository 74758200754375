import { message } from "antd";
import UEScene from "../UEScene/UEScene";
import UETaskProgress from "../UETaskProgress";
import { forwardRef, Ref, useImperativeHandle, useRef } from "react";
import { UeplayerRefProps } from "@/types";


interface UEPreviewProps {
    taskId: string;
    status: number;
    streamID: string;
    onComplete: (isSuccess: boolean, status: number, streamID: string) => void;
    startTime: number;
    reconnect: (status: number) => void;
    quickGenerate: () => void;
}

function UEPreview({ taskId, status, streamID, onComplete, startTime, reconnect, quickGenerate }: UEPreviewProps, ref: Ref<UeplayerRefProps>) {
    const wrapperClass = "flex justify-center items-center flex-col text-sm h-full select-none text-[#5E5E5E]";
    const ueplayerRef = useRef<UeplayerRefProps>(null);

    useImperativeHandle(ref, () => ({
        getUeplayerRef: () => {
            return ueplayerRef.current?.getUeplayerRef() || null;
        }
    }))

    if ((status > 210 && status < 240) || (status > 250 && status < 360)) {
        return (
            <div className={wrapperClass}>
                <UETaskProgress taskId={taskId} onComplete={(isSuccess: boolean, status: number, streamID: string) => { onComplete(isSuccess, status, streamID) }} startTime={startTime} />
            </div>
        );
    }

    if (status === 360) {
        return <UEScene ref={ueplayerRef} taskId={taskId} callback={(status: number) => { reconnect(status)}} streamID={streamID} />;
    }

    if (status >= 370 && status < 380) {
        message.error({
            content: "An error occurred while generating the UE scene.",
            duration: 10
        });
        return (
            <div className={wrapperClass}>
                <div>An error occurred while generating the UE scene.</div>
            </div>
        );
    }

    if (status === 900) {
        return (
            <div className={wrapperClass}>
                <button
                    className="rounded-sm text-black px-2 py-1 bg-white hover:bg-gray-200"
                    onClick={quickGenerate}>
                    Generate scenes quickly
                </button>
            </div>
        );
    }

    if (status === 1000) {
        return (
            <div className={wrapperClass}>
                <div>Closing...</div>
            </div>
        );
    }

    return (
        <div className={wrapperClass}>
            <span>Click "Generate Environment" to generate the scene</span>
        </div>
    );
}

export default forwardRef(UEPreview);

import { Link } from "react-router-dom";
import backIcon from "@/assets/back.png";
import TagSelector from "@/components/TagSelector";

function VillageLayout() {
    return (
        <div>
            <div className=" flex items-center">
                <Link to="/labs" className="flex items-center cursor-pointer">
                    <img src={backIcon} alt="back" width={14} height={14} />
                    <span className="ml-2 text-link">Back</span>
                </Link>
                <h2 className=" ml-10">Village Layout</h2>
            </div>
            <div className="flex mt-10">
                <div className="flex flex-1 flex-col">
                    <TagSelector
                        serialNumber="1"
                        isDisable={false}
                        generateHandler={(selectedData) => { 
                            console.log(selectedData);
                         }}
                        isLoading={false}
                    />
                </div>
                <div className=" w-[600px] p-5 ml-5 bg-white bg-opacity-10 rounded-md">
                    <img src="/image/image.png" alt="tag-to-layout" />
                </div>
            </div>
        </div>
    );
}

export default VillageLayout;
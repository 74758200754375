interface MapPreviewProps {
    mapURL: string;
    size: number;
    from?: string;
    biome?: string;
    task_id: string;
}

const MapPreview = ({ mapURL, size, from, biome, task_id }: MapPreviewProps) => {
    return (
        <>
            <div className="flex justify-between relative">
                {mapURL && <img src={mapURL} alt="" width={502} height={502} />}
            </div>
            <div>
                <ul className="text-white opacity-50 mt-2 text-sm">
                    <li>Size: {size}</li>
                    {from === "mapgen" ? (
                        <>
                            <li>Biome: {biome}</li>
                        </>
                    ) : null}
                    <li>Task: {task_id}</li>
                </ul>
            </div>
        </>
    );
};

export default MapPreview;
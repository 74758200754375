// 消息类型定义
export enum WorkerMessageType { 
    INIT = 'init',
    GENERATE = 'generate',
    UPDATE = 'update',
    PAINT = 'paint',
    ERROR = 'error'
}

// 基础消息接口
export interface WorkerMessage {
    type: WorkerMessageType;
    payload: any;
}

// 生成地图的参数接口
export interface GeneratePayload {
    seed: number;
    width: number;
    height: number;
    config: {
        elevation: {
            min: number;
            max: number;
            waterThreshold: number;
        };
        noise: {
            persistence: number;
            octaves: number;
        };
        mountain: {
            scale: { x: number; y: number };
            offset: { x: number; y: number };
        };
    };
}

// 更新操作的参数接口
export interface UpdatePayload {
    x: number;
    y: number;
    value: number;
    radius: number;
}

// 错误消息接口
export interface ErrorPayload {
    code: string;
    message: string;
}
export const mapOptions = [
    {
        id: 'Forest Wetland',
        image: '/image/Forest-Wetland.png',
        text: 'Forest Wetland'
    },
    {
        id: 'Snow Mountain',
        image: '/image/Snow-Mountain.png',
        text: 'Snow Mountain'
    },
    {
        id: 'Gobi Desert',
        image: '/image/Gobi-Desert.png',
        text: 'Gobi Desert'
    }
];

export const townOptions = [  // 改用小驼峰命名更符合JS/TS规范
    {
        id: "sdf412fgd34fgf",
        image: '/image/case.png',
        text: 'Water Village'
    },
    {
        id: "34df45sdfsdfgd3",
        image: '/image/case.png',
        text: 'Medieval Village'
    },
    {
        id: "34df453dsfsd34",
        image: '/image/case.png',
        text: 'Wooden House'
    },
    {
        id: "sdfsd34fdg4fdgfd",
        image: '/image/case.png',
        text: 'Farm House'
    }
];

// 转换标题格式和编号
export function transformTitle(title: string): string {
    const titleMap: { [key: string]: string } = {
        'Showcase 5': 'Scene 1',
        'Showcase 1': 'Scene 2',
        'Showcase 4': 'Scene 3',
        'Showcase 2': 'Scene 4',
        'Showcase 3': 'Scene 5'
    };
    
    const originalTitle = title;
    if (titleMap[originalTitle]) {
        return titleMap[originalTitle];
    }
    
    return title.replace(/Showcase/i, 'Scene');
}

// 自定义排序函数
export function customSort(data: any[]) {
    const orderMap: { [key: string]: number } = {
        'Scene 1': 1,  // 原 Showcase 5
        'Scene 2': 2,  // 原 Showcase 1
        'Scene 3': 3,  // 原 Showcase 4
        'Scene 4': 4,  // 原 Showcase 2
        'Scene 5': 5   // 原 Showcase 3
    };
    
    return data.sort((a: any, b: any) => {
        const orderA = orderMap[a.title] || 999;
        const orderB = orderMap[b.title] || 999;
        
        if (orderA !== orderB) {
            return orderA - orderB;
        }
        
        // 对于没有特定顺序的项目，按标题排序
        return a.title.localeCompare(b.title);
    });
}

export const sortByIndex = (list: any[]) => {
  return [...list].sort((a, b) => a.index - b.index);
};
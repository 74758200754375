import { useNavigate } from "react-router-dom";

interface LabsCardProps {
  title: string;
  description: string;
  imageUrl: string;
  routePath: string;
}

function LabsCard({ 
  title,
  description,
  imageUrl,
  routePath
}: LabsCardProps) {
  const navigate = useNavigate();

  return (
    <div 
      className="labs-card rounded-3xl py-6 w-[240px] bg-white bg-opacity-10"
    >
      <div className="flex flex-col items-center">
        <img 
          src={imageUrl} 
          alt={title}
          className="w-[180px] h-[180px] rounded-lg"
        />
        <h3 className="w-[180px] text-lg p-0 mt-4">{title}</h3>
        <p className="w-[180px] h-[40px] text-sm p-0 mt-2">{description}</p>
        <button 
          className="text-sm w-[180px] border border-white rounded-md px-4 py-1 hover:bg-white hover:bg-opacity-10 transition-all mt-8"
          onClick={() => navigate(routePath)}  
        >
          Try it now
        </button>
      </div>
    </div>
  );
}

export default LabsCard;
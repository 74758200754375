import MapGen from "../../index.ts";
import {
  INITIAL_POSITION,
  MAP_BOUNDARY_LIMIT,
  ZOOM_FACTOR,
} from "../../const/common.ts";
class DragOperations {
  private position: { x: number; y: number } = { ...INITIAL_POSITION };
  private onMouseMoveCallback?: (x: number, y: number) => void;
  private mapGen: MapGen;
  constructor(mapGen: MapGen, private mapElement: HTMLElement) {
    this.mapGen = mapGen;
    this.mapElement.addEventListener("mousedown", this.onMouseDown.bind(this));
  }

  private onMouseDown(event: MouseEvent) {
    if (this.mapGen.isEdit()) return;
    event.preventDefault();
    const { x, y } = this.position;
    //const { x, y } = this.mapGen.getRender().getMousePosition();

    const clientX = event.clientX;
    const clientY = event.clientY;

    document.body.style.cursor = "grabbing";

    const onMouseMove = (moveEvent: MouseEvent) => {
      const zoom = this.mapGen.getRender().getZoom();
      const mapBoundarie = this.mapGen
        .getRender()
        .linearRegression(ZOOM_FACTOR / zoom);
      let deltaX = moveEvent.clientX - clientX;
      let deltaY = moveEvent.clientY - clientY;

      // @ts-ignore
      let rotate_deg =
        this.mapGen.getPlugins().get("sliders")?.getValue("rotate_deg") || 0;

      // RotateMat(-rotate_deg) * [dx, dy] 进行修正

      const rad = ((180 - rotate_deg) * Math.PI) / 180;
      const sinC = Math.sin(rad);
      const cosC = Math.cos(rad);
      const [dx, dy] = [
        -(deltaX * cosC - deltaY * sinC),
        -(deltaX * sinC + deltaY * cosC),
      ];

      // mapgen对应坐标
      this.position.x = Math.min(
        -mapBoundarie,
        Math.max(-(MAP_BOUNDARY_LIMIT - mapBoundarie), dx + x)
      );
      this.position.y = Math.min(
        -mapBoundarie,
        Math.max(-(MAP_BOUNDARY_LIMIT - mapBoundarie), dy + y)
      );

      this.onMouseMoveCallback?.(this.position.x, this.position.y);
      // this.onMouseMoveCallback?.(dx + x, dy + y);
    };

    const onMouseUp = () => {
      document.body.style.cursor = "default";
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };

    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);
  }

  public onMouseMove(callback: (x: number, y: number) => void) {
    this.onMouseMoveCallback = callback;
  }

  public dispose() {
    this.mapElement.removeEventListener(
      "mousedown",
      this.onMouseDown.bind(this)
    );
  }
}

export default DragOperations;

export const SHADER_SOURCE = {
    rivers: {
        frag: `
            precision mediump float;
            uniform sampler2D u_rivertexturemap;
            varying vec2 v_uv;
            const vec3 blue = vec3(0.2, 0.5, 0.7);
            void main() {
            vec4 color = texture2D(u_rivertexturemap, v_uv);
            gl_FragColor = vec4(blue * color.a, color.a);
            // gl_FragColor = color;
        }`,
    //定点着色器，定义每个定点的位置
        vert: `
            precision highp float;
            uniform mat4 u_projection;
            attribute vec4 a_xyuv;
            varying vec2 v_uv;
            void main() {
            v_uv = a_xyuv.ba;
            gl_Position = vec4(u_projection * vec4(a_xyuv.xy, 0, 1));
        }`,
    },
    land: {
        frag: `
            precision highp float;
            uniform sampler2D u_water; 
            uniform float u_outline_water;
            varying float v_e;
            varying vec2 v_xy;
            uniform float export_value;

            void main() {
            float e = 0.5 * (1.0 + v_e);
            float river = texture2D(u_water, v_xy).a;
            if (e >= 0.5) {
                float bump = u_outline_water / 256.0;
                float L1 = e + bump;
                float L2 = (e - 0.5) * (bump * 100.0) + 0.5;
                // TODO: simplify equation
                e = min(L1, mix(L1, L2, river));
            }
            if (export_value>0.5){
                gl_FragColor = vec4(e, e, e, 1);
            } else {
                gl_FragColor = vec4(fract(256.0*e), e , 0, 1);
                //gl_FragColor = vec4(0.8 + 0.2 * fract(256.0 * e), 0.8 + 0.2 * e, 0, 1);
            }
            // NOTE: it should be using the floor instead of rounding, but
            // rounding produces a nice looking artifact, so I'll keep that
            // until I can produce the artifact properly (e.g. bug → feature).
            // Using linear filtering on the texture also smooths out the artifacts.
            //  gl_FragColor = vec4(fract(256.0*e), floor(256.0*e)/256.0, 0, 1);
            // NOTE: need to use GL_NEAREST filtering for this texture because
            // blending R,G channels independently isn't going to give the right answer
            }`,

            vert: `
                precision highp float;
                uniform mat4 u_projection;
                attribute vec2 a_xy;
                attribute vec2 a_em; // NOTE: moisture channel unused
                varying float v_e;
                varying vec2 v_xy;
                void main() {
                    vec4 pos = vec4(u_projection * vec4(a_xy, 0, 1));
                    v_xy = (1.0 + pos.xy) * 0.5;
                    v_e = a_em.x;
                    gl_Position = pos;
            }`,
    },
    depth: {
        frag: `
            precision highp float;
            varying float v_z;
            void main() {
            gl_FragColor = vec4(fract(256.0*v_z), floor(256.0*v_z)/256.0, 0, 1);
            }`,

        vert: `
            precision highp float;
            uniform mat4 u_projection;
            attribute vec2 a_xy;
            attribute vec2 a_em;
            varying float v_z;
            void main() {
                vec4 pos = vec4(u_projection * vec4(a_xy, max(0.0, a_em.x), 1));
                v_z = a_em.x;
                gl_Position = pos;
            }`,
    },
    drape: {
        frag: `
            precision highp float;
            uniform sampler2D u_colormap;
            uniform sampler2D u_mapdata;
            uniform sampler2D u_water;
            uniform sampler2D u_depth;
            uniform vec2 u_light_angle;
            uniform float u_inverse_texture_size, 
                        u_slope, u_flat,
                        u_ambient, u_overhead,
                        u_outline_strength, u_outline_coast, u_outline_water,
                        u_outline_depth, u_outline_threshold,
                        u_biome_colors;
            varying vec2 v_uv, v_xy, v_em;
            varying float v_z;

            const vec2 _decipher = vec2(1.0/256.0, 1);
            float decipher(vec4 v) {
            return dot(_decipher, v.xy);
            }

            const vec3 neutral_land_biome = vec3(0.9, 0.8, 0.7);
            const vec3 neutral_water_biome = 0.8 * neutral_land_biome;

            void main() {
            vec2 sample_offset = vec2(0.5*u_inverse_texture_size, 0.5*u_inverse_texture_size);
            vec2 pos = v_uv + sample_offset;
            vec2 dx = vec2(u_inverse_texture_size, 0),
                    dy = vec2(0, u_inverse_texture_size);

            float zE = decipher(texture2D(u_mapdata, pos + dx));
            float zN = decipher(texture2D(u_mapdata, pos - dy));
            float zW = decipher(texture2D(u_mapdata, pos - dx));
            float zS = decipher(texture2D(u_mapdata, pos + dy));
            vec3 slope_vector = normalize(vec3(zS-zN, zE-zW, u_overhead*2.0*u_inverse_texture_size));
            vec3 light_vector = normalize(vec3(u_light_angle, mix(u_slope, u_flat, slope_vector.z)));

            //计算光照强度
            float light = u_ambient + max(0.0, dot(light_vector, slope_vector));
            vec2 em = texture2D(u_mapdata, pos).yz;
            em.y = v_em.y;
            vec3 neutral_biome_color = neutral_land_biome;
            vec4 water_color = texture2D(u_water, pos);
            if (em.x >= 0.5 && v_z >= 0.0) {
                // on land, lower the elevation around rivers
                em.x -= u_outline_water / 256.0 * (1.0 - water_color.a); 
            } else {
                // in the ocean, or underground, don't draw rivers
                water_color.a = 0.0; neutral_biome_color = neutral_water_biome; 
            }
            vec3 biome_color = texture2D(u_colormap, em).rgb;
            water_color = mix(vec4(neutral_water_biome * (1.2 - water_color.a), water_color.a), water_color, u_biome_colors);
            biome_color = mix(neutral_biome_color, biome_color, u_biome_colors);
            if (v_z < 0.0) {
                // at the exterior boundary, we'll draw soil or water underground
                float land_or_water = smoothstep(0.0, -0.001, v_em.x - v_z);
                vec3 soil_color = vec3(0.4, 0.3, 0.2);
                vec3 underground_color = mix(soil_color, mix(neutral_water_biome, vec3(0.1, 0.1, 0.2), u_biome_colors), land_or_water) * smoothstep(-0.7, -0.1, v_z);
                vec3 highlight_color = mix(vec3(0, 0, 0), mix(vec3(0.8, 0.8, 0.8), vec3(0.4, 0.5, 0.7), u_biome_colors), land_or_water);
                biome_color = mix(underground_color, highlight_color, 0.5 * smoothstep(-0.025, 0.0, v_z));
                light = 1.0 - 0.3 * smoothstep(0.8, 1.0, fract((v_em.x - v_z) * 20.0)); // add horizontal lines
            }
            // if (fract(em.x * 10.0) < 10.0 * fwidth(em.x)) { biome_color = vec3(0,0,0); } // contour lines

            // TODO: add noise texture based on biome

            // TODO: once I remove the elevation rounding artifact I can simplify
            // this by taking the max first and then deciphering
            float depth0 = decipher(texture2D(u_depth, v_xy)),
                    depth1 = max(max(decipher(texture2D(u_depth, v_xy + u_outline_depth*(-dy-dx))),
                                    decipher(texture2D(u_depth, v_xy + u_outline_depth*(-dy+dx)))),
                                decipher(texture2D(u_depth, v_xy + u_outline_depth*(-dy)))),
                    depth2 = max(max(decipher(texture2D(u_depth, v_xy + u_outline_depth*(dy-dx))),
                                    decipher(texture2D(u_depth, v_xy + u_outline_depth*(dy+dx)))),
                                decipher(texture2D(u_depth, v_xy + u_outline_depth*(dy))));
            float outline = 1.0 + u_outline_strength * (max(u_outline_threshold, depth1-depth0) - u_outline_threshold);

            // Add coast outline, but avoid it if there's a river nearby
            float neighboring_river = max(
                max(
                    texture2D(u_water, pos + u_outline_depth * dx).a,
                    texture2D(u_water, pos - u_outline_depth * dx).a
                ),
                max(
                    texture2D(u_water, pos + u_outline_depth * dy).a,
                    texture2D(u_water, pos - u_outline_depth * dy).a
                )
            );
            if (em.x <= 0.5 && max(depth1, depth2) > 1.0/256.0 && neighboring_river <= 0.2) { outline += u_outline_coast * 256.0 * (max(depth1, depth2) - 2.0*(em.x - 0.5)); }

            gl_FragColor = vec4(mix(biome_color, water_color.rgb, water_color.a) * light / outline, 1);
        }`,

        vert: `
            precision highp float;
            uniform mat4 u_projection;
            attribute vec2 a_xy;
            attribute vec2 a_em;
            varying vec2 v_em, v_uv, v_xy;
            varying float v_z;
            void main() {
                v_em = a_em;
                vec2 xy_clamped = clamp(a_xy, vec2(0, 0), vec2(1000, 1000));
                v_z = max(0.0, a_em.x); // oceans with e<0 still rendered at z=0
                if (xy_clamped != a_xy) { // boundary points
                    v_z = -0.5;
                    v_em = vec2(0.0, 0.0);
                }
                vec4 pos = vec4(u_projection * vec4(xy_clamped, v_z, 1));
                v_uv = a_xy / 1000.0;
                v_xy = (1.0 + pos.xy) * 0.5;
                gl_Position = pos;
        }`,

    },
    final: {
        frag: `
            precision mediump float;
            uniform sampler2D u_land_texture;
            uniform sampler2D u_final_texture;
            uniform sampler2D u_river_texture;
            uniform vec2 u_offset;
            varying vec2 v_uv;
            uniform float export_value;

            void main() {
            if (export_value>0.5){
                vec4 river = texture2D(u_river_texture, v_uv + u_offset);
                vec4 land = texture2D(u_land_texture, v_uv + u_offset);
                gl_FragColor = vec4(land.r,river.b,0,1);
                // gl_FragColor = texture2D(u_land_texture, v_uv + u_offset);
            }else{
                gl_FragColor = texture2D(u_final_texture, v_uv + u_offset);
            }
        }`,

        vert: `
            precision highp float;
            attribute vec2 a_uv;
            varying vec2 v_uv;
            void main() {
            v_uv = a_uv;
            gl_Position = vec4(2.0 * v_uv - 1.0, 0.0, 1.0);
        }`,
    }
}

import { useMemo, useState } from "react";
import { RandomImageProps } from "@/types";
import questionLineIcon from "@/assets/mapgen/questionLine.svg";
import "./RandomImage.css";

const BUTTON_BASE_CLASS = "mr-2 w-[4rem] h-[4rem] rounded-lg";
const ACTIVE_CLASS = "active";
const CURSOR_CLASS = (isDisabled: boolean) =>
  isDisabled ? "cursor-not-allowed" : "cursor-pointer";

function RandomImage({
  serialNumber,
  isDisable,
  clickImg,
  clickRandomBtn,
  imgList,
  tourHandler,
}: RandomImageProps) {
  const [activeBtn, setActiveBtn] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const is_3d = location.href.includes("create3d") ? true : false;

  const changeBtnActive = (id: string) => {
    setActiveBtn(id);
    clickImg(id);
  };

  const randomHandler = async () => {
    setIsLoading(true);
    await clickRandomBtn();
    setIsLoading(false);
  };

  const imageButtons = useMemo(() => {
    return imgList.map((image) => (
      <button
        disabled={isDisable || isLoading}
        key={image.id}
        className={`${BUTTON_BASE_CLASS} ${
          image.id === activeBtn ? ACTIVE_CLASS : ""
        } ${CURSOR_CLASS(isDisable || isLoading)}`}
        onClick={() => changeBtnActive(image.id)}
      >
        <img src={image.src} alt="" />
      </button>
    ));
  }, [imgList, isDisable, isLoading, activeBtn]);

  return (
    <div className="random p-6 rounded-md">
      <div className="flex justify-between text-lg mb-3">
        <div className="flex">
          <span className="serial-number mr-2">{serialNumber}</span>
          {is_3d ? (
            <h1>Generate a random map</h1>
          ) : (
            <h1>Choose a map from the templates</h1>
          )}
        </div>
        {tourHandler ? (
          <img
            className="cursor-pointer"
            onClick={() => tourHandler?.()}
            src={questionLineIcon}
          />
        ) : null}
      </div>
      {/* <h3>Choose a map from library</h3> */}
      <div className="flex justify-between w-full">
        {!is_3d && (
          <section className="flex img-container">{imageButtons}</section>
        )}
        <section className="fex-1 flex items-end">
          <button
            className={`random-btn mt-4 ${
              !isDisable && !isLoading
                ? "hover:bg-[rgba(255,255,255,0.2)] cursor-pointer"
                : " cursor-not-allowed"
            }`}
            disabled={isDisable || isLoading}
            onClick={randomHandler}
          >
            {isLoading ? "loading..." : "Random"}
          </button>
        </section>
      </div>
    </div>
  );
}

export default RandomImage;

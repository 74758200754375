export function convertImageDataToBase64(imageData: ImageData): string {
    const tempCanvas = document.createElement('canvas');
    tempCanvas.width = imageData.width;
    tempCanvas.height = imageData.height;
    const ctx = tempCanvas.getContext('2d');
    ctx?.putImageData(imageData, 0, 0);
    return tempCanvas.toDataURL('image/png').split(',')[1];
}

export function processImage(imageData: ImageData): { mask1: ImageData, mask2: ImageData } {
    const width = imageData.width;
    const height = imageData.height;

    const mask1 = new ImageData(width, height);
    const mask2 = new ImageData(width, height);

    for (let i = 0; i < imageData.data.length; i += 4) {
        const r = imageData.data[i];
        const g = imageData.data[i + 1];
        const b = imageData.data[i + 2];

        const isWhite = (r > 240 && g > 240 && b > 240);
        const isBlack = (r < 50 && g < 50 && b < 50);

        if (isWhite) {
            mask1.data[i] = 0;       // R
            mask1.data[i + 1] = 0;   // G
            mask1.data[i + 2] = 0;   // B
        } else {
            mask1.data[i] = 255;     // R
            mask1.data[i + 1] = 255; // G
            mask1.data[i + 2] = 255; // B
        }
        mask1.data[i + 3] = 255;     // A

        if (isBlack) {
            mask2.data[i] = 255;     // R
            mask2.data[i + 1] = 255; // G
            mask2.data[i + 2] = 255; // B
        } else {
            mask2.data[i] = 0;       // R
            mask2.data[i + 1] = 0;   // G
            mask2.data[i + 2] = 0;   // B
        }
        mask2.data[i + 3] = 255;     // A
    }

    return { mask1, mask2 };
}

// @ts-nocheck

import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import PreparedScene from "@/components/PreparedScene";
import RandomImage from "@/components/RandomImage/RandomImage";
import TerrainSize from "@/components/TerrainSize/TerrainSize";
import MapResetModal from "./components/moveConfirmModal";
import { defaultMapgenImgs } from "@/lib/data";
import MapGen from "@/mapgen";
import param from "@/mapgen/config/index.ts";
import BlockRouter from "@/components/blockRouter";
import {
  createTask,
  revisionTask,
  getVillageFile,
} from "@/service/taskService";

import "./Mapgen.css";

import { useMount, useUnmount } from "ahooks";
import { message } from "antd";
import Warning from "@/components/Warning/Warning";
import cx from "classnames";
import { isAxiosError } from "axios";

import {
  MapSize,
  TERRAIN_NAMES,
  DEFAULT_MAP_SIZE,
  MAP_ZOOM,
  MAP_ZOOM_SIZE,
  ZOOM_5000,
} from "./constants";
import backIcon from "@/assets/back.png";
import MapgenTool from "@/components/MapgenTool";
import { MapgenSelect, MapgenTypeEnums } from "@/types";
import { DEFAULT_MAPGEN_SELECT } from "@/components/MapgenTool/constants";

import {
  MapgenStorageData,
  TaskResponse,
  MapInfo,
  MapgenConfig,
  MapFiles,
} from "./type";
import MapgenTour from "@/components/MapgenTour";

function Mapgen() {
  const [imgs] = useState(defaultMapgenImgs);
  const [zoom, setZoom] = useState<number>();
  const [mapgen, setMapgen] = useState<MapGen | null>(null);
  const [mapgenSelect, setMapgenSelect] = useState<Partial<MapgenSelect>>(
    DEFAULT_MAPGEN_SELECT
  );
  const [baseImg, setBaseImg] = useState<string>();
  const [hideRedMap, setHideRedMap] = useState<boolean>(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [terrain, setTerrain] = useState<number>(0);
  const [terrainsize, setTerrainSize] = useState<number>(5000);
  const [mapResetModalVisible, setMapResetModalVisible] =
    useState<boolean>(false);
  const [canvasImage, setCanvasImage] = useState<string>("");
  const [isGenerateLoading, setIsGenerateLoading] = useState<boolean>(false);
  const [showWarning, setShowWarning] = useState(false);

  const [openTour, setOpenTour] = useState<boolean>(false);
  const [currentTour, setCurrentTour] = useState<number>(0);
  const step1Ref = useRef<HTMLDivElement>(null);
  const step2Ref = useRef<HTMLDivElement>(null);
  const step3Ref = useRef<HTMLDivElement>(null);
  const step4Ref = useRef<HTMLDivElement>(null);
  const step5Ref = useRef<HTMLButtonElement>(null);

  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const from = queryParams.get("from"); // 获取 'from' 参数的值

  const getTaskIdFromLocal = () => {
    const taskId = localStorage.getItem("taskId");
    if (taskId && taskId !== "undefined") {
      return taskId;
    }
    return null;
  };

  const clearLocal = () => {
    localStorage.removeItem("taskId");
    localStorage.removeItem("mapgen_info");
  };

  useMount(async () => {
    let taskId = getTaskIdFromLocal();
    if (from === "home") {
      if (taskId) {
        clearLocal();
        taskId = null;
      }
    }

    let mapgen: MapGen | null = null;
    let mapInfo: MapInfo = {
      size: MapSize.LARGE,
      constraints: undefined,
      terrain: 0,
    };

    if (taskId) {
      try {
        mapInfo = await getMapInfo(taskId);
      } catch (error) {
        console.log(error);
      }
    }

    const { size, constraints, terrain, sliders, position } = mapInfo;
    const zoom = MAP_ZOOM[size];
    setZoom(zoom);
    setTerrainSize(size);
    setTerrain(terrain);

    const mapgenConfig: MapgenConfig = {
      zoom,
      constraints,
      paintOptions: {},
      operaters: {
        supports: [
          {
            type: "zoom",
            zoomSliderEl: "#zoom-slider",
            zoomSizeDisplayEl: ".zoom-size-display",
            zoomStep: 0.1,
            range: [0.5, 5],
          },
          {
            type: "drag",
          },
        ],
        panElementId: "pan-map",
      },
    };

    mapgen = createMapgenInstance(mapgenConfig);

    mapgen?.onReady().then(() => {
      mapgen?.start();
      const painting = mapgen.getPainting();
      const renderer = mapgen.getRender();
      const brush = painting.getBrush();
      const mapOperations = mapgen.getMapOperations();
      mapOperations.getZoom().setSliderValue(size / 1000);
      renderer.setTerrain(terrain);
      painting.start((message: string) => {
        messageApi.open({
          type: "warning",
          content: message,
        });
      });

      //sliders恢复默认值
      mapgen?.plugins.get("sliders").setAllValue(sliders);

      if (position) {
        const { x, y } = position;
        mapgen?.getRender().setMousePosition(x, y);
      }

      // 笔刷跟随zoom变化
      renderer.onZoomChange = (zoom) => {
        setMapgenSelect({
          type: !mapgen.isEdit()
            ? ("pan" as MapgenTypeEnums)
            : (brush.getTool() as MapgenTypeEnums),
          //@ts-ignore
          size: brush?.getSizeConfigByZoom(zoom),
        });
      };
      brush.setSizeConfigByZoom(zoom);
      setMapgen(mapgen);
      setMapgenSelect({
        type: !mapgen.isEdit()
          ? ("pan" as MapgenTypeEnums)
          : (brush.getTool() as MapgenTypeEnums),
        //@ts-ignore
        size: brush.getSize(),
      });
    });
  });

  useUnmount(() => {
    mapgen?.dispose();
  });

  useEffect(() => {
    setMapgenSelect({
      type: mapgen?.getPainting().getBrush().getTool() as MapgenTypeEnums,
      size: mapgenSelect.size,
    });
  }, [mapgenSelect.size]);

  const createMapgenInstance = (config: MapgenConfig): MapGen => {
    return new MapGen(
      "mapgen4",
      {
        zoom: config.zoom,
        paintOptions: config.paintOptions || {},
        operaters: config.operaters || {},
      },
      config.constraints
    );
  };

  const getMapInfo = async (taskId: string): Promise<MapInfo> => {
    const localData = localStorage.getItem("mapgen_info");
    if (localData) {
      const {
        size,
        data: constraints,
        terrain,
        sliders,
        position,
      } = JSON.parse(localData);
      return { size, constraints, terrain, sliders, position };
    }

    const res = await getVillageFile(taskId);
    if (res.status === 200) {
      const {
        map_size: size,
        constraints,
        terrain,
        sliders,
        position,
      } = res.data;
      return { size, constraints, terrain, sliders, position };
    }

    throw new Error("Failed to get map info");
  };

  const saveRedImage = (): Promise<{
    blobRed: Blob;
    blobRedCut: Blob | undefined;
  }> => {
    return new Promise((reslove) => {
      if (!mapgen) return;
      const { renderer } = mapgen;

      renderer.generateHeightMap();
      renderer.screenshotCallback = () => {
        renderer.getScreenshotCanvas().toBlob((blobRed: Blob | null) => {
          if (!blobRed) return;
          renderer.generateOriginalMap();
          const url = URL.createObjectURL(blobRed);
          const zoom = renderer.getZoom();
          if (zoom && zoom > ZOOM_5000) {
            const redInfoCanvas = document.createElement("canvas");
            const renderCanvas = document.querySelector("#mapgen4")!;
            redInfoCanvas.width = 548 || renderCanvas.clientWidth;
            redInfoCanvas.height = 548 || renderCanvas.clientHeight;

            const ctx = redInfoCanvas.getContext("2d");
            const img = new Image();
            img.onload = function () {
              // 计算 offset
              const scale = zoom * 5;
              const { x, y } = renderer.getOffset();

              const dOffset = ((1 - scale) / 2) * renderCanvas.clientWidth;
              const dSize = renderCanvas.clientWidth * scale;

              const xDOffset = dOffset + (dSize / 1000) * (500 - x);
              const yDOffset = dOffset - (dSize / 1000) * (500 - y);
              //return;

              ctx?.drawImage(img, xDOffset, yDOffset, dSize, dSize);
              redInfoCanvas?.toBlob((blobRedCut: Blob | null) => {
                if (!blobRedCut) return;
                generateTestImg(blobRedCut);
                generateTestImg(blobRed);
                reslove({ blobRed, blobRedCut });
              });
            };
            img.src = url;
          } else {
            reslove({ blobRed, blobRedCut: undefined });
          }
        });
      };
    });
  };

  const saveOriginalImage = (renderParam?: {
    tilt_deg?: number;
    rotate_deg?: number;
  }): Promise<Blob> => {
    return new Promise((reslove) => {
      if (!mapgen) return;
      // const { renderer } = mapgen;
      const renderer = mapgen.getRender();
      Object.assign(param.render, {
        tilt_deg: renderParam?.tilt_deg ?? 0,
        rotate_deg: renderParam?.rotate_deg ?? 0,
      });

      renderer.updateView(param.render);
      renderer.setScreenshotCallback(() => {
        renderer.getScreenshotCanvas().toBlob((blob: Blob | null) => {
          if (!blob) return;
          reslove(blob);
          generateTestImg(blob);
        });
      });
    });
  };

  // 处理随机按钮点击事件
  const clickRandomBtn = async () => {
    if (mapgen?.getPainting().getUserHasPainted()) {
      setShowWarning(true);
    } else {
      mapgen?.reload();
    }
  };

  // 处理地形大小变化
  const terrainSizeHandler = async (size: number) => {
    if (Object.keys(MAP_ZOOM).includes(size.toString())) {
      const zoom = MAP_ZOOM[size];
      setZoom(zoom);
      const painting = mapgen?.getPainting();
      const renderer = mapgen?.getRender();
      painting?.clearVaillage();
      //设置地图缩放比例
      renderer?.setZoom(zoom);
      //设置该比例尺下笔刷
      painting?.brush.setSizeConfigByZoom(zoom);

      if (mapgenSelect.size !== MAP_ZOOM_SIZE[size]) {
        setMapgenSelect((preVal) => {
          return { ...preVal, size: MAP_ZOOM_SIZE[size] };
        });
      }
    }
  };

  const loadReader = async (blob: Blob) => {
    return new Promise((reslove) => {
      const url = URL.createObjectURL(blob);
      const img = new Image();
      img.src = url;
      img.onload = () => {
        setBaseImg(url);
        setHideRedMap(true);
        reslove(true);
      };
    });
  };

  const generate = async () => {
    setIsGenerateLoading(true);
    //重新设置旋转角度
    // Object.assign(param.render, { tilt_deg: 0, rotate_deg: 0 });
    const render = mapgen?.getRender();
    render?.updateView(param.render);
    render?.resetRotationAngles();
    const { tilt_deg, rotate_deg } = render?.getRenderParam();
    const blob: Blob = await saveOriginalImage({ tilt_deg, rotate_deg });
    await loadReader(blob);
    const res = await saveRedImage();
    const { blobRed, blobRedCut } = res as {
      blobRed: Blob;
      blobRedCut: Blob | undefined;
    };
    const miniMap: Blob = await generateMiniMap(blob);

    await generateModel(blob, blobRed, blobRedCut, miniMap);
    setIsGenerateLoading(false);
  };

  const generateModel = async (
    blob: Blob,
    blobRed: Blob,
    blobRedCut: Blob | undefined,
    miniMap: Blob
  ) => {
    const render = mapgen?.getRender();

    //在500比例尺下山体最高为30，否则山顶会有贴不上图的现象，但3d要求山体高度最小为60，故做如下修正
    const mountain_height = Math.max(render?.getMountainHeight() ?? 60, 60);
    const map_size =
      (mapgen?.getMapSlider()?.valueAsNumber ?? 1) * 1000 || DEFAULT_MAP_SIZE;

    const formData = createFormDataWithFiles({
      originalMap: blob,
      heightMap: blobRed,
      heightMapCut: blobRedCut,
      miniMap,
      metadata: {
        map_type: 2,
        map_zoom_rate: parseFloat((map_size / 5000).toFixed(2)),
        map_style_terrain: render?.drawer?.getTerrain(),
        map_size,
        mountain_height,
        constraints: mapgen?.painting.getElevation(),
        sliders: mapgen?.plugins.get("sliders").getAllValue(),
        positon: mapgen?.getRender()?.getOffset(),
      },
    });

    const taskId = getTaskIdFromLocal();

    try {
      const isRevision = !!taskId;
      const response = await handleTaskSubmission(formData, taskId, isRevision);
      const render = mapgen?.getRender();

      if (response.status === 200 && response?.data?.task_id) {
        const { task_id } = response.data;
        if (task_id) {
          saveMapgenInfo({
            task_id,
            size: (mapgen?.getMapSlider()?.valueAsNumber ?? 1) * 1000,
            terrain: render?.drawer?.getTerrain() ?? 0,
            data: mapgen?.painting.elevation,
            sliders: mapgen?.plugins.get("sliders").getAllValue(),
            positon: mapgen?.getRender()?.getOffset(),
          });
          navigate("/home/preview", {
            replace: true,
            state: {
              param,
              task_id,
              from: "mapgen",
              size: map_size,
              biome: TERRAIN_NAMES[render?.drawer?.getTerrain()],
              isRevision,
            },
          });
        }
      }
    } catch (error) {
      if (isAxiosError(error)) {
        if (error.status === 403) {
          // setWarningVisible(true);
        }
      }
      return;
    } finally {
      setHideRedMap(false);
    }
  };

  const generateMiniMap = async (blob: Blob): Promise<Blob> => {
    return new Promise((reslove) => {
      const url = URL.createObjectURL(blob);
      const redInfoCanvas = document.createElement("canvas");
      const renderCanvas = document.querySelector("#mapgen4")!;
      redInfoCanvas.width = renderCanvas.clientWidth;
      redInfoCanvas.height = renderCanvas.clientHeight;
      const ctx = redInfoCanvas.getContext("2d");
      const img = new Image();
      img.onload = function () {
        ctx?.drawImage(img, 0, 0, 512, 512);
        redInfoCanvas?.toBlob((miniblob: Blob | null) => {
          if (!miniblob) return;
          // generateTestImg(miniblob);
          reslove(miniblob);
        });
      };
      img.src = url;
    });
  };

  const generateHandler = async (terrain: number) => {
    const render = mapgen?.getRender();
    const isRotationAnglesReset = render?.isRotationAnglesReset();
    setTerrain(terrain);
    if (isRotationAnglesReset) {
      const { tilt_deg, rotate_deg } = render?.getRenderParam();

      setMapResetModalVisible(true);
      setTimeout(async () => {
        // await loadReader(blob);
        const blob = await saveOriginalImage({ tilt_deg, rotate_deg });
        const url = URL.createObjectURL(blob);
        await loadReader(blob);
        const blobReset = await saveOriginalImage();
        const urlReset = URL.createObjectURL(blobReset);
        setCanvasImage(urlReset);
        setHideRedMap(false);
        Object.assign(param.render, { tilt_deg, rotate_deg });
        render?.updateView(param.render);
      }, 100);
    } else {
      await generate();
    }
  };

  const generateTestImg = (blob: Blob) => {
    return;
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "screenshot.png"; // 设置下载的文件名
    link.click();
    URL.revokeObjectURL(url);
  };

  const createFormDataWithFiles = ({
    originalMap,
    heightMap,
    heightMapCut,
    miniMap,
    metadata,
  }: MapFiles): FormData => {
    const formData = new FormData();
    const files = [
      { blob: originalMap, name: "map.png" },
      { blob: heightMap, name: "map_h.png" },
      {
        blob: new Blob([JSON.stringify(metadata)], {
          type: "application/json",
        }),
        name: "data.json",
      },
      { blob: miniMap, name: "mini_map.png" },
    ];

    if (heightMapCut) {
      files.push({ blob: heightMapCut, name: "map_h_cut.png" });
    }

    files.forEach(({ blob, name }) => {
      formData.append("files", blob, name);
    });

    return formData;
  };
  const handleTaskSubmission = async (
    formData: FormData,
    taskId: string | null,
    isRevision: boolean
  ): Promise<TaskResponse> => {
    return isRevision && taskId
      ? await revisionTask(formData, taskId)
      : await createTask(formData, 2);
  };

  const saveMapgenInfo = (data: MapgenStorageData) => {
    localStorage.setItem("taskId", data.task_id);
    localStorage.setItem("mapgen_info", JSON.stringify(data));
  };

  const goBack = () => {
    navigate("/home/scene", { replace: true });
  };

  const tourHandler = (step: number) => {
    setOpenTour(true);
    setCurrentTour(step);
  };

  return (
    <>
      <MapgenTour
        openTour={openTour}
        setOpenTour={setOpenTour}
        currentTour={currentTour}
        step1Ref={step1Ref}
        step2Ref={step2Ref}
        step3Ref={step3Ref}
        step4Ref={step4Ref}
        step5Ref={step5Ref}
        setCurrentTour={setCurrentTour}
      />
      <div className="pb-6">
        <div>
          <div className=" flex items-center">
            {/* <section
              className="flex items-center cursor-pointer"
              onClick={goBack}
            >
              <img src={backIcon} alt="back" width={14} height={14} />
              <span className="ml-2 text-link">Back</span>
            </section> */}
            <BlockRouter clickHandler={goBack}>
              <section className="flex items-center cursor-pointer">
                <img src={backIcon} alt="back" width={14} height={14} />
                <span className="ml-2 text-link">Back</span>
              </section>
            </BlockRouter>
            <h2 className=" ml-10">Create a new scene</h2>
          </div>
        </div>
        <div className="flex mt-4 flex-col sm:flex-row">
          <div className="space-y-4 flex-1">
            <div ref={step1Ref}>
              <RandomImage
                serialNumber={1}
                isDisable={false}
                imgList={imgs}
                clickImg={(id) => {
                  console.log("id:", id);
                }}
                clickRandomBtn={clickRandomBtn}
                tourHandler={() => tourHandler(0)}
              />
            </div>

            <div ref={step2Ref}>
              <TerrainSize
                serialNumber={2}
                isDisable={false}
                callback={terrainSizeHandler}
                options={[
                  { size: 500, label: "500 * 500m", iconSize: "w-6 h-6" },
                  { size: 750, label: "750 * 750m", iconSize: "w-7 h-7" },
                  { size: 1000, label: "1000 * 1000m", iconSize: "w-8 h-8" },
                  { size: 5000, label: "5000 * 5000m", iconSize: "w-9 h-9" },
                ]}
                defaultSize={terrainsize}
                tourHandler={() => tourHandler(1)}
              />
            </div>

            <div ref={step4Ref}>
              <PreparedScene
                actionRef={step5Ref}
                serialNumber={3}
                clickImgHandler={generateHandler}
                onTerrainChange={(terrain: number) => {
                  mapgen?.getRender().setTerrain(terrain);
                }}
                terrain={terrain}
                tourHandler={() => tourHandler(3)}
              />
            </div>
          </div>
          <div
            id="ui"
            className="board sm:ml-6 w-[600px] flex flex-col max-w-[90rem] text-sm"
          >
            <div className="flex flex-col" ref={step3Ref}>
              <div id="map" className={cx("relative overflow-hidden")}>
                <canvas
                  id="mapgen4"
                  width="2048"
                  height="2048"
                  className={cx({
                    "!absolute bottom-full left-full": hideRedMap,
                  })}
                />

                <img
                  src={baseImg}
                  className={cx("w-full", { "!hidden": !hideRedMap })}
                />
                <div
                  className={"zoom-slider-container"}
                  style={{
                    position: "absolute",
                    zIndex: 10,
                    bottom: "10px",
                    right: "10px",
                    pointerEvents: "none", // 允许事件穿透
                    backgroundColor: "transparent", // 确保背景透明
                  }}
                >
                  <input
                    className="zoom-slider"
                    type="range"
                    name=""
                    id="zoom-slider"
                    max="5"
                    min="0.5"
                    step="0.1"
                    style={{ pointerEvents: "auto" }} // 允许滑块本身接收事件
                    // value="5"
                  />

                  <div
                    className="zoom-size-display"
                    style={{ pointerEvents: "auto" }}
                  >
                    size: 5 * 5 km
                  </div>
                </div>
              </div>

              <MapgenTool
                mapgenSelect={mapgenSelect}
                setMapgenSelect={setMapgenSelect}
              />
            </div>

            {/* <div id="sliders" className="">
              <button id="button-reset">Reset</button>
            </div> */}
          </div>
        </div>
        {contextHolder}
        <Warning
          isVisible={showWarning}
          text="Your current map edits will be lost. Do you want to proceed with creating a new map?"
          onCancel={() => setShowWarning(false)}
          onContinue={() => {
            mapgen?.reload();
            setShowWarning(false);
          }}
        />
        <MapResetModal
          visible={mapResetModalVisible}
          priviewEl={canvasImage}
          onCancel={() => {
            setMapResetModalVisible(false);
          }}
          onLoading={isGenerateLoading}
          onGenerate={generate}
        />
      </div>
    </>
  );
}
export default Mapgen;

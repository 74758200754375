import "./GenerationMethod.css";
import presetIcon from "@/assets/preset.png";
import backIcon from "@/assets/back.png";
import textIcon from "@/assets/text.png";
import { Link, useNavigate } from "react-router-dom";

function GenerationMethod() {
    const navigate = useNavigate();

    const jump = (method: string) => {
        navigate('/home/create2d', { state: method });
    }

    return (
        <div className=" flex flex-col h-full">
            <section>
                <div className=" flex items-center">
                    <Link to="/home/scene" className="flex items-center cursor-pointer">
                        <img src={backIcon} alt="back" width={14} height={14} />
                        <span className="ml-2 text-link">Back</span>
                    </Link>
                    <h2 className=" ml-10">Create a new scene</h2>
                </div>
            </section>
            <div className="flex-1 flex justify-center items-center">
                <div>
                    <h2 className=" text-4xl">Which method do you like to use?</h2>
                    {/* <span className=" mt-8">Choose one to continue</span> */}
                    <div className="flex mt-16 text-sm text-center choice-method">
                        <div onClick={() => jump("template")}>
                            <img src={presetIcon} alt="create-scene-4-colors" width={38} height={38} />
                            <h3 className=" text-xl my-2">Template</h3>
                        </div>
                        <div className="ml-10" onClick={() => jump("text")}>
                            <img src={textIcon} alt="create-scene-parameters" width={38} height={38} />
                            <h3 className=" text-xl my-2">Text to map</h3>
                        </div>
                        {/* <div className="ml-10" onClick={() => jump("picture")}>
                            <img src={presetIcon} alt="create-scene-parameters" width={38} height={38} />
                            <h3 className=" text-xl my-2">Picture to map</h3>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
}
export default GenerationMethod;
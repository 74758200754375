import { initialParams } from "../data/initParam";

export class ParamManager {
    public params: Record<string, number | (string | number)[][]> = {};

    constructor() {
        // 初始化所有参数
        this.initializeParams();
    }

    private initializeParams() {
        this.params = initialParams;
    }

    public getAllParams() {
        return this.params;
    }

    // 通用的获取和设置方法
    public get(key: string):  number | (string | number)[][] {
        return this.params[key];
    }

    public set(key: string, value: number): void {
        if (this.isValidValue(key, value)) {
            this.params[key] = value;
        } else {
            console.warn(`Invalid value for ${key}: ${value}`);
        }
    }

    private isValidValue(key: string, value: number): boolean {
        const ranges: Record<string, [number, number]> = {
            'seed': [1, 1 << 30],
            'island': [0, 1],
            'noisy_coastlines': [0, 0.1],
            'hill_height': [0, 0.1],
            'mountain_jagged': [0, 1],
            'mountain_sharpness': [9.1, 12.5],
            'ocean_depth': [1, 3],
            'wind_angle_deg': [0, 360],
            'raininess': [0, 2],
            'rain_shadow': [0.1, 2],
            'evaporation': [0, 1],
            'lg_min_flow': [-5, 5],
            'lg_river_width': [-5, 5],
            'flow': [0, 1],
            'zoom': [100/1000, 100/50],
            'x': [0, 1000],
            'y': [0, 1000],
            'light_angle_deg': [0, 360],
            'slope': [0, 5],
            'flat': [0, 5],
            'ambient': [0, 1],
            'overhead': [0, 60],
            'tilt_deg': [0, 90],
            'rotate_deg': [-180, 180],
            'mountain_height': [0, 250],
            'outline_depth': [0, 2],
            'outline_strength': [0, 30],
            'outline_threshold': [0, 100],
            'outline_coast': [0, 1],
            'outline_water': [0, 20],
            'biome_colors': [0, 1]
        };

        const [min, max] = ranges[key] || [0, 0];
        return value >= min && value <= max;
    }
}
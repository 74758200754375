import { Routes, Route, useLocation } from 'react-router-dom';

import Home from "./pages/Home/Home.tsx";
import AssetsLibrary from "./pages/AssetsLibrary/AssetsLibrary.tsx";
import Collections from "./pages/Collections/Collections.tsx";
import Marketplace from "./pages/Marketplace/Marketplace.tsx";
import MyOrders from "./pages/MyOrders/MyOrders.tsx";
import MyScenes from './pages/MyScenes/MyScenes.tsx';
import Agreement from "./pages/UserAgreement/Agreement.tsx";
import Login from './pages/Login/Login.tsx';
import Upload from './pages/Upload/Upload.tsx';
import SceneChoice from './pages/SceneChoice/SceneChoice.tsx';
import Layout from './components/Layout/Layout.tsx';
import GenerationMethod from './pages/GenerationMethod/GenerationMethod.tsx';
import ColorMap from './pages/ColorMap/ColorMap.tsx';
import Mapgen from './pages/Mapgen/Mapgen.tsx';
import Preview from './pages/Preview/Preview.tsx';
import NotFound from './components/NotFound/index.tsx';

import type { RootState } from "./store/store.ts";
import { useSelector } from 'react-redux';
import ReactGA from 'react-ga4';
import PrivateRoute from './components/PrivateRoute.tsx';
import { useEffect } from "react";
import WaitList from './pages/WaitList/WaitList.tsx';
import Labs from './pages/Labs/Labs.tsx';
import LabText from './pages/LabText/LabText.tsx';
import LabTag from './pages/LabTag/LabTag.tsx';
import ShowCases from './pages/ShowCases/ShowCases.tsx';
import Mask from './pages/Mask/Mask.tsx';
import UEpreview from './pages/UEpreview/UEpreview.tsx';
import VillageLayout from './pages/VillageLayout/VillageLayout.tsx';

export default function App() {
  const token = useSelector((state: RootState) => state.user.token);
  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize(import.meta.env.VITE_APP_TRACKING_ID);
  }, []);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
      title: document.title
    });
  }, [location]);


  return (
    <main className="h-screen flex flex-col">
      <Layout>
        <Routes>
          <Route
            path="/"
            element={
              <PrivateRoute isAuthenticated={token != ""}>
                <Home />
              </PrivateRoute>
            }
          />
          <Route
            path="/home/scene"
            element={
              <PrivateRoute isAuthenticated={token != ""}>
                <SceneChoice />
              </PrivateRoute>
            }
          />
          <Route
            path="/home/scene/choice"
            element={
              <PrivateRoute isAuthenticated={token != ""}>
                <GenerationMethod />
              </PrivateRoute>
            }
          />
          <Route
            path="/home/create2d"
            element={
              <PrivateRoute isAuthenticated={token != ""}>
                <ColorMap />
              </PrivateRoute>
            }
          />
          <Route
            path="/home/create3d"
            element={
              <PrivateRoute isAuthenticated={token != ""}>
                <Mapgen />
              </PrivateRoute>
            }
          />
          <Route
            path="/home/preview"
            element={
              <PrivateRoute isAuthenticated={token != ""}>
                <Preview />
              </PrivateRoute>
            }
          />
          <Route
            path="/labs/cases"
            element={
              <PrivateRoute isAuthenticated={token != ""}>
                <ShowCases />
              </PrivateRoute>
            }
          />
          <Route path="/assetslibrary" element={<AssetsLibrary />} />
          <Route path="/collections" element={<Collections />} />
          <Route path="/marketplace" element={<Marketplace />} />
          <Route path="/myorders" element={<MyOrders />} />
          <Route path="/myscenes" element={<MyScenes />} />
          <Route path="/agreement" element={<Agreement />} />
          <Route path="/upload" element={<Upload />} />
          <Route path="/agreement" element={<Agreement />} />
          <Route path="/login" element={<Login />} />
          <Route path="/wait" element={<WaitList />} />
          <Route path="/labs" element={<Labs />} />
          <Route path="/labs/text" element={<LabText />} />
          <Route path="/labs/tag" element={<LabTag />} />
          <Route path="/labs/editor" element={<Labs />} />
          <Route path="/labs/mask" element={<Mask />} />
          <Route path="/labs/village" element={<VillageLayout />} />
          <Route path="/uepreview/:task_id" element={<UEpreview />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Layout>
    </main>
  );
}

// @ts-nocheck
/*
 * From http://www.redblobgames.com/maps/mapgen4/
 * Copyright 2018 Red Blob Games <redblobgames@gmail.com>
 * License: Apache v2.0 <http://www.apache.org/licenses/LICENSE-2.0.html>
 *
 * This module uses webgl+regl to render the generated maps
 */

import BasePlugin from "../../plugin/base.ts";
import { vec2, vec4, mat4 } from "gl-matrix";
import Geometry from "../../algorithm/geometry.ts";
import type { Mesh } from "../../types";
//@ts-ignore
import REGL from "regl/dist/regl.js";

import Drawer from "./shader.ts";
import { ParamManager } from "../paramManager.ts";
import param from "../../config/index.ts";
import { RenderParameters, DrapeParams } from "./type.ts";

interface Context {
  plugins: Map<string, BasePlugin>;
  generate: () => void;
  setEditMode: (editMode: boolean) => void;
}

class Renderer {
  private regl: REGL.Regl;
  private river_texturemap: REGL.Texture2D;
  private fbo_texture_size: number = 0;
  private numRiverTriangles: number = 0;
  private screenshotCanvas: HTMLCanvasElement;
  // fbo_land_texture:REGL.Texture2D;
  // fbo_land:REGL.Framebuffer2D;

  public screenshotCallback: () => void;
  public renderParam: RenderParameters | null = null;
  private mountainHeight: number = 60;
  private context: Record<string, any>;
  private updateViewThrottled: (() => void) | null = null;
  public onZoomChange: ((zoom: number) => void) | null = null;
  public drawer: Drawer;

  private readonly el: string;
  private zoom: number;

  private readonly fbos: {
    final: REGL.Framebuffer2D;
    land: REGL.Framebuffer2D;
    river: REGL.Framebuffer2D;
    depth: REGL.Framebuffer2D;
    z: REGL.Framebuffer2D;
  };

  // 2. 纹理相关
  private readonly textures: {
    land: REGL.Texture2D;
    river: REGL.Texture2D;
    final: REGL.Texture2D;
    depth: REGL.Texture2D;
    z: REGL.Texture2D;
  };

  // 3. 几何数据相关
  private readonly geometryBuffers: {
    /**
     * [...红点坐标, ...蓝点坐标]
     * 顶点着色器中使用
     */
    a_quad_xy: Float32Array;
    /**
     * 深度信息
     * drawDepth、drawLand中使用，应该是用于绘制光照、阴影效果
     */
    a_quad_em: Float32Array;
    quad_elements: Int32Array;
    /**
     * 河流的顶点坐标和纹理坐标
     * 相对独立，和其他buffer无关联
     */
    a_river_xyuv: Float32Array;
  };

  private readonly buffers: {
    quad_xy: REGL.Buffer;
    quad_em: REGL.Buffer;
    quad_elements: REGL.Elements;
    river_xyuv: REGL.Buffer;
  };

  constructor(el: string, mesh: Mesh, context: Context) {
    if (!el) throw new Error("container ele can not be null");
    if (!mesh) throw new Error("generated mesh can not be null");

    this.el = el;
    this.mesh = mesh;
    this.context = context;

    this._initREGL();
    this.resizeCanvas();

    this.textures = this.initTextures();
    this.renderState = this.initRenderState();
    this.fbos = this.initFramebuffers();

    this.geometryBuffers = this.initGeometryBuffers();
    this.buffers = this.initBuffers();
    this.drawer = new Drawer(this.regl);

    this.initScreenshotCanvas();
    this.initParamManager();
    this.startDrawingLoop();
  }

  _initREGL() {
    this.regl = REGL({
      canvas: `#${this.el}` || "#mapgen4",
      extensions: ["OES_element_index_uint"],
    });

    this.fbo_texture_size = 2048;
  }

  private initFramebuffers(): typeof this.fbos {
    //  用于创建一个新的帧缓冲区对象，可以用于离屏渲染
    const createFbo = (texture: REGL.Texture2D) =>
      this.regl.framebuffer({
        color: texture,
        depth: true,
      });

    const fboKeys = ["final", "land", "river", "depth"] as const;

    return Object.fromEntries(
      fboKeys.map((key) => [key, createFbo(this.textures[key])])
    ) as typeof this.fbos;
  }

  private initRenderState(): typeof this.renderState {
    const topdown = mat4.create();
    mat4.translate(topdown, topdown, [-1, -1, 0]);
    mat4.scale(topdown, topdown, [1 / 500, 1 / 500, 1]);
    return {
      projection: mat4.create(),
      inverseProjection: mat4.create(),
      topdown,
      numRiverTriangles: 0,
    };
  }

  private initTextures(): typeof this.textures {
    this.textureSize = 2048;
    this.river_texturemap = this.regl.texture({
      data: Geometry.createRiverBitmap(),
      mipmap: "nice",
      min: "mipmap",
      mag: "linear",
      premultiplyAlpha: true,
    });
    const _createTexture = (): REGL.Texture2D => {
      return this.regl.texture({
        width: this.textureSize,
        height: this.textureSize,
        min: "linear",
        mag: "linear",
      });
    };

    const textureKeys = ["land", "river", "final", "depth", "z"] as const;

    return Object.fromEntries(
      textureKeys.map((key) => [key, _createTexture()])
    ) as typeof this.textures;
  }

  private initScreenshotCanvas(): void {
    this.screenshotCanvas = document.createElement("canvas");
    // const textureSize = this.getTextureSize();

    const size: { width: number; height: number } = {
      width: this.textureSize,
      height: this.textureSize,
    };
    Object.assign(this.screenshotCanvas, size);
    //@ts-ignore
    this.screenshotCallback = null;
  }

  private initGeometryBuffers(): typeof this.geometryBuffers {
    // Geometry.setMeshGeometry(mesh, this.geometryBuffers.a_quad_xy);
    const buffers = {
      a_quad_xy: new Float32Array(
        2 * (this.mesh.numRegions + this.mesh.numTriangles)
      ),
      a_quad_em: new Float32Array(
        2 * (this.mesh.numRegions + this.mesh.numTriangles)
      ),
      a_river_xyuv: new Float32Array(1.5 * 3 * 4 * this.mesh.numSolidTriangles),
      quad_elements: new Int32Array(3 * this.mesh.numSolidSides),
    };

    Geometry.setMeshGeometry(this.mesh, buffers.a_quad_xy);
    return buffers;
  }
  private initBuffers(): typeof this.buffers {
    return {
      quad_xy: this.regl.buffer({
        usage: "static",
        type: "float",
        data: this.geometryBuffers.a_quad_xy,
      }),
      quad_em: this.regl.buffer({
        usage: "dynamic",
        type: "float",
        length: 4 * this.geometryBuffers.a_quad_em.length,
      }),
      quad_elements: this.regl.elements({
        primitive: "triangles",
        usage: "dynamic",
        type: "uint32",
        length: 4 * this.geometryBuffers.quad_elements.length,
        count: this.geometryBuffers.quad_elements.length,
      }),
      river_xyuv: this.regl.buffer({
        usage: "dynamic",
        type: "float",
        length: 4 * this.geometryBuffers.a_river_xyuv.length,
      }),
    };
  }

  public screenToWorld(coords: [number, number]): vec2 {
    /* convert from screen 2d (inverted y) to 4d for matrix multiply */
    let glCoords = vec4.fromValues(
      coords[0] * 2 - 1,
      1 - coords[1] * 2,
      /* TODO: z should be 0 only when tilt_deg is 0;
       * need to figure out the proper z value here */
      0,
      1
    );
    /* it returns vec4 but we only need vec2; they're compatible */
    let transformed = vec4.transformMat4(
      vec4.create(),
      glCoords,
      this.renderState.inverseProjection
    );
    return [transformed[0], transformed[1]];
  }

  public getRenderState(): typeof this.renderState {
    return this.renderState;
  }

  public getRenderInverseProjection(): mat4 {
    return this.renderState.inverseProjection;
  }

  private initParamManager(): void {
    this.paramManager = new ParamManager();
    //this.renderParam = this.paramManager.getAllParams();
  }

  /* Allow drawing at a different resolution than the internal texture size */
  private resizeCanvas(): void {
    // this.el = this.el || 'mapgen4'
    let canvas = document.getElementById(`${this.el}`) as HTMLCanvasElement;
    let size = canvas.clientWidth;
    size = 2048; /* could be smaller to increase performance */
    if (canvas.width !== size || canvas.height !== size) {
      console.log(
        `Resizing canvas from ${canvas.width}x${canvas.height} to ${size}x${size}`
      );
      canvas.width = canvas.height = size;
      this.regl.poll();
    }
  }

  private clearBuffers() {
    // I don't have to clear fbo_em because it doesn't have depth
    // and will be redrawn every frame. I do have to clear
    // fbo_river because even though it doesn't have depth, it
    // doesn't draw all triangles.
    this.fbos.river.use(() => {
      this.regl.clear({ color: [0, 0, 0, 0] });
    });
    this.fbos.depth.use(() => {
      this.regl.clear({ color: [0, 0, 0, 1], depth: 1 });
    });
    this.fbos.final.use(() => {
      this.regl.clear({ color: [0.3, 0.3, 0.35, 1], depth: 1 });
    });
  }

  private calculateDrapeParams(
    currentRenderParam: RenderParameters
  ): DrapeParams {
    // 计算光照角度
    const lightAngleRad =
      (Math.PI / 180) *
      (currentRenderParam.light_angle_deg + currentRenderParam.rotate_deg);

    return {
      // 几何相关参数
      elements: this.buffers.quad_elements,
      a_xy: this.buffers.quad_xy,
      a_em: this.buffers.quad_em,

      // 纹理相关参数
      u_water: this.textures.river,
      u_depth: this.textures.depth,
      u_projection: this.renderState.projection,

      // 光照相关参数
      u_light_angle: [Math.cos(lightAngleRad), Math.sin(lightAngleRad)],
      u_slope: currentRenderParam.slope,
      u_flat: currentRenderParam.flat,
      u_ambient: currentRenderParam.ambient,
      u_overhead: currentRenderParam.overhead,

      // 轮廓相关参数
      u_outline_depth:
        currentRenderParam.outline_depth * 5 * currentRenderParam.zoom,
      u_outline_coast: currentRenderParam.outline_coast,
      u_outline_water: currentRenderParam.outline_water,
      u_outline_strength: currentRenderParam.outline_strength,
      u_outline_threshold: currentRenderParam.outline_threshold / 1000,

      // 生物群系颜色
      u_biome_colors: currentRenderParam.biome_colors,
    };
  }

  private captureScreenshot(): void {
    if (!this.screenshotCallback || !this.screenshotCanvas) return;

    try {
      const context: ScreenshotContext = {
        gl: this.regl._gl,
        canvas: this.screenshotCanvas,
        textureSize: this.fbo_texture_size,
      };

      this.processScreenshotData(context);

      // 执行回调并清理
      this.screenshotCallback();
      this.screenshotCallback = null;
    } catch (error) {
      console.error("Screenshot capture failed:", error);
      this.screenshotCallback = null;
    }
  }

  private processScreenshotData(context: ScreenshotContext): void {
    const { gl, canvas, textureSize } = context;

    // 获取 2D 上下文
    const ctx = canvas.getContext("2d", { willReadFrequently: true });
    if (!ctx) throw new Error("Failed to get 2D context");

    // 创建图像数据
    const imageData = ctx.getImageData(0, 0, textureSize, textureSize);
    const bytesPerRow = 4 * textureSize;
    const buffer = new Uint8Array(bytesPerRow * textureSize);

    // 读取像素数据
    gl.readPixels(
      0,
      0,
      textureSize,
      textureSize,
      gl.RGBA,
      gl.UNSIGNED_BYTE,
      buffer
    );

    // 翻转行顺序 (WebGL 到 Canvas)
    this.flipImageRows(imageData, buffer, textureSize, bytesPerRow);

    // 将数据写回画布
    ctx.putImageData(imageData, 0, 0);
  }

  private flipImageRows(
    imageData: ImageData,
    buffer: Uint8Array,
    textureSize: number,
    bytesPerRow: number
  ): void {
    for (let y = 0; y < textureSize; y++) {
      const rowBuffer = new Uint8Array(
        buffer.buffer,
        y * bytesPerRow,
        bytesPerRow
      );
      imageData.data.set(rowBuffer, (textureSize - y - 1) * bytesPerRow);
    }
  }
  //@ts-ignore
  private startDrawingLoop(exportImage: boolean = false): void {
    const exportValue = exportImage ? 1 : 0;
    // console.log("exportValue:",exportValue)
    /* draw rivers to a texture, which will be draped on the map surface */

    // /* write 16-bit elevation to a texture's G,R channels; the B,A channels are empty */

    /* using the same perspective as the final output, write the depth
        to a texture, G,R channels; used for outline shader */

    /* Only draw when render parameters have been passed in;
     * otherwise skip the render and wait for the next tick */
    this.clearBuffers();
    //@ts-ignore
    this.regl.frame((_context) => {
      const currentRenderParam = this.renderParam;

      if (!currentRenderParam || Object.keys(currentRenderParam).length === 0) {
        return;
      }
      this.renderParam = null;

      if (this.numRiverTriangles > 0) {
        this.drawer.drawRivers(this.fbos.river, this.river_texturemap, {
          count: 3 * this.numRiverTriangles,
          a_xyuv: this.buffers.river_xyuv,
          u_projection: this.renderState.topdown,
        });
      }
      this.drawer.drawLand(this.fbos.land, {
        elements: this.buffers.quad_elements,
        a_xy: this.buffers.quad_xy,
        a_em: this.buffers.quad_em,
        u_projection: this.renderState.topdown,
        u_water: this.textures.river,
        u_outline_water: currentRenderParam.outline_water,
        export_value: exportValue,
      });

      //定义旋转后的投影效果，主要应对与sliders中的title_deg和rolate_deg
      /* Standard rotation for orthographic view */
      mat4.identity(this.renderState.projection);
      mat4.rotateX(
        this.renderState.projection,
        this.renderState.projection,
        ((180 + currentRenderParam.tilt_deg) * Math.PI) / 180
      );
      mat4.rotateZ(
        this.renderState.projection,
        this.renderState.projection,
        (currentRenderParam.rotate_deg * Math.PI) / 180
      );

      /* Top-down oblique copies column 2 (y input) to row 3 (z
       * output). Typical matrix libraries such as glm's mat4 or
       * Unity's Matrix4x4 or Unreal's FMatrix don't have this
       * this.renderState.projection built-in. For mapgen4 I merge orthographic
       * (which will *move* part of y-input to z-output) and
       * top-down oblique (which will *copy* y-input to z-output).
       * <https://en.wikipedia.org/wiki/Oblique_projection> */
      this.renderState.projection[9] = 1;

      /* Scale and translate works on the hybrid this.renderState.projection */

      mat4.scale(this.renderState.projection, this.renderState.projection, [
        currentRenderParam.zoom / 100,
        currentRenderParam.zoom / 100,
        (currentRenderParam.mountain_height * currentRenderParam.zoom) / 100,
      ]);

      // const { worldTopLeft, worldTopRight, worldBottomLeft, worldBottomRight } = this.getMapBoundaries();
      //  const isMapBoundaries = this.getMapBoundaries();
      mat4.translate(this.renderState.projection, this.renderState.projection, [
        -currentRenderParam.x,
        -currentRenderParam.y,
        0,
      ]);
      /* Keep track of the inverse matrix for mapping mouse to world coordinates */
      mat4.invert(
        this.renderState.inverseProjection,
        this.renderState.projection
      );
      //添加轮廓，增加3d渲染效果

      if (currentRenderParam.outline_depth > 0) {
        this.drawer.drawDepth(this.fbos.depth, {
          elements: this.buffers.quad_elements,
          a_xy: this.buffers.quad_xy,
          a_em: this.buffers.quad_em,
          u_projection: this.renderState.projection,
        });
      }
      //将地形纹理图层渲染在整个地形表面上，实现类似“披覆”的效果，以增强地图的视觉细节。
      //它通过渲染到特定纹理上，使得地形和纹理图层更好地结合。这通常用在应用光影、颜色等效果上，
      //以呈现地形的起伏和高低差异。结合 WebGL 渲染管线，该函数使用了 regl 库定义的绘制参数，
      //例如顶点着色器、片段着色器和各项渲染配置，以实现高效渲染
      this.drawer.drawDrape(
        this.fbos.final,
        this.textures.land,
        this.textureSize,
        this.calculateDrapeParams(currentRenderParam)
      );
      /* draw the high resolution final output to the screen, smoothed and resized */

      /* draw the final image by draping the biome colors over the geometry;
            note that u_depth and u_mapdata are both encoded with G,R channels
            for 16 bits */
      this.drawer.drawFinal(this.textures, {
        u_offset: [0.5 / this.fbo_texture_size, 0.5 / this.fbo_texture_size],
        export_value: exportValue,
      });

      if (this.screenshotCallback) {
        // TODO: regl says I need to use preserveDrawingBuffer
        this.captureScreenshot();
      }

      this.clearBuffers();
    });
  }

  public linearRegression(xValue: number): number {
    const a = 1.04186; // 根据方程解得
    const b = 0.9069; // 根据方程解得
    return a * xValue + b - 1;
  }

  public getZoom(): number {
    return this.zoom;
  }

  public setZoom(zoom: number): void {
    this.zoom = zoom;
    const ZOOM_SETTINGS = {
      MEDIUM: {
        range: { min: 0.2, max: 0.85 },
        params: {
          outline_depth: 1,
          outline_strength: 10,
          mountain_height: 70,
        },
      },
      FAR: {
        range: { min: 1, max: Infinity },
        params: {
          outline_depth: 0.2,
          outline_strength: 5,
          mountain_height: 30,
        },
      },
    } as const;

    const zoomSetting = Object.values(ZOOM_SETTINGS).find(
      ({ range }) => this.zoom > range.min && this.zoom < range.max
    );

    if (zoomSetting) {
      Object.assign(param.render, zoomSetting.params);
      if (!this.updateViewThrottled) {
        this.updateViewThrottled = () => {
          requestAnimationFrame(() => {
            this.updateView(param.render);
            this.updateViewThrottled = null;
          });
        };
        setTimeout(this.updateViewThrottled, 100); // 100ms 的节流间隔
      }
    }
    this.onZoomChange?.(this.zoom);
  }

  public getOffset(): { x: number; y: number } {
    return {
      x: param.render.x || this.renderParam.x,
      y: param.render.y || this.renderParam.y,
    };
  }

  public resetRotationAngles(): void {
    if (!this.isRotationAnglesReset()) return;

    Object.assign(param.render, {
      tilt_deg: 0,
      rotate_deg: 0,
    });
    this.context.plugins.get("sliders").setValue("tilt_deg", 0);
    this.context.plugins.get("sliders").setValue("rotate_deg", 0);
    //  this.updateView(param.render);
  }

  public isRotationAnglesReset(): boolean {
    const renderParam = this.getRenderParam();
    const { tilt_deg, rotate_deg } = renderParam;
    return (
      (rotate_deg !== 0 &&
        rotate_deg !== 90 &&
        rotate_deg !== -90 &&
        rotate_deg !== 180 &&
        rotate_deg !== -180 &&
        this.zoom < 0.32) ||
      tilt_deg !== 0
    );
  }

  public getMousePosition(): { x: number; y: number } {
    return {
      x: param.render.x ?? -500,
      y: param.render.y ?? -500,
    };
  }

  public setMousePosition(x: number, y: number): void {
    // this.mousePosition = { x, y };
    Object.assign(param.render, { x: -x, y: -y });
    this.updateView(param.render);
    // if (!this.updateViewThrottled) {
    //   this.updateViewThrottled = () => {
    //     requestAnimationFrame(() => {
    //       this.updateView(param.render);
    //       this.updateViewThrottled = null;
    //     });
    //   };
    //   setTimeout(this.updateViewThrottled, 100);
    // }
  }

  public getTerrain(): number {
    return this.drawer.getTerrain();
  }

  public setTerrain(terrain: number): void {
    this.drawer.setTerrain(terrain);

    //沙漠
    if (terrain === 2) {
      Object.assign(param.render, {
        ambient: 0.1,
        overhead: 50,
        flat: 5,
      });
      param.rivers.flow = 0;
      // heightOffset = 0.4;
      // localStorage.setItem("heightOffset", heightOffset.toString());
      // this.context.generate({heightOffset});
      this.context.generate();
    } else if (terrain === 1) {
      Object.assign(param.render, {
        ambient: 0.2,
        overhead: 40,
        flat: 1,
      });
      param.rivers.flow = 0;
      // heightOffset = 0.2;
      // localStorage.setItem("heightOffset", heightOffset.toString());
      this.context.generate();
    } else {
      Object.assign(param.render, {
        ambient: 0.08,
        overhead: 40,
        flat: 5,
      });
      param.rivers.flow = 0.2;
      // heightOffset = 0;
      // localStorage.setItem("heightOffset", heightOffset.toString());
      // this.context.generate({heightOffset});
      // this.updateView(param.render);
      this.context.generate();
    }
  }
  /* Update the buffers with the latest map data */
  public updateMap(): void {
    this.buffers.quad_em.subdata(this.geometryBuffers.a_quad_em);
    this.buffers.quad_elements.subdata(this.geometryBuffers.quad_elements);
    this.buffers.river_xyuv.subdata(
      this.geometryBuffers.a_river_xyuv.subarray(
        0,
        4 * 3 * this.numRiverTriangles
      )
    );
  }

  public updateView(renderParam: any): void {
    if (!renderParam) return;

    // 创建新的渲染参数对象
    this.renderParam = {
      ...renderParam,
      // 如果存在自定义缩放，则使用自定义缩放
      zoom: this.zoom || renderParam.zoom,
    };

    // 更新山体高度
    if (renderParam.mountain_height) {
      this.mountainHeight = renderParam.mountain_height;
    }
  }
  public getTransferBuffers(): {
    quad_elements_buffer: ArrayBuffer;
    a_quad_em_buffer: ArrayBuffer;
    a_river_xyuv_buffer: ArrayBuffer;
  } {
    const { geometryBuffers } = this;
    return {
      quad_elements_buffer: geometryBuffers.quad_elements.buffer,
      a_quad_em_buffer: geometryBuffers.a_quad_em.buffer,
      a_river_xyuv_buffer: geometryBuffers.a_river_xyuv.buffer,
    };
  }

  public getTransferBufferList(): ArrayBuffer[] {
    const { geometryBuffers } = this;
    return [
      geometryBuffers.quad_elements.buffer,
      geometryBuffers.a_quad_em.buffer,
      geometryBuffers.a_river_xyuv.buffer,
    ];
  }

  public getRenderParam(): any {
    return param.render;
  }

  public getMountainHeight(): number {
    return this.mountainHeight;
  }

  public generateHeightMap(): void {
    this.startDrawingLoop(true);
    this.updateView(param.render);
  }

  public generateOriginalMap(): void {
    this.startDrawingLoop(false);
    this.updateView(param.render);
  }

  public setScreenshotCallback(callback: () => void): void {
    this.screenshotCallback = callback;
  }

  public getScreenshotCanvas(): HTMLCanvasElement {
    return this.screenshotCanvas;
  }

  private stopDrawingLoop(): void {
    if (this.renderLoop) {
      this.regl.cancelFrame(this.renderLoop);
      this.renderLoop = null;
    }
  }

  public dispose(): void {
    this.stopDrawingLoop();
    //@ts-ignore
    this.screenshotCallback = null;
    this.renderParam = undefined;
  }
}

export default Renderer;

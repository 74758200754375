import { useState, useRef, useEffect, useId, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { AxiosError } from "axios";
import { message, notification, Modal } from "antd";
import { useFullscreen } from "ahooks";
import UESceneHeader from "@/components/UESceneHeader";
import ModelPreview from "@/components/ModelPreview";
import UEPreview from "@/components/UEPreview";
import MapPreview from "@/components/Mappreview";
import ModelPreviewHeader from "@/components/ModelPreviewHeader";
import {
  taskStatus,
  confirmTask,
  closeTask,
  taskRender,
  getModelURL,
} from "@/service/taskService";
import { disabledButton, enabledButton, downloadCheck } from "@/lib/utils";
import { ueNotification } from "@/lib/notification";
import "@/App.css";
import "./Preview.css";
// import backIcon from "@/assets/back.png";
import { UeplayerRefProps } from "@/types";
// import previewLeftArrowIcon from "@/assets/preview-back.png";

const modelStyle =
  "flex-1 w-full flex flex-col justify-center m-auto bg-[#191919] rounded-md text-[#5E5E5E] mb-4 px-4 pt-0 pb-4";
const ueStyle =
  "flex-1 w-full rounded-md bg-[#191919] text-[#5E5E5E] overflow-hidden";
const ASSET_MODEL =
  import.meta.env.VITE_APP_BASE_URL + import.meta.env.VITE_APP_ASSET_MODEL;
const MODEL =
  import.meta.env.VITE_APP_BASE_URL + import.meta.env.VITE_APP_DOWNLOAD_MODEL;

function Preview() {
  const location = useLocation();
  // const navigate = useNavigate();
  const ueplayerRef = useRef<UeplayerRefProps>(null);
  const { task_id, from, size, biome, isRevision } = location.state || {};
  const [genPersceneLoading, setGenPersceneLoading] = useState<boolean>(false);
  const [modelUrl, setModelUrl] = useState<string>("");
  const [currentTaskStatus, setCurrentTaskStatus] = useState<number>(0);
  const mapURL = useRef<string>("");
  const [streamID, setStreamID] = useState<string>("");
  const modelFullRef = useRef(null);
  const [isUeFullScreen, setIsUeFullScreen] = useState<boolean>(false);
  const fullScreenBtnRef = useRef<HTMLButtonElement>(null);
  // const [showBackIcon, setShowBackIcon] = useState<boolean>(false);
  const [
    isModelFullscreen,
    {
      enterFullscreen: enterModelFullscreen,
      exitFullscreen: exitModelFullscreen,
    },
  ] = useFullscreen(modelFullRef);
  const notifiKey = useId();

  useEffect(() => {
    if (task_id) {
      historyTaskView();
      const mapURLName =
        from === "mapgen" || size === 1000 ? "/map.png" : "/map_preview.png";
      const url = `${import.meta.env.VITE_APP_BASE_URL}${
        import.meta.env.VITE_APP_ASSET_FILE
      }${task_id}${mapURLName}?t=${new Date().getTime()}`;
      mapURL.current = url;
    }
    if (isRevision) {
      setGenPersceneLoading(true);
    }

    // 阻止使用空格键控制全屏
    const blockFullScreen = (event: KeyboardEvent) => {
      if (
        event.code === "Space" &&
        document.activeElement === fullScreenBtnRef.current
      )
        event.preventDefault();
    };
    // 阻止使用空格键控制全屏
    document.addEventListener("keydown", blockFullScreen);
    return () => {
      enabledButton();
      document.removeEventListener("keydown", blockFullScreen);
      notification.destroy(notifiKey);
    };
  }, []);

  // 处理查看历史记录预览
  const historyTaskView = async () => {
    let response, status, stream_id;
    try {
      response = await taskStatus(task_id);

      stream_id = response.data.ue_worker_id;
      status = Number(response?.data?.status);
      if (!status) {
        message.error("Invalid task status");
        return;
      }
    } catch (error) {
      message.error("Error in querying task status!");
      return;
    }

    // 加载历史记录中的模型
    if (status >= 210) {
      const modelURL = ASSET_MODEL + task_id + "/scene.fbx";
      setModelUrl(modelURL);
      //setShowBackIcon(true);
    }

    if (status === 360) {
      console.log({ streamID: response.data.ue_worker_id });
      console.log({ response });

      stream_id && setStreamID(stream_id);
    }

    // 禁用按钮
    if (status === 100 || status === 110 || status === 200) {
      disabledButton();
    }

    if (status === 220 || status === 230 || status === 260 || status === 300) {
      disabledButton();
      setGenPersceneLoading(true);
    }
    setCurrentTaskStatus(status);
  };

  // 快速生成UE场景
  const quickGenerateUEScene = async () => {
    disabledButton();
    try {
      await taskRender(task_id);
      setGenPersceneLoading(true);
      setCurrentTaskStatus(211);
    } catch (error) {
      message.error("Error in generating UE scene!");
    }
  };

  // 处理连接错误
  const handleConnectionError = async (status: number) => {
    console.log({ status });
    setStreamID("");
    setCurrentTaskStatus(900);
    if (status === 0) {
      Modal.confirm({
        title: "Tips",
        content: `You have been inactive for a while, are you still there? 
                The 3D environment will automatically close to free up resources.`,
        okText: "I am here",
        cancelText: "Close task",
        onOk: connect,
        onCancel: closeWS,
      });
    } else if (status === 1) {
      message.warning(`Your network speed is slow. Please change the network.`);
    } else if (status === 2) {
      Modal.confirm({
        title: "Tips",
        content: `Your network is disconnected, please check your network connection`,
        cancelButtonProps: { style: { display: "none" } },
        onOk: connect,
      });
    } else if (status === 3) {
      Modal.confirm({
        title: "Tips",
        content: `Server is busy, please try again later.`,
        cancelButtonProps: { style: { display: "none" } },
        onOk: () => {},
      });
    }
  };

  // 连接UE
  const connect = async () => {
    setStreamID("");
    notification.destroy(notifiKey);
    var response, stream_id;
    try {
      response = await taskStatus(task_id);
      stream_id = response.data.ue_worker_id;
    } catch (error) {
      // @ts-ignore
      console.log("error:", error.message);
      await closeTask(task_id);
      Modal.confirm({
        title: "error",
        content: `Network error, please switch to a different network.`,
        cancelButtonProps: { style: { display: "none" } },
        onOk: () => {},
      });
    }
    console.log({ response });
    console.log({ streamID: response?.data.ue_worker_id });
    if (response?.data.status === "360") {
      setCurrentTaskStatus(360);
      stream_id && setStreamID(stream_id);
    }

    if (response?.data.status === "900") {
      quickGenerateUEScene();
    }
  };

  //  获取模型URL
  const getModelUrl = async (task_id: string) => {
    try {
      //setShowBackIcon(true);
      const url = `${ASSET_MODEL}${task_id}/scene.fbx`;
      const response = await getModelURL(task_id);
      console.log("url:", url);
      if (response.status === 200) {
        setModelUrl(url);
      }
    } catch (error) {
      message.error("Failed to get model URL");
    }
  };
  // 模型生成完成
  const modelComplete = useCallback(
    (success: boolean, status: number, duration?: number) => {
      enabledButton();
      if (success) {
        getModelUrl(task_id);
        setCurrentTaskStatus(status);
      } else {
        Modal.error({
          title: "3D Model Generation",
          content:
            "The current image cannot generate a model, please change the image.",
          cancelButtonProps: { style: { display: "none" } },
        });
        setCurrentTaskStatus(status);
      }
      console.log("duration:", duration);
      setGenPersceneLoading(false);
    },
    [task_id]
  );

  // 生成UE场景
  const generateUEScene = async (task_id: string) => {
    // 通知组件，检查是否可以生成UE场景
    if (ueNotification(currentTaskStatus)) return;
    setGenPersceneLoading(true);
    disabledButton();
    try {
      const response = await confirmTask(task_id);
      if (response.status === 200) setCurrentTaskStatus(220);
    } catch (error) {
      setGenPersceneLoading(true);
      enabledButton();
      message.error(`An error occurred:, ${(error as AxiosError)?.message}`);
    }
  };

  // UE生成完成
  const ueComplete = (isSuccess: boolean, status: number, streamID: string) => {
    console.log("ueComplete: ", { isSuccess, status, streamID });
    console.log({ streamID });
    if (status === 500) {
      message.error("Failed to generate UE scene.");
      return;
    }

    setGenPersceneLoading(false);
    enabledButton();
    if (isSuccess) {
      streamID && setStreamID(streamID);
    }
    setCurrentTaskStatus(status);
  };

  // 关闭UE连接
  const closeWS = async () => {
    try {
      if (currentTaskStatus >= 360) {
        const response = await closeTask(task_id);
        console.log("关闭状态：", response.status);
        setCurrentTaskStatus(1000);
        enabledButton();
        setStreamID("");
        setTimeout(() => {
          setCurrentTaskStatus(900);
        }, 3000);
      } else {
        message.error("Failed to close the UE scene.");
      }
    } catch (error) {
      message.error(`An error occurred:, ${(error as AxiosError)?.message}`);
    }
  };

  const ueContainerClasses = `text-white text-sm h-full${
    isUeFullScreen ? " fixed top-0 left-0 w-full bg-[#191919]" : ""
  }`;

  // const backUrl =
  //   from === "mapgen" ? "/home/create3d?from=preview" : "/home/create2d";

  return (
    <main className="h-full pt-0 flex-1 flex flex-col" id="preview-main">
      <section className="pb-4">
        <div className=" flex items-center">
          {/* {showBackIcon && (
            <p
              className="flex items-center cursor-pointer mr-4"
              onClick={() => {
                navigate(backUrl);
              }}
            >
              <img src={backIcon} alt="back" width={14} height={14} />
              <span className="ml-2 text-link">Back</span>
            </p>
          )} */}
          <h2>Create a new scene</h2>
        </div>
      </section>
      <div className="flex-1 flex">
        <div className="flex flex-col">
          <div className="px-6 py-6 pt-3 rounded-md bg-gradient w-[550px] flex-1">
            {/* <div className=" text-white font-light flex items-center justify-between pb-2">
                            <p className="flex items-center preview-back-btn" onClick={() => {
                                //navigate(-1)
                            }}>
                                <img src={previewLeftArrowIcon} alt="" width={14} height={14} />
                                <span className="ml-2">Previous Step</span>
                            </p>
                        </div> */}

            <MapPreview
              mapURL={mapURL.current}
              size={size}
              from={from}
              biome={biome}
              task_id={task_id}
            />
          </div>
        </div>

        <div className="flex-1 pl-4 flex flex-col">
          <div className={modelStyle} ref={modelFullRef}>
            <ModelPreviewHeader
              isModelFullscreen={isModelFullscreen}
              genPersceneLoading={genPersceneLoading}
              modelUrl={modelUrl}
              taskid={task_id}
              MODEL={MODEL}
              enterModelFullscreen={enterModelFullscreen}
              exitModelFullscreen={exitModelFullscreen}
              generate={generateUEScene}
            />
            <ModelPreview
              currentTaskStatus={currentTaskStatus}
              isModelFullscreen={isModelFullscreen}
              modelUrl={modelUrl}
              size={size}
              from={from}
              task_id={task_id}
              onComplete={(success, status, duration) => {
                modelComplete(success, status, duration);
              }}
            />
          </div>
          <div className={ueStyle}>
            <div className={ueContainerClasses}>
              <UESceneHeader
                isUeFullScreen={isUeFullScreen}
                setIsUeFullScreen={setIsUeFullScreen}
                closeWS={closeWS}
                taskid={task_id}
                downloadCheck={downloadCheck}
                fullScreenBtnRef={fullScreenBtnRef}
                downLoadImgHandler={() => {
                  const ueplayer = ueplayerRef.current?.getUeplayerRef();
                  if (ueplayer) {
                    ueplayer.sendScreenshot();
                  }
                }}
              />
              <UEPreview
                taskId={task_id}
                status={currentTaskStatus}
                ref={ueplayerRef}
                onComplete={(
                  isSuccess: boolean,
                  status: number,
                  streamID: string
                ) => {
                  ueComplete(isSuccess, status, streamID);
                }}
                startTime={0}
                reconnect={(status: number) => {
                  handleConnectionError(status);
                }}
                quickGenerate={() => {
                  quickGenerateUEScene();
                }}
                streamID={streamID}
              />
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Preview;

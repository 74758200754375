import { useState } from "react";


interface TagSelectorProps {
    serialNumber: string;
    isDisable: boolean;
    isLoading: boolean;
    generateHandler: (data: {
        geography: string;
        mountain: string;
        isBarren: boolean;
        environments: string[];
    }) => void;
}


const geographyOptions = [
    'Bay',
    'Land',
    'Lake',
    'Peninsula',
    'Island',
    'Archipelago',
    'Fjord',
    'Coast'
];

const mountainOptions = [
    'Highland',
    'Lowland'
];

function TagSelector({
    serialNumber,
    isDisable,
    isLoading,
    generateHandler
}: TagSelectorProps) {

    const [selectedGeography, setSelectedGeography] = useState<string>('Bay');
    const [selectedMountain, setSelectedMountain] = useState<string>('');
    const [isBarren, setIsBarren] = useState<boolean>(false);
    const [environments, setEnvironments] = useState<string[]>([]);

    const handleBarrenChange = (checked: boolean) => {
        setIsBarren(checked);
        if (checked) {
            setEnvironments([]);
        }
    };

    const handleEnvironmentChange = (env: string, checked: boolean) => {
        if (checked) {
            setIsBarren(false);
            setEnvironments(prev => [...prev, env]);
        } else {
            setEnvironments(prev => prev.filter(item => item !== env));
        }
    };

    const handleGenerate = () => {
        const selectedData = {
            geography: selectedGeography,
            mountain: selectedMountain,
            isBarren,
            environments
        };
        generateHandler(selectedData);
    };

    const handleReset = () => {
        setSelectedGeography('Bay');
        setSelectedMountain('');
        setIsBarren(false);
        setEnvironments([]);
    };

    return (
        <div className="random p-5 rounded-md">
            <div className="flex text-lg mb-3">
                <span className="serial-number mr-2">{serialNumber}</span>
                <h1>Generate your terrain with tags</h1>
            </div>
            <div className="">
                <div>
                    <p className="text-white opacity-50">Geography *</p>
                    {geographyOptions.map((geography) => (
                        <div
                            key={geography}
                            className={`
                            border rounded-md px-3 py-1 mr-2 mb-2 inline-flex items-center
                            ${isLoading ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'}
                            ${selectedGeography === geography
                                    ? 'border-white bg-gradient-to-r from-[#345777] to-[#763BAF] text-white'
                                    : 'border-gray-500 text-gray-400'
                                }
                        `}
                            onClick={() => !isLoading && setSelectedGeography(geography)}
                        >
                            <div
                                className={`
                                w-3 h-3 rounded-full border-2 mr-2
                                ${selectedGeography === geography
                                        ? 'border-white border-4'
                                        : 'border-gray-400 bg-transparent'
                                    }
                            `}
                            />
                            <span>{geography}</span>
                        </div>
                    ))}

                </div>
                <div>
                    <div className="text-white opacity-50 mt-3">Mountain</div>
                    {mountainOptions.map((mountain) => (
                        <div
                            key={mountain}
                            className={`
                            border rounded-md px-3 py-1 mr-2 mb-2 inline-flex items-center
                            ${isLoading ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'}
                            ${selectedMountain === mountain
                                    ? 'border-white bg-gradient-to-r from-[#345777] to-[#763BAF] text-white'
                                    : 'border-gray-500 text-gray-400'
                                }
                        `}
                            onClick={() => !isLoading && setSelectedMountain(mountain)}
                        >
                            <div
                                className={`
                                w-3 h-3 rounded-full border-2 mr-2
                                ${selectedMountain === mountain
                                        ? 'border-white border-4'
                                        : 'border-gray-400 bg-transparent'
                                    }
                            `}
                            />
                            <span>{mountain}</span>
                        </div>
                    ))}
                </div>

                <div>
                    <div className="text-white opacity-50 mt-3">Environment</div>
                    <div
                        className={`
                        border rounded-md px-3 py-1 mb-2 inline-flex items-center
                        ${isLoading ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'}
                        ${isBarren
                                ? 'border-white bg-gradient-to-r from-[#345777] to-[#763BAF] text-white'
                                : 'border-gray-500 text-gray-400'
                            }
                    `}
                        onClick={() => !isLoading && handleBarrenChange(!isBarren)}
                    >
                        <div
                            className={`
                            w-3 h-3 rounded-full border-2 mr-2
                            ${isBarren
                                    ? 'border-white border-4'
                                    : 'border-gray-400 bg-transparent'
                                }
                        `}
                        />
                        <span>Barren</span>
                    </div>
                    <div
                        className="h-6 w-px bg-white opacity-30 mx-2 inline-flex items-center align-middle"
                    ></div>
                    {['Woodland', 'Wetland'].map((env) => (
                        <div
                            key={env}
                            className={`
                            border rounded-md px-3 py-1 mr-2 mb-2 inline-flex items-center
                            ${isLoading ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'}
                            ${environments.includes(env)
                                    ? 'border-white bg-gradient-to-r from-[#345777] to-[#763BAF] text-white'
                                    : 'border-gray-500 text-gray-400'
                                }
                        `}
                            onClick={() => !isLoading && handleEnvironmentChange(env, !environments.includes(env))}
                        >
                            <div
                                className={`
                                w-3 h-3 border-2 mr-2 relative flex items-center justify-center
                                ${environments.includes(env)
                                        ? 'border-white'
                                        : 'border-gray-400'
                                    }
                            `}
                            >
                                {environments.includes(env) && (
                                    <svg
                                        className="w-3 h-3 text-white"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                    >
                                        <path
                                            d="M4 10l4 4L16 6"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                )}
                            </div>
                            <span>{env}</span>
                        </div>
                    ))}
                </div>
            </div>

            <div className="flex justify-between w-full">
                <button 
                    className="text-white opacity-50 mr-4 hover:opacity-100"
                    onClick={handleReset}
                    disabled={isLoading}
                >
                    Reset
                </button>
                <section className="flex items-end">
                    <button
                        className={`random-btn mt-4 px-8 py-1 ${!isDisable && !isLoading
                            ? "hover:bg-[rgba(255,255,255,0.2)] cursor-pointer"
                            : "cursor-not-allowed opacity-20"
                            }`}
                        disabled={isDisable || isLoading}
                        onClick={handleGenerate}
                    >
                        {isLoading ? (
                            <div className="flex items-center">
                                <span className={isLoading ? 'opacity-50' : ''}>Generating</span>
                                <svg
                                    className="animate-spin ml-2 h-4 w-4 text-white"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <circle
                                        className="opacity-25"
                                        cx="12"
                                        cy="12"
                                        r="10"
                                        stroke="currentColor"
                                        strokeWidth="4"
                                    />
                                    <path
                                        className="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    />
                                </svg>
                            </div>
                        ) : "Generate Map"}
                    </button>
                </section>
            </div>
        </div>
    );
}

export default TagSelector;
import MapGen from "../../index.ts";
import _ from "lodash";
import {
  ZOOM_MIN,
  ZOOM_MAX,
  ZOOM_STEP,
  ZOOM_DEFAULT,
  ZOOM_SLIDER_MIN,
  ZOOM_SLIDER_MAX,
  ZOOM_SLIDER_DEFAULT,
  ZOOM_FACTOR,
  MAP_BOUNDARY_LIMIT,
} from "../../const/common.ts";

class ZoomOperations {
  private zoomSliderElement: HTMLInputElement;
  private zoom: number = ZOOM_DEFAULT;
  private onWheelingCallback?: (scale: number, x: number, y: number) => void;
  //  private onMouseMoveCallback?: (x: number, y: number) => void;
  private mapGen: MapGen;
  private config: Record<string, any>;

  constructor(
    mapGen: MapGen,
    private mapElement: HTMLElement,
    config: Record<string, any>
  ) {
    this.mapGen = mapGen;
    this.config = config;
    this.mapElement.addEventListener("wheel", this.onWheel.bind(this));
    this.zoomSliderElement = document.querySelector(this.config.zoomSliderEl)!;
    this.zoomSliderElement.addEventListener(
      "input",
      this.onZoomSliderMouseMove.bind(this)
    );
    this.zoomSliderElement.value = ZOOM_SLIDER_DEFAULT.toFixed(1);
  }

  public setSliderValue(value: number, x: number, y: number) {
    this.zoomSliderElement.value = value.toFixed(1);
    document.querySelector(
      this.config.zoomSizeDisplayEl
    )!.textContent = `size: ${value.toFixed(1)} * ${value.toFixed(1)} km`;
    this.zoom = this.calculateZoomBySlider(value);
    this.onWheelingCallback?.(this.zoom, x, y);
  }

  private onZoomSliderMouseDown(event: MouseEvent) {
    if (event.target !== this.zoomSliderElement) return;
    event.preventDefault();
  }

  private onZoomSliderMouseMove(event: Event) {
    event.preventDefault();
    let value = this.zoomSliderElement.valueAsNumber;
    const zoom = this.mapGen.getRender().getZoom();
    const mapBoundarie = this.mapGen
      .getRender()
      .linearRegression(ZOOM_FACTOR / zoom);
    let { x, y } = this.mapGen.getRender().getOffset();

    x = Math.min(
      -mapBoundarie,
      Math.max(-(MAP_BOUNDARY_LIMIT - mapBoundarie), -x)
    );
    y = Math.min(
      -mapBoundarie,
      Math.max(-(MAP_BOUNDARY_LIMIT - mapBoundarie), -y)
    );
    this.setSliderValue(value, x, y);
  }

  private onZoomSliderMouseUp(event: MouseEvent) {
    event.preventDefault();
  }

  private calculateZoomBySlider(size: number) {
    const maxSize = ZOOM_SLIDER_MAX;
    const maxZoom = ZOOM_MAX;
    const minZoom = ZOOM_MIN;
    let zoom = (maxSize / size) * minZoom;

    zoom = Math.max(minZoom, Math.min(maxZoom, zoom));
    return zoom;
  }

  private onWheel(event: WheelEvent) {
    //  if(this.mapGen.isEdit()) return;
    event.preventDefault();

    const step = this.config.zoomStep || ZOOM_STEP; // 定义每次滚动的步长
    let value = this.zoomSliderElement.valueAsNumber;
    value += event.deltaY < 0 ? -step : step;

    const range = this.config.range || [ZOOM_SLIDER_MIN, ZOOM_SLIDER_MAX];
    value = Math.max(range[0], Math.min(range[1], value));
    // this.zoomSliderElement.value = value.toFixed(1);

    const zoom = this.mapGen.getRender().getZoom();
    const mapBoundarie = Math.ceil(
      this.mapGen.getRender().linearRegression(ZOOM_FACTOR / zoom)
    );
    let { x, y } = this.mapGen.getRender().getOffset();
    x = Math.ceil(
      Math.min(
        -mapBoundarie,
        Math.max(-(MAP_BOUNDARY_LIMIT - mapBoundarie), -x)
      )
    );
    y = Math.ceil(
      Math.min(
        -mapBoundarie,
        Math.max(-(MAP_BOUNDARY_LIMIT - mapBoundarie), -y)
      )
    );
    this.setSliderValue(value, x, y);
  }
  //@ts-ignore
  private onWheelThrottled(event: WheelEvent) {
    if (this.mapGen.isEdit()) return;
    event.preventDefault();
    _.throttle(() => {
      console.log("on wheel throttled");
      this.onWheel(event);
    }, 100);
  }

  public onWheeling(callback: (scale: number) => void) {
    this.onWheelingCallback = callback;
  }
  public getSliderElement() {
    return this.zoomSliderElement;
  }
  public getSliderValue() {
    return this.zoomSliderElement.valueAsNumber;
  }

  public dispose() {
    this.mapElement.removeEventListener("wheel", this.onWheel);
    this.zoomSliderElement.removeEventListener(
      "input",
      this.onZoomSliderMouseMove
    );
    document.removeEventListener("mousedown", this.onZoomSliderMouseDown);
    document.removeEventListener("mousemove", this.onZoomSliderMouseMove);
    document.removeEventListener("mouseup", this.onZoomSliderMouseUp);
  }
}

export default ZoomOperations;

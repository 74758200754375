import UEPlayer from "@/lib/ueplayer";

// Generate.tsx
export type GenerateState = {
    text: string,
    time: number,
    total_time: number,
    value: number,
    currentState: number,
    taskAhead: number,
    renderAhead: number
}

export type KeyMessage = {
    [key: number]: string;
}

export type KeySightMessage = {
    [key: string]: string;
}

export type drawBoardRef = {
    boardDrawImage: Function,
    toBlob: Function,
    canvasClear: Function,
    addChooseImage: Function,
}

export type DrawBoardRef = {
    boardDrawImage: Function,
    toBlob: Function,
    canvasClear: Function,
    addChooseImage: Function,
    pos: Function,
    clearVillage: Function,
    step: () => number,
    isColorAllowed: () => checkImg,
    getImageData: Function
}

type checkImg = {
    valicount: number,
    imglen: number
}

export type ImageList = {
    id: number,
    key: string,
    src: string,
    alt: string
}

export type RandomPic = {
    index: number,
    src: string
}

export type RefreshToken = { 
    token: string, 
    photoUrl: string 
}

export type ProgerssProps = {
    loading: boolean,
    status: number,
    btnStyle: React.CSSProperties,
    value: number,
    total_time: number,
    taskAhead:number, 
    renderAhead: number,
    genScene: () => void,
    isClosed: boolean
}

export type Pos = {
    imgId: string;
    type: string;
    pos: number[];
}

export type MapgenPropsCallback = (paint: Pos, blob: Blob, blob2: Blob, enable: Function) => void

export type MapgenProps = {
    callback: MapgenPropsCallback,
    loading: boolean
}

export enum MapgenTypeEnums {
  ocean = "ocean",
  water = "shallow",
  valley = "valley",
  mountain = "mountain",
  vaillage = "vaillage",
  deleteVaillage = "deleteVaillage",
  xtiny = "xtiny",
  tiny = "tiny",
  small = "small",
  medium = "medium",
  large = "large",
  pan = "pan",
}

export type MapgenSelect = {
  type: MapgenTypeEnums;
  vaillage?: MapgenTypeEnums;
  size: MapgenTypeEnums;
};

export type MapgenStyleItem = {
  src: string;
  name: string;
  key: number;
}

export type UeplayerRefProps = {
  getUeplayerRef: () => UEPlayer | null
}
export const initialParams = {
  elevation: [
    [
      "seed",
      "seed",
      Math.floor(Math.random() * (1 << 30)),
      1,
      1 << 30,
      "hidden",
    ],
    ["island", "island", 0.5, 0, 1, "hidden"],
    ["noisy_coastlines", "Coastlines", 0.01, 0, 0.1, "hidden"],
    ["hill_height", "Hill Height", 0.00005, 0, 0.1, "hidden"],
    ["mountain_jagged", "Mountain Jagged", 0, 0, 1, "hidden"],
    ["mountain_sharpness", "Mountain Sharpness", 11.5, 9.1, 12.5, "show"],
    ["ocean_depth", "Ocean Depth", 1.5, 1, 3, "hidden"],
  ],
  biomes: [
    ["wind_angle_deg", "Wind Angle", 0, 0, 360, "hidden"],
    ["raininess", "Raininess", 0.9, 0, 2, "hidden"],
    ["rain_shadow", "Rain Shadow", 0.5, 0.1, 2, "hidden"],
    ["evaporation", "Evaporation", 0.5, 0, 1, "hidden"],
  ],
  rivers: [
    ["lg_min_flow", "Min Flow", 2.7, -5, 5, "hidden"],
    ["lg_river_width", "River Width", 3, 0, 5, "show"],
    ["flow", "Flow", 0.2, 0, 1, "show"],
  ],
  render: [
    ["zoom", "Zoom", 100 / 480, 100 / 1000, 100 / 50, "hidden"],
    ["x", "X", 500, 0, 1000, "hidden"],
    ["y", "Y", 500, 0, 1000, "hidden"],
    ["light_angle_deg", "Light Angle", 120, 0, 360, "hidden"],
    ["slope", "Slope", 1, 0, 5, "hidden"],
    ["flat", "Flat", 2, 0, 5, "hidden"],
    ["ambient", "Ambient", 0.08, 0, 1, "hidden"],
    ["overhead", "Overhead", 40, 0, 60, "hidden"],
    ["tilt_deg", "Tilt Degree", 0, 0, 90, "show"],
    ["rotate_deg", "Rotate Degree", 0, -180, 180, "show"],
    ["mountain_height", "Mountain Height", 60, 0, 250, "show"],
    ["outline_depth", "Outline Depth", 1, 0, 2, "hidden"],
    ["outline_strength", "Outline Strength", 10, 0, 30, "hidden"],
    ["outline_threshold", "Outline Threshold", 0, 0, 100, "hidden"],
    ["outline_coast", "Outline Coast", 0, 0, 1, "hidden"],
    ["outline_water", "Outline Water", 10.0, 0, 20, "hidden"], // things start going wrong when this is high
    ["biome_colors", "Biome Colors", 1, 0, 1, "hidden"],
  ],
  others: [
    ["island", "Island", 0.5, 0, 1, "show"],
    ["hill_height", "Hill Height", 0.00005, 0, 0.1, "show"],
  ],
};

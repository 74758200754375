import { useEffect, useState } from 'react';
import { taskStatus } from '@/service/taskService';
import { GenerateUEProgress } from "@/lib/utils";

interface UETaskProgressProps {
    taskId: string;
    onComplete: (isSuccess: boolean, status: number, streamID: string) => void;
    startTime: number;
}

function UETaskProgress({ taskId, onComplete, startTime }: UETaskProgressProps) {
    const [progress, setProgress] = useState(0);
    const [elapsedTime, setElapsedTime] = useState(0);
    const [currentStatus, setCurrentStatus] = useState(0);
    const [taskQueueAhead, setTaskQueueAhead] = useState(0);
    const [renderQueueAhead, setRenderQueueAhead] = useState(0);

    useEffect(() => {
        let isMounted = true;

        const fetchProgress = async () => {
            try {
                const response = await taskStatus(taskId);
                let status = parseInt(response.data.status);
                let streamID = response.data.ue_worker_id;
                const render_queue_ahead = parseInt(response.data.render_queue_ahead);
                const task_queue_ahead = parseInt(response.data.task_queue_ahead);
                if (!isMounted) return false;
               
                
                setCurrentStatus(status);
                setTaskQueueAhead(task_queue_ahead);
                setRenderQueueAhead(render_queue_ahead);
                
                if (status >= 370 && status < 380) {
                    onComplete?.(false, status, streamID);
                    return true;
                }

                if (status === 360) {
                    setProgress(100);
                    onComplete?.(true, status, streamID);
                    return true;
                }
                
                setProgress(prev => {
                    const increment = 0.2;
                    const newProgress = Math.min(prev + increment, 99);
                    return newProgress;
                });
                
                setElapsedTime(prev => prev + 2000);
                return false;

            } catch (error) {
                if (isMounted) {
                    onComplete?.(false, 500, "");
                }
                return true;
            }
        };

        fetchProgress();

        const interval = setInterval(async () => {
            const shouldStop = await fetchProgress();
            if (shouldStop && isMounted) {
                clearInterval(interval);
            }
        }, 2000);

        return () => {
            isMounted = false;
            clearInterval(interval);
        };
    }, [taskId, onComplete, startTime]);

    // console.log({currentStatus, taskQueueAhead, renderQueueAhead});
    
    return (
        <>
            <div className="mx-2">
                <progress value={progress} max="100" className="w-60 model-progress"></progress>
            </div>
            {renderQueueAhead > 0 ? (
                <span>Number of people waiting: {renderQueueAhead}</span>
            ) : (
                <span>{GenerateUEProgress(currentStatus, taskQueueAhead, renderQueueAhead)},Total Time:{elapsedTime / 1000 + startTime}s</span>
            )}
        </>
    );
};

export default UETaskProgress;
// @ts-nocheck

import MapGen from "@/index.ts";
import _ from "lodash";
import ZoomOperations from "./zoom.ts";
import DragOperations from "./drag.ts";

class MapOperations {

    private zoomOpe: ZoomOperations;
    private dragOpe: DragOperations;
    constructor(mapElement: HTMLElement,config:Record<string, any>,mapGen:MapGen) {

        //mapGen.setEditMode(false);
        const outerElement = document.getElementById(config.panElementId);
        if (outerElement) {
            outerElement.addEventListener('click', () => {
                mapGen.setEditMode(false);
            });
        }
        const {supports} = config;
        supports.forEach((subConfig:Record<string, any>) => {
            if(subConfig.type === "zoom"){
                this.zoomOpe = new ZoomOperations(mapGen,mapElement,subConfig);
            }
            if(subConfig.type === "drag"){
                this.dragOpe = new DragOperations(mapGen,mapElement);
            }
        });
    }

    public getZoom(){
        return this.zoomOpe;
    }

    public getDrag(){
        return this.dragOpe;
    }

    public dispose() {
        this.zoomOpe.dispose();
        this.dragOpe.dispose();
    }
}

export default MapOperations;
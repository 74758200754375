const TERRAIN_WETLAND = 0;
const TERRAIN_SNOW_MOUNTAIN = 1;
const TERRAIN_DESERT = 2;

class Colormap {
    private width: number = 64;
    private height: number = 64;
    private color: Uint8Array;
    private terrain:number = 0;
    private data:{
        width:number,
        height:number,
        color:Uint8Array
    } = {
        width:0,
        height:0,
        color:new Uint8Array(0)
    }

    constructor() {
        this.color = this.generateColormap();
        this.data = {
            width:this.width,
            height:this.height,
            color:this.color
        }
    }

    public setTerrain(terrain:number):void {
        this.terrain = terrain;
        this.color = this.generateColormap();
        this.data = {
            width:this.width,
            height:this.height,
            color:this.color
        }
    }

    private generateColormap(): Uint8Array {
        const pixels = new Uint8Array(this.width * this.height * 4);

        for (let y = 0, p = 0; y < this.height; y++) {
            for (let x = 0; x < this.width; x++) {
                let e = 2 * x / this.width - 1,
                    m = y / this.height;
                let r, g, b;

                if (x === this.width / 2 - 1) {
                    r = 48;
                    g = 120;
                    b = 160;
                } else if (x === this.width / 2 - 2) {
                    r = 48;
                    g = 100;
                    b = 150;
                } else if (x === this.width / 2 - 3) {
                    r = 48;
                    g = 80;
                    b = 140;
                } else if (e < 0.0) {
                    r = 48 + 48 * e;
                    g = 64 + 64 * e;
                    b = 150 + 127 * e;
                } else {
                    m = m * (1 - e);
                    const terrainColors: { [key: number]: [number, number, number] } = {
                        [TERRAIN_WETLAND]: [210, 185, 139], 
                        [TERRAIN_SNOW_MOUNTAIN]: [255, 255, 255], 
                        [TERRAIN_DESERT]: [175, 95, 60]
                    };

                    const originRGB = terrainColors[this.terrain] || [0, 0, 0];
                    let [or, og, ob] = originRGB;
                    r = or - 100 * m;
                    g = og - 45 * m;
                    b = ob - 45 * m;
                    r = 255 * e + r * (1 - e);
                    g = 255 * e + g * (1 - e);
                    b = 255 * e + b * (1 - e);
                }

                pixels[p++] = r;
                pixels[p++] = g;
                pixels[p++] = b;
                pixels[p++] = 100;
            }
        }
        return pixels;
    }

    public getColormap(){
        return this.data;
    }
}

export default Colormap;
interface WebRTCConfig {
    serverUrl: string;
    videoElement: HTMLVideoElement;
    streamerId: string;
    onLoss?: () => void;
    onSend?: (data: any) => void;
    onConnected?: () => void;
}

class WebRTCClient {
    // WebSocket related properties
    public wsUrl: string;
    public socket: WebSocket | null = null;
    public sfuSocket: WebSocket | null = null;
    private onLoss: ((status: number) => void) | null = null;
    private onSend: ((data: any) => void) | null = null;
    private onConnected: (() => void) | null = null;
   


    // PeerConnection related properties
    public streamID: string;
    public remoteVideo: HTMLVideoElement | null;
    public peerConnection: RTCPeerConnection | null = null;

    // Stream list related properties
    private availableStreamList: string[] = [];
    private streamListCheckInterval: number | null = null;
    private streamListCheckAttempts: number = 0;
    private maxStreamListCheckAttempts: number = 30;
    private reconnectIntervalTime: number = 3000; // listStreamer 3秒后发送


    // Statistics related properties
    private statsInterval: number | null = null;
    // private statsIntervalTime: number = 5000; // 5 seconds

    private debug: boolean = false;
    // private isIceDisconnectedFake: boolean = true;
    private readonly defaultStunServers: RTCIceServer[] = [
        // {
        //     urls: ['stun:stun.l.google.com:19302',
        //         'stun:stun1.l.google.com:19302',
        //         'stun:stun2.l.google.com:19302',
        //         'stun:stun3.l.google.com:19302',
        //         'stun:stun4.l.google.com:19302',
        //         "stun:iphone-stun.strato-iphone.de:3478",
        //         "stun:stun.xirsys.com"]
        // },
    ];

    // 
    private subscribeTimeout: number | null = null;
    private currentSubscribedId: string | null = null;
    private readonly SUBSCRIBE_TIMEOUT = 15000; // 15 seconds
    private subscribeStartTime: number = 0;  // 添加订阅开始时间记录
    private isSubscribed: boolean = false;

    // Add new property for reconnection
    private wsReconnectAttempts: number = 0;
    private readonly MAX_RECONNECT_ATTEMPTS: number = 5;
    private wsReconnectTimeout: number | null = null;
    // private readonly RECONNECT_DELAY: number = 2000; // 3 seconds

    // Update status definitions
    private readonly STATUS = {
        INIT: 100,
        WS_CONNECTED: 101,
        PEER_INIT_CONNECTED: 200,
        PEER_CONNECTED: 201,
        STREAM_INIT_CONNECTED: 300,
        STREAM_CONNECTED: 301,
        CLOSED: 400,
        ERROR: 500
    };
    
    // Add current status tracking
    private currentStatus: number = 100;  // Initialize with INIT status

    // Add new property for ice transport policy
    private iceTransportPolicy: RTCIceTransportPolicy = 'all';
    // private iceTransportPolicy: RTCIceTransportPolicy = 'relay';

    // private simulateDisconnectOnce: boolean = true;
    // private isSimulatingDisconnect: boolean = false;  // Add this flag

    constructor(config: WebRTCConfig) {
        this.wsUrl = config.serverUrl;
        this.streamID = config.streamerId;
        this.remoteVideo = config.videoElement;
        this.onLoss = config.onLoss || null;
        this.onSend = config.onSend || null;
        this.onConnected = config.onConnected || null;
        this.initializeWebSocket();
        // this.startStatsInterval(); // Statistics
    }


    
    private logWithTimestamp(message: string, ...args: any[]): void {
        if (this.debug) {
            const timestamp = new Date().toISOString();
            console.log(`[${timestamp}] ${message}`, ...args);
        }
    }

    // WebSocket related methods
    private initializeWebSocket(): void {
        try {
            this.socket = new WebSocket(this.wsUrl);
            this.isSubscribed = false;
            this.socket.onopen = () => {
                this.wsReconnectAttempts = 0; // Reset attempts on successful connection
                this.handleSocketOpen();
            };
            this.socket.onmessage = this.handleSocketMessage.bind(this);
            this.socket.onclose = this.handleSocketClose.bind(this);
            this.socket.onerror = this.handleSocketError.bind(this);
        } catch (error) {
            this.logWithTimestamp("Error initializing WebSocket:", error);
            this.loss(3);
        }
    }


    private handleSocketOpen(): void {
        this.setStatus(this.STATUS.WS_CONNECTED);
        this.logWithTimestamp("Video WebSocket connection established");
        // Clear existing timeout if any
        if (this.wsReconnectTimeout) {
            clearTimeout(this.wsReconnectTimeout);
        }
    }
    public sendMessage(message: any): void {
        this.logWithTimestamp("Sending message:", message);
        try {
            const messageString = JSON.stringify(message);
            if (!this.socket || this.socket.readyState === WebSocket.CLOSING || this.socket.readyState === WebSocket.CLOSED) {
                return;
            }
            if (this.socket.readyState === WebSocket.OPEN) {
                this.socket.send(messageString);
            }
        } catch (error) {
            this.logWithTimestamp("Error sending message");
            if (this.onLoss) {
                this.loss(3);
            }
        }
    }

    private handleSocketClose(): void {
        this.logWithTimestamp("Video WebSocket connection closed");
        
        // Don't attempt reconnection if status is CLOSED
        if (this.currentStatus === this.STATUS.CLOSED) {
            this.logWithTimestamp("Connection is closed, skipping reconnection");
            return;
        }
        
        this.setStatus(this.STATUS.INIT);  // Reset to initial state
        
        // Clear existing timeout if any
        if (this.wsReconnectTimeout) {
            clearTimeout(this.wsReconnectTimeout);
        }
        
        // Attempt reconnection if under max attempts
        if (this.wsReconnectAttempts < this.MAX_RECONNECT_ATTEMPTS) {
            this.wsReconnectAttempts++;
            this.logWithTimestamp(`Attempting to reconnect... (Attempt ${this.wsReconnectAttempts}/${this.MAX_RECONNECT_ATTEMPTS})`);
            this.reinitialize();
        } else {
            this.logWithTimestamp("Max reconnection attempts reached");
            this.onConnected && this.onConnected();
            this.loss(3);
        }
    }

    private handleSocketError(error: Event): void {
        this.logWithTimestamp("Video WebSocket error details:", {
            readyState: this.socket?.readyState,
            timestamp: new Date().toISOString(),
            errorType: error.type
        });
        this.setStatus(this.STATUS.INIT);  // Reset to initial state
        
        if (!navigator.onLine) {
            this.logWithTimestamp("Network connection lost, waiting for recovery...");
            this.loss(2);
        }else{
            this.loss(3);
        }
    }

    // Message handling methods
    public async handleSocketMessage(message: MessageEvent): Promise<void> {
        try {
            const signal = JSON.parse(message.data);
            this.logWithTimestamp("Received signal:", signal);
            switch (signal.type) {
                case "config":
                    await this.handleConfig(signal);
                    break;
                case "offer":
                    await this.handleOffer(signal);
                    break;
                case "answer":
                    await this.handleAnswer(signal);
                    break;
                case "iceCandidate":
                    await this.handleIceCandidate(signal);
                    break;
                case "streamerList":
                    await this.handleStreamerList(signal);
                    break;
                default:
                    this.logWithTimestamp("Unknown signal type: " + signal.type);
            }
        } catch (error) {
            this.logWithTimestamp("Error processing WebSocket message:", error);
        }
    }

    private async handleConfig(config: any): Promise<void> {
        this.logWithTimestamp("Received config signal:", config);
        const defaultConfiguration: RTCConfiguration = {
            iceTransportPolicy: this.iceTransportPolicy,  // Use the configurable policy
            iceCandidatePoolSize: 20,
            iceServers: this.defaultStunServers
        };

        // Merge default settings, server-provided settings, and default STUN servers
        const configuration: RTCConfiguration = {
            ...defaultConfiguration,
            ...config.peerConnectionOptions,
            iceServers: [
                ...this.defaultStunServers,
                ...(config.peerConnectionOptions.iceServers || [])
            ]
        };

        this.peerConnection = new RTCPeerConnection(configuration);
        this.initializePeerConnection();
        this.sendMessage({ type: "listStreamers" }); 
 
    }

    private modifySdp(sdp: string): string {
        const sdpLines = sdp.split('\r\n');
        const videoIndex = sdpLines.findIndex(line => line.startsWith('m=video'));

        if (videoIndex === -1) {
            return sdp;
        }

        // Set maximum bitrate (e.g., 2Mbps)
        sdpLines.splice(videoIndex + 1, 0, 'b=AS:10000');

        // Set maximum frame rate (e.g., 30fps)
        const maxFramerate = 30;
        const fmtpLineIndex = sdpLines.findIndex(line => line.startsWith('a=fmtp:'));
        if (fmtpLineIndex !== -1) {
            const fmtpLine = sdpLines[fmtpLineIndex];
            if (fmtpLine.includes('max-fr=')) {
                // If max-fr is already present, update it
                sdpLines[fmtpLineIndex] = fmtpLine.replace(/max-fr=\d+/, `max-fr=${maxFramerate}`);
            } else {
                // If max-fr is not present, add it
                sdpLines[fmtpLineIndex] = `${fmtpLine};max-fr=${maxFramerate}`;
            }
        } else {
            // If no fmtp line is found, add a new fmtp line after the video line
            const videoPayloadType = sdpLines[videoIndex].split(' ')[3];
            sdpLines.splice(videoIndex + 1, 0, `a=fmtp:${videoPayloadType} max-fr=${maxFramerate}`);
        }
        return sdpLines.join('\r\n');
    }
    private async handleOffer(signal: any): Promise<void> {
        if (!this.peerConnection) {
            this.logWithTimestamp("PeerConnection not initialized");
            return;
        }

        try {
            const offer = signal.sdp;
            await this.peerConnection.setRemoteDescription(new RTCSessionDescription({ type: "offer", sdp: offer }));
            const answer = await this.peerConnection.createAnswer();
            // Modify answer SDP using coding information extracted from offer
            const modifiedSdp = answer.sdp ? this.modifySdp(answer.sdp) : '';
            const modifiedAnswer = new RTCSessionDescription({
                type: 'answer',
                sdp: modifiedSdp
            });
            await this.peerConnection.setLocalDescription(modifiedAnswer);
            // Send modified answer to signaling server
            this.sendMessage({ type: "answer", sdp: modifiedSdp });
        } catch (error) {
            this.logWithTimestamp('Error processing offer:', error);
        }
    }


    /*  处理answer  */
    private async handleAnswer(answer: RTCSessionDescriptionInit): Promise<void> {
        if (this.peerConnection) {
            this.logWithTimestamp("Setting remote description to answer...");
            await this.peerConnection.setRemoteDescription(new RTCSessionDescription(answer));
            this.logWithTimestamp("Completed setting remote description to answer");
        } else {
            this.logWithTimestamp("PeerConnection not initialized");
        }
    }

    private async handleIceCandidate(signal: any): Promise<void> {
        if (this.peerConnection) {
            try {
                await this.peerConnection.addIceCandidate(new RTCIceCandidate(signal.candidate));
            } catch (error) {
                this.logWithTimestamp("Error adding ICE candidate from signaling server:", error);
            }
        } else {
            this.logWithTimestamp("PeerConnection not initialized");
        }
    }

    // Stream list related methods
    private isValidStreamId(streamId: string): boolean {
        const regex = /^(.+?)(?:@\d+)*$/;
        return this.availableStreamList.some(id => {
            const baseId = id.match(regex)?.[1] || id;
            const baseStreamId = streamId.match(regex)?.[1] || streamId;
            return baseId === baseStreamId;
        });
    }

    private findBestMatchingStreamId(streamId: string): string | null {
        const regex = /^(.+?)(?:@(\d+))*$/;
        const baseStreamId = streamId.match(regex)?.[1] || streamId;
        
        const matchingIds = this.availableStreamList.filter(id => {
            const baseId = id.match(regex)?.[1] || id;
            return baseId === baseStreamId;
        });

        if (matchingIds.length === 0) {
            return null;
        }

        return matchingIds.reduce((a, b) => {
            const aMatch = a.match(regex);
            const bMatch = b.match(regex);
            const aNum = aMatch?.[2] ? parseInt(aMatch[2]) : -1;
            const bNum = bMatch?.[2] ? parseInt(bMatch[2]) : -1;
            return bNum > aNum ? b : a;
        });
    }

    // Public methods
    public subscribe(streamerId: string): void {
        this.logWithTimestamp("Subscribing to streamer:", streamerId);
        if (streamerId && !this.isSubscribed) {
            // Clear any existing timeout first
            this.clearSubscribeTimeout();
            
            this.currentSubscribedId = streamerId;
            this.subscribeStartTime = Date.now();
            this.sendMessage({ type: "subscribe", streamerId: streamerId });
            this.isSubscribed = true;
            
            // Start new timer
            this.subscribeTimeout = window.setTimeout(() => {
                // Check if we're still in a valid state before handling timeout
                if (this.currentStatus !== this.STATUS.CLOSED && 
                    this.currentSubscribedId === streamerId) {
                    this.logWithTimestamp('============ 取流超时 重新订阅 =========================');
                    // 清除当前的timeout
                    this.clearSubscribeTimeout();
                    // 重置订阅状态
                    this.isSubscribed = false;
                    this.currentSubscribedId = null;
                    
                    this.onSend && this.onSend({ "command": "UELost", "streamerId": this.streamID });
                    this.logWithTimestamp('============ UELost Sended =========================');

                    // 3秒后发送listStreamers
                    setTimeout(() => {
                        this.sendMessage({ type: "listStreamers" });
                    }, 3000);
                }
            }, this.SUBSCRIBE_TIMEOUT);
        }
    }

    private async handleStreamerList(signal: any): Promise<void> {
        this.availableStreamList = signal.ids;
        
        this.logWithTimestamp('availableStreamList:', this.availableStreamList);
        if (this.isValidStreamId(this.streamID)) {
            this.clearStreamListCheck();
            const bestMatchingId = this.findBestMatchingStreamId(this.streamID);
            if (bestMatchingId) {
                this.subscribe(bestMatchingId);
            }
        } else {
            this.logWithTimestamp(`Current streamID ${this.streamID} not in available list`);
            this.startStreamListCheck();
        }
    }


    private startStreamListCheck(): void {
        // 已经在轮询中，不需要重新开始
        if (this.streamListCheckInterval !== null) {
            this.logWithTimestamp("StreamList check already in progress...");
            return;
        }

        // 检查当前是否已经有可用的匹配stream
        if (this.isValidStreamId(this.streamID)) {
            const bestMatchingId = this.findBestMatchingStreamId(this.streamID);
            if (bestMatchingId) {
                this.logWithTimestamp("Valid stream ID found, subscribing:", bestMatchingId);
                this.subscribe(bestMatchingId);
                return;
            }
        }

        // 重尝试次数并始轮询
        this.streamListCheckAttempts = 0;
        this.streamListCheckInterval = window.setInterval(() => {
            // 检查是否达到最大尝试次数
            if (this.streamListCheckAttempts >= this.maxStreamListCheckAttempts) {
                this.logWithTimestamp('Maximum stream list check attempts reached, stopping checks...');
                this.clearStreamListCheck();
                this.loss(1);
                return;
            }

            // 检查是否已有可用stream
            if (this.isValidStreamId(this.streamID)) {
                const bestMatchingId = this.findBestMatchingStreamId(this.streamID);
                if (bestMatchingId) {
                    this.logWithTimestamp("Valid stream ID found during check, subscribing:", bestMatchingId);
                    this.subscribe(bestMatchingId);
                    this.clearStreamListCheck();
                    return;
                }
            }

            this.streamListCheckAttempts++;
            this.logWithTimestamp(`Checking stream list (${this.streamListCheckAttempts}/${this.maxStreamListCheckAttempts})`);

            // 在第3次尝试时发送UELost
            if (this.streamListCheckAttempts === 2 && !this.isValidStreamId(this.streamID)) {
                this.logWithTimestamp("UE lost, send UELost command");
                this.onSend && this.onSend({ "command": "UELost", "streamerId": this.streamID });
            }
        }, this.reconnectIntervalTime);
    }

    private clearStreamListCheck(): void {
        if (this.streamListCheckInterval !== null) {
            clearInterval(this.streamListCheckInterval);
            this.streamListCheckInterval = null;
        }
        this.streamListCheckAttempts = 0;
    }

    // PeerConnection related methods
    private initializePeerConnection(): void {
        if (!this.peerConnection) {
            this.logWithTimestamp("PeerConnection not initialized");
            this.onLoss && this.onLoss(3);
            return;
        }
        this.peerConnection.onicecandidate = this.handleLocalIceCandidate.bind(this);
        this.peerConnection.ontrack = this.handleTrack.bind(this);
        this.peerConnection.onconnectionstatechange = this.handleConnectionStateChange.bind(this);
        this.peerConnection.oniceconnectionstatechange = this.handleIceConnectionStateChange.bind(this);
        this.peerConnection.onicegatheringstatechange = this.handleIceGatheringStateChange.bind(this);
        this.logWithTimestamp('============= PeerConnection initialized');
    }

    private handleIceGatheringStateChange(): void {
        if (!this.peerConnection) return;

        const state = this.peerConnection.iceGatheringState;
        this.logWithTimestamp(`ICE gathering state changed to: ${state}`);

        switch (state) {
            case 'complete':
                this.peerConnection.getStats().then(stats => {
                    let candidatesCount = {
                        host: 0,
                        srflx: 0,
                        relay: 0
                    };

                    stats.forEach(report => {
                        if (report.type === 'local-candidate') {
                            if (report.candidateType === 'host') candidatesCount.host++;
                            if (report.candidateType === 'srflx') candidatesCount.srflx++;
                            if (report.candidateType === 'relay') candidatesCount.relay++;
                        }
                    });

                    if (candidatesCount.srflx === 0 && candidatesCount.relay === 0) {
                        this.logWithTimestamp('WARNING: No STUN/TURN candidates gathered - NAT traversal may fail');
                    }
                });
                break;
        }
    }

    private handleLocalIceCandidate(event: RTCPeerConnectionIceEvent): void {
        if (event.candidate) {
            if (event.candidate.candidate.includes("typ relay")) {
                this.logWithTimestamp(" =================     Connection is using TURN relay server.");
            } else if (event.candidate.candidate.includes("typ srflx")) {
                this.logWithTimestamp("==================  Connection is P2P through STUN (Server Reflexive).");
            } else if (event.candidate.candidate.includes("typ host")) {
                this.logWithTimestamp("===================  Connection is P2P with local network.");
            }
               // 判断是否为UDP
            if (event.candidate.protocol === 'udp') {
                this.logWithTimestamp('Using UDP');
            } else if (event.candidate.protocol === 'tcp') {
                this.logWithTimestamp('Using TCP');
            }
            this.sendMessage({
                type: "iceCandidate",
                candidate: event.candidate
            });
        } else {
            this.logWithTimestamp(' ICE candidate event is null');
            this.logWithTimestamp('ICE candidate:', String(event.candidate));
        }
    }

    private handleTrack(event: RTCTrackEvent): void {
        if (event.track.kind === 'video' && this.remoteVideo) {
            const timeElapsed = Date.now() - this.subscribeStartTime;
            this.logWithTimestamp(`Received video track after ${timeElapsed}ms, clearing subscribe timeout`);
            this.clearSubscribeTimeout();
            this.setStatus(this.STATUS.STREAM_INIT_CONNECTED);
            this.remoteVideo.srcObject = event.streams[0];
            this.remoteVideo.style.display = 'block';
            this.setStatus(this.STATUS.STREAM_CONNECTED);
        }
        event.track.onended = () => {
            this.logWithTimestamp("Track video stream from UE ended");
        };
    }

    private handleConnectionStateChange(): void {
        if (this.peerConnection) {
            const state = this.peerConnection.connectionState;
            this.logWithTimestamp(`PeerConnection state changed to: ${state}`);
            
            switch (state) {
                case 'disconnected':
                case 'failed':
                    this.handlePeerDisconnection(state);
                    break;
                case 'connecting':
                    this.setStatus(this.STATUS.PEER_INIT_CONNECTED);
                    break;
                case 'connected':
                    this.logWithTimestamp('PeerConnection successfully connected');
                    this.setStatus(this.STATUS.PEER_CONNECTED);
                    this.clearStreamListCheck();
                    this.onConnected && this.onConnected();
                    break;
            }
        }
    }

    private handleIceConnectionStateChange(): void {
        if (this.peerConnection) {
            const state = this.peerConnection.iceConnectionState;
            this.logWithTimestamp(`ICE connection state changed to: ${state}`);
            
            switch (state) {
                case 'disconnected':
                    this.logWithTimestamp('ICE disconnected - Temporary connectivity lost');
                    this.logIceFailureDetails();
                    this.iceTransportPolicy = 'relay';
                    this.logWithTimestamp('Switching to relay-only ICE transport policy');
                    this.reinitializePeerConnection();
                    break;
                case 'failed':
                    this.logWithTimestamp('ICE failed - Connection attempt failed');
                    this.logIceFailureDetails();
                    this.iceTransportPolicy = 'relay';
                    this.logWithTimestamp('Switching to relay-only ICE transport policy');
                    this.reinitializePeerConnection();
                    break;
                case 'checking':
                    this.logWithTimestamp('ICE checking - Connecting to peer...');
                    break;
                case 'connected':
                    this.logWithTimestamp('ICE connected - Media connection established');
                    // Only simulate disconnection if we haven't done it yet and we're not already simulating
                    // if (this.simulateDisconnectOnce && !this.isSimulatingDisconnect) {
                    //     this.logWithTimestamp('Simulated ICE disconnection triggered');
                    //     this.simulateIceDisconnection();
                    // }
                    break;
                case 'closed':
                    this.logWithTimestamp('ICE closed - Connection closed');
                    break;
            }
        }
    }

    // 添加新方法来记录ICE失败的详细信息
    private async logIceFailureDetails(): Promise<void> {
        if (!this.peerConnection) return;

        try {
            const stats = await this.peerConnection.getStats();
            let failureDetails = {
                candidatePairs: [] as any[],
                localCandidates: [] as any[],
                remoteCandidates: [] as any[],
                networkStatus: {
                    online: navigator.onLine,
                    type: (navigator as any).connection?.type || 'unknown'
                }
            };

            stats.forEach(report => {
                switch (report.type) {
                    case 'candidate-pair':
                        failureDetails.candidatePairs.push({
                            state: report.state,
                            localType: report.localCandidateType,
                            remoteType: report.remoteCandidateType,
                            protocol: report.protocol,
                            priority: report.priority,
                            nominated: report.nominated,
                            bytesSent: report.bytesSent,
                            bytesReceived: report.bytesReceived,
                            lastPacketSentTimestamp: report.lastPacketSentTimestamp,
                            lastPacketReceivedTimestamp: report.lastPacketReceivedTimestamp
                        });
                        break;
                    case 'local-candidate':
                        failureDetails.localCandidates.push({
                            protocol: report.protocol,
                            type: report.candidateType,
                            ip: report.ip,
                            port: report.port,
                            priority: report.priority,
                            networkType: report.networkType
                        });
                        break;
                    case 'remote-candidate':
                        failureDetails.remoteCandidates.push({
                            protocol: report.protocol,
                            type: report.candidateType,
                            ip: report.ip,
                            port: report.port,
                            priority: report.priority
                        });
                        break;
                }
            });

            this.logWithTimestamp('ICE Failure Details:', {
                timestamp: new Date().toISOString(),
                iceConnectionState: this.peerConnection.iceConnectionState,
                iceGatheringState: this.peerConnection.iceGatheringState,
                signalingState: this.peerConnection.signalingState,
                connectionState: this.peerConnection.connectionState,
                details: failureDetails
            });

            // 分析可能的失败原因
            this.analyzeIceFailure(failureDetails);

        } catch (error) {
            this.logWithTimestamp('Error getting ICE failure details:', error);
        }
    }

    // 添加新方法来分析ICE失败的可能原因
    private analyzeIceFailure(details: any): void {
        const possibleReasons = [];

        // 检查网络连接
        if (!details.networkStatus.online) {
            possibleReasons.push('No internet connection');
        }

        // 检查候选者对
        if (details.candidatePairs.length === 0) {
            possibleReasons.push('No candidate pairs generated');
        }

        // 检查是否有STUN/TURN候选者
        const hasStunCandidate = details.localCandidates.some((c: any) => c.type === 'srflx');
        const hasTurnCandidate = details.localCandidates.some((c: any) => c.type === 'relay');

        if (!hasStunCandidate && !hasTurnCandidate) {
            possibleReasons.push('No STUN/TURN candidates available - possible NAT traversal issues');
        }

        // 检查UDP阻塞情况
        const hasUdpCandidate = details.localCandidates.some((c: any) => c.protocol === 'udp');
        if (!hasUdpCandidate) {
            possibleReasons.push('No UDP candidates - possible firewall blocking');
        }

        // 检查连接超时
        const candidatePairsWithTraffic = details.candidatePairs.filter((pair: any) => 
            pair.bytesSent > 0 || pair.bytesReceived > 0
        );
        if (candidatePairsWithTraffic.length === 0) {
            possibleReasons.push('No traffic observed on any candidate pair - possible connectivity issue');
        }

        if (possibleReasons.length > 0) {
            this.logWithTimestamp('Possible ICE failure reasons:', possibleReasons);
        } else {
            this.logWithTimestamp('No specific ICE failure reason identified');
        }
    }

    // 新增处理 PeerConnection 断开的方法
    private async handlePeerDisconnection(state: string): Promise<void> {
        this.logWithTimestamp(`PeerConnection ${state}: attempting recovery...`);
        
        // Log disconnection details
        this.peerConnection?.getStats().then(stats => {
            stats.forEach(report => {
                if (report.type === 'peer-connection') {
                    this.logWithTimestamp('PeerConnection Disconnection details:', {
                        dataChannelsClosed: report.dataChannelsClosed,
                        dataChannelsOpened: report.dataChannelsOpened,
                        connectionType: report.connectionType
                    });
                }
            });
        }).catch(err => {
            this.logWithTimestamp('Error getting disconnection stats:', err);
        });

        // Use reinitialize for recovery
        if (this.currentStatus !== this.STATUS.CLOSED) {
            this.logWithTimestamp('Initiating reconnection sequence...');
            try {
                // Create a new offer with ICE restart
                const offer = await this.peerConnection?.createOffer({ iceRestart: true });
                if (offer) {
                    await this.peerConnection?.setLocalDescription(offer);
                    this.sendMessage({ type: "offer", sdp: offer.sdp });
                }
            } catch (error) {
                this.logWithTimestamp('Error during ICE restart:', error);
                this.loss(3);
            }
        } else {
            this.logWithTimestamp('Connection is closed, skipping reconnection');
        }
    }

    public loss(status: number) {
        if (status === 3){
            if (!navigator.onLine) {
                this.logWithTimestamp("Network connection lost, waiting for recovery...");
                this.onLoss && this.onLoss(2);
            }
            else{
                this.logWithTimestamp("webRTC loss call");
                this.setStatus(this.STATUS.CLOSED);
                this.onLoss && this.onLoss(status);
            }
        }
    }
    public destroy(): void {
        this.clearSubscribeTimeout();  // Clear timeout before destruction
        // Set status to closed
        this.setStatus(this.STATUS.CLOSED);

        // Clean up WebSocket connection
        if (this.socket) {
            this.socket.close();
            this.socket = null;
        }

        // Clean up WebRTC connection
        if (this.peerConnection) {
            this.peerConnection.close();
            this.peerConnection = null;
        }

        // Clean up video element
        if (this.remoteVideo) {
            this.remoteVideo.srcObject = null;
        }

        // Clean up intervals and timeouts
        [
            this.streamListCheckInterval,
            this.statsInterval,
            this.subscribeTimeout,
            this.wsReconnectTimeout
        ].forEach(timer => {
            if (timer !== null) {
                clearTimeout(timer);
                timer = null;
            }
        });

        // Reset state variables
        this.availableStreamList = [];
        this.streamListCheckAttempts = 0;
        this.currentSubscribedId = null;
        this.isSubscribed = false;
        this.logWithTimestamp("WebRTCClient instance destroyed");
    }

    /*  获取连接状态  */
    // private startStatsInterval(): void {
    //     this.statsInterval = window.setInterval(() => {
    //         this.getConnectionStats();
    //     }, this.statsIntervalTime);
    // }

    // private async getConnectionStats(): Promise<void> {
    //     if (!this.peerConnection) {
    //         this.logWithTimestamp("PeerConnection not initialized");
    //         return;
    //     }

    //     try {
    //         const stats = await this.peerConnection.getStats();
    //         stats.forEach(report => {
    //             if (report.type === 'candidate-pair' && report.state === 'succeeded') {
    //                 console.log('------------------------------------------')
    //                 console.log('Round trip time:', report.currentRoundTripTime);
    //             }
    //             // 新增：统计丢包率和抖动
    //             if (report.type === 'inbound-rtp' && report.kind === 'video') {
    //                 console.log(`Packets lost(丢包): ${report.packetsLost}`);
    //                 // jitter 是以秒为单位，转换为毫秒
    //                 const jitterInMs = report.jitter * 1000;
    //                 console.log(`Jitter(抖动): ${jitterInMs.toFixed(2)}ms`);

    //                 // 计算丢包率
    //                 if (report.packetsReceived > 0) {
    //                     const lossRate = (report.packetsLost / (report.packetsReceived + report.packetsLost)) * 100;
    //                     console.log(`Packet loss rate: ${lossRate.toFixed(2)}%`);
    //                 }
    //                 console.log('------------------------------------------')
    //             }
    //         });
    //     } catch (error) {
    //         this.logWithTimestamp('Error getting stats:', error);
    //     }
    // }

    // 添加清理计时器的方法
    private clearSubscribeTimeout(): void {
        if (this.subscribeTimeout !== null) {
            window.clearTimeout(this.subscribeTimeout);
            this.subscribeTimeout = null;
            this.logWithTimestamp("Subscribe timeout cleared");
        }
    }

    // Add status getter
    public getStatus(): number {
        return this.currentStatus;
    }

    // Add new private method for status updates
    private setStatus(newStatus: number): void {
        const statusMap: { [key: number]: string } = {
            100: "INIT",
            101: "WS_CONNECTED",
            200: "PEER_INIT_CONNECTED",
            201: "PEER_CONNECTED",
            300: "STREAM_INIT_CONNECTED",
            301: "STREAM_CONNECTED",
            400: "CLOSED",
            500: "ERROR"
        };
        
        this.currentStatus = newStatus;
        this.logWithTimestamp(`Status changed to: ${statusMap[newStatus]} (${newStatus})`);
    }

    private reinitialize(): void {
        try {
            const isWebSocketConnected = this.socket?.readyState === WebSocket.OPEN;
            this.logWithTimestamp(`Reinitializing... WebSocket status: ${isWebSocketConnected ? 'connected' : 'disconnected'}`);
            
            if (isWebSocketConnected) {
                // WebSocket is connected - only cleanup WebRTC
                this.cleanupWithoutWebSocket();
                this.setStatus(this.STATUS.WS_CONNECTED);
                // Request new stream list
                this.sendMessage({ type: "listStreamers" });
            } else {
                // WebSocket is disconnected - full cleanup and reconnect
                this.cleanup();
                this.setStatus(this.STATUS.INIT);
                
                if (this.currentStatus !== this.STATUS.CLOSED) {
                    this.logWithTimestamp('Reinitializing WebSocket connection...');
                    this.initializeWebSocket();
                } else {
                    this.logWithTimestamp('Connection is closed, skipping reinitialization');
                }
            }
        } catch (error: any) {
            this.logWithTimestamp(`Reinitialization failed: ${error?.message || 'Unknown error'}`);
            this.setStatus(this.STATUS.ERROR);
        }
    }

    private cleanupWithoutWebSocket(): void {
        // Clear WebRTC connection
        if (this.peerConnection) {
            this.peerConnection.close();
            this.peerConnection = null;
        }

        // Clear video element
        if (this.remoteVideo) {
            this.remoteVideo.srcObject = null;
        }

        // Clear streamList related
        this.clearStreamListCheck();
        this.availableStreamList = [];
        this.streamListCheckAttempts = 0;

        // Clear subscription related
        this.clearSubscribeTimeout();
        this.currentSubscribedId = null;
        this.isSubscribed = false;

        this.logWithTimestamp("WebRTCClient partial cleanup completed (keeping WebSocket)");
    }

    private cleanup(): void {
        // WebSocket cleanup
        if (this.socket) {
            this.socket.close();
            this.socket = null;
        }

        // Perform all other cleanup operations
        this.cleanupWithoutWebSocket();

        // Clear reconnection related
        if (this.wsReconnectTimeout) {
            clearTimeout(this.wsReconnectTimeout);
            this.wsReconnectTimeout = null;
        }

        this.logWithTimestamp("WebRTCClient full cleanup completed");
    }

    private reinitializePeerConnection(): void {
        this.logWithTimestamp('Reinitializing PeerConnection with new ICE transport policy...');
        try {
            // Close existing connection
            if (this.peerConnection) {
                this.peerConnection.close();
            }

            // Get current configuration and update ICE transport policy
            const currentConfig = this.peerConnection?.getConfiguration() || {
                iceServers: this.defaultStunServers
            };
            this.logWithTimestamp('currentConfig:', currentConfig);
            this.cleanupWithoutWebSocket();
            const newConfig: RTCConfiguration = {
                ...currentConfig,
                iceTransportPolicy: this.iceTransportPolicy,
                iceCandidatePoolSize: 20
            };

            // Create new PeerConnection with updated config
            this.peerConnection = new RTCPeerConnection(newConfig);
            this.initializePeerConnection();
            
            // Request new configuration from server
            this.sendMessage({ type: "listStreamers" });
            
            this.logWithTimestamp(`PeerConnection reinitialized with iceTransportPolicy: ${this.iceTransportPolicy}`);
        } catch (error) {
            this.logWithTimestamp('Error reinitializing PeerConnection:', error);
            this.loss(3);
        }
    }

    // Add new public method
    // public simulateIceDisconnection(): void {
    //     if (this.simulateDisconnectOnce && this.peerConnection && !this.isSimulatingDisconnect) {
    //         this.isSimulatingDisconnect = true;
    //         this.logWithTimestamp("Simulating ICE disconnection...");
    //         this.simulateDisconnectOnce = false;
            
    //         // Create and dispatch a fake ICE connection state change event
    //         const fakeEvent = new Event('iceconnectionstatechange');
    //         Object.defineProperty(this.peerConnection, 'iceConnectionState', {
    //             get: () => 'disconnected',
    //             configurable: true
    //         });
            
    //         // Trigger the event handler
    //         this.handleIceConnectionStateChange();
            
    //         // Reset the iceConnectionState property after a short delay
    //         setTimeout(() => {
    //             Object.defineProperty(this.peerConnection, 'iceConnectionState', {
    //                 get: () => this.peerConnection?.iceConnectionState || 'closed',
    //                 configurable: true
    //             });
    //             this.isSimulatingDisconnect = false;
    //         }, 100);
    //     }
    // }

}

export { WebRTCClient };

import LabsCard from "@/components/LabsCard";
import "./Labs.css";

interface LabsCardData {
    title: string;
    description: string;
    imageUrl: string;
    routePath: string;
}

const labsData: LabsCardData[] = [
    {
        title: "Text to map",
        description: "Generate map with text input",
        imageUrl: "/image/text-to-map.png",
        routePath: "/labs/text"
    },
    {
        title: "Tag to map",
        description: "Control the map with tags",
        imageUrl: "/image/tag-to-map.png",
        routePath: "/labs/tag"
    },
    {
        title: "Mask to layout",
        description: "Generate the village layout with drawing masks",
        imageUrl: "/image/mask-to-layout.png",
        routePath: "/labs/mask"
    },
    // {
    //     title: "Tag to layout",
    //     description: "Control the village layout with tags",
    //     imageUrl: "/image/tag-to-layout.png",
    //     routePath: "/labs/village"
    // }
];

function Labs() {
    return (
        <div className="flex flex-col">
            <div className="flex">
                <h3 className="font-thin p-0 text-6xl">LABS. CYBEVER</h3>
                <p className="text-base flex items-end ml-5 text-gray-400">Experiment with the future 3D creation</p>
            </div>
            <div className="flex flex-wrap gap-5 mt-10">
                {labsData.map((card, index) => (
                    <LabsCard
                        key={index}
                        title={card.title}
                        description={card.description}
                        imageUrl={card.imageUrl}
                        routePath={card.routePath}
                    />
                ))}
            </div>
        </div>
    );
}

export default Labs;
import Warning from "../Warning/Warning";
import { useState } from "react";

interface Props {
  children: React.ReactNode;
  clickHandler: () => void;
}

export default function BlockRouter(props: Props) {
  const { children, clickHandler } = props;
  const [showWarning, setShowWarning] = useState(false);
  const getTaskIdFromLocal = () => {
    const taskId = localStorage.getItem("taskId");
    if (taskId && taskId !== "undefined") {
      return taskId;
    }
    return null;
  };
  // 关闭警告弹窗
  const closeWarning = () => {
    setShowWarning(false);
  };

  // 打开警告弹窗
  const openWarning = () => {
    setShowWarning(true);
  };
  return (
    <>
      <div
        onClick={() => {
          const taskId = getTaskIdFromLocal();
          if (taskId) {
            openWarning();
          } else {
            clickHandler();
          }
        }}
      >
        {children}
      </div>
      <Warning
        isVisible={showWarning}
        text="The operation will replace your current drawing."
        onCancel={() => closeWarning()}
        onContinue={() => {
          localStorage.removeItem("taskId");
          localStorage.removeItem("mapgen_info");
          closeWarning();
          clickHandler();
        }}
      />
    </>
  );
}


export const MAP_CONFIG = {
  // 画布大小
  CANVAS_SIZE: 256,
  
  // 高程范围
  ELEVATION: {
    MIN: -1.0,  // 水面最低高度
    MAX: 1.0,   // 陆地最高高度
    WATER_THRESHOLD: 0.0  // 水陆分界线
  },
  
  // 山地生成参数
  MOUNTAIN: {
    ELEVATION_MULTIPLIER: 5.0,  // 山地高度乘数 
    STRENGTH_THRESHOLD: 0.5,   // 山地强度阈值
    MAX_ELEVATION: 10.0   // 山地最大高度
  }
} as const;

export const NOISE_CONFIG = {
  PERSISTENCE: 0.5, // 持久性是用于控制噪声图的平滑度和细节的参数。
  OCTAVES: 5, // 噪声图的频率数量
  MOUNTAIN_NOISE_OFFSET: {
    X: 30,   // 山地在X轴的整体偏移
    Y: 50   // 山地在Y轴的整体偏移
  },
  MOUNTAIN_NOISE_SCALE: {
    X: 33,    // 山地在X轴的缩放因子
    Y: 55  // 山地在Y轴的缩放因子
  }
} as const;

export const BRUSH_SIZES = {
    xtiny: { key: '1', rate: 1, innerRadius: 1, outerRadius: 2 },
    tiny: { key: '2', rate: 3, innerRadius: 1.5, outerRadius: 2.5 },
    small: { key: '3', rate: 4, innerRadius: 2, outerRadius: 6 },
    medium: { key: '4', rate: 4, innerRadius: 5, outerRadius: 10 },
    large: { key: '5', rate: 3, innerRadius: 10, outerRadius: 16 },
};

export const TERRAIN_TOOLS = {
    ocean: { elevation: -0.25 },
    shallow: { elevation: -0.05 },
    valley: { elevation: +0.05 },
    mountain: { elevation: +1.0 },
    vaillage: { elevation: +0.5 },
    deleteVaillage: { elevation: -0.5 },
    clearVillage: { elevation: -0.5 },
};

// @ts-nocheck
import {SHADER_SOURCE} from "../../config/shader-source.ts";
import REGL from 'regl/dist/regl.js';
import Colormap from './colormap.ts';

class Drawer {

    private regl: REGL.Regl;
    private colormap: Colormap;
    private terrain:number;
    constructor(regl: REGL.Regl) {
        this.regl = regl;
        this.colormap = new Colormap();
    }

    public setTerrain(terrain:number):void {
        this.colormap.setTerrain(terrain);
        this.terrain = terrain;
    }

    public getTerrain():number{
        return this.terrain;
    }

    public drawRivers( fbo_river: REGL.Framebuffer, river_texturemap: REGL.Texture, props:any) {
        return this.regl({
            ...SHADER_SOURCE.rivers,
            uniforms:  {
                u_projection: this.regl.prop("u_projection"),
                u_rivertexturemap: river_texturemap,
            },
            //定义渲染目标，可用作纹理，允许多次渲染。
            framebuffer: fbo_river,
            //启用混合模式，支持透明度控制
            blend: {
                enable: true,
                func: {src:'one', dst:'one minus src alpha'},
                equation: {
                    rgb: 'add',
                    alpha: 'add'
                },
            color: [1, 1, 1, 0]
            },
            //配置深度测试，确保物体的正确遮挡关系。
            depth: {
                enable: false,
            },
            count: this.regl.prop("count"),
            //指定每个顶点的属性，如位置和法线等。
            attributes: {
                a_xyuv: this.regl.prop("a_xyuv"),
            },
        })(props);
    }

    public drawLand( fbo_land: REGL.Framebuffer, props:any) {
        return this.regl({
            ...SHADER_SOURCE.land,
            uniforms:  {
                u_projection: this.regl.prop("u_projection"),
                u_water: this.regl.prop("u_water"),
                u_outline_water: this.regl.prop("u_outline_water"),
                u_m: this.regl.prop("u_m"),
                export_value: this.regl.prop("export_value"),
            },

            framebuffer: fbo_land,
            depth: {
                enable: false,
            },
            elements: this.regl.prop("elements"),
            //@ts-ignore
            attributes: {
                a_xy: this.regl.prop("a_xy"),
                a_em: this.regl.prop("a_em"),
            },
        })(props);
    }

    public drawDepth( fbo_depth: REGL.Framebuffer, props:any) {
        return this.regl({
            ...SHADER_SOURCE.depth,           
            framebuffer: fbo_depth,
            elements: this.regl.prop("elements"),
            attributes: {
                a_xy: this.regl.prop("a_xy"),
                a_em: this.regl.prop("a_em"),
            },
            uniforms: {
                u_projection: this.regl.prop("u_projection"),
            },})(props);      
    }

    public drawDrape(fbo_final: REGL.Framebuffer, u_land_texture: REGL.Texture, texture_size:number, props:any) {
        const colormapData = this.colormap.getColormap();
        
        return this.regl({...SHADER_SOURCE.drape,           

            framebuffer: fbo_final,
            elements: this.regl.prop("elements"),
            attributes: {
                a_xy: this.regl.prop("a_xy"),
                a_em: this.regl.prop("a_em"),
            },
            uniforms: {
                u_projection: this.regl.prop("u_projection"),
                u_depth: this.regl.prop("u_depth"),
                u_colormap: this.regl.texture({
                    width: colormapData.width, 
                    height: colormapData.height, 
                    data: colormapData.color, 
                    wrapS: 'clamp', 
                    wrapT: 'clamp'
                }),
                u_mapdata: () => u_land_texture,
                u_water: this.regl.prop("u_water"),
                u_inverse_texture_size: 1.5 / texture_size,
                u_light_angle: this.regl.prop("u_light_angle"),
                u_slope: this.regl.prop("u_slope"),
                u_flat: this.regl.prop("u_flat"),
                u_ambient: this.regl.prop("u_ambient") ,
                u_overhead: this.regl.prop("u_overhead"),
                u_outline_depth: this.regl.prop("u_outline_depth"),
                u_outline_coast: this.regl.prop("u_outline_coast"),
                u_outline_water: this.regl.prop("u_outline_water"),
                u_outline_strength: this.regl.prop("u_outline_strength"),
                u_outline_threshold: this.regl.prop("u_outline_threshold"),
                u_biome_colors: this.regl.prop("u_biome_colors"),
            },
        })(props);      
    }

    public drawFinal( textures: any, props:any) {
        return this.regl({
            ...SHADER_SOURCE.final,           
            uniforms:  {
                u_land_texture: textures.land,
                u_final_texture: textures.final,
                u_river_texture: textures.river,
                u_offset: this.regl.prop("u_offset"),
                export_value: this.regl.prop("export_value"),
            },
            depth: {
                enable: false,
            },
            count: 3,
            attributes: {
                a_uv: [-2, 0, 0, -2, 2, 2]
            },
        })(props);      
    }  


}

export default Drawer;
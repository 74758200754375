// @ts-nocheck
import param from "../config/index.ts";
import { WorkerMessage, WorkerMessageType, GeneratePayload, UpdatePayload, ErrorPayload } from './types';

class MapWorkerService {
    protected working:boolean = false;
    protected workRequested:boolean = false;
    protected worker:Worker | null = null;

    private render;
    private elapsedTimeHistory:number[] = [];
    private generate:()=>void;
    private messageHandler?: (event: MessageEvent) => void;
    // private errorHandler?: (event: ErrorEvent) => void;
    private messageHandlers: Map<WorkerMessageType, (data: any) => void>;
    private errorHandler: (error: ErrorPayload) => void;


    // @ts-ignore
    constructor(render ,generate:()=>void) {
        this.render = render;
        this.generate = generate;
        this.initializeWorker();

    }

    private initializeWorker(){
        if(this.worker) return;
        this.worker = new Worker(
            new URL("./worker.ts", import.meta.url), 
            { type: "module" }
        );

        this.messageHandlers = new Map();
        this.errorHandler = (error) => console.error('Worker Error:', error);
       this.setupEventListeners();

    }

    private setupEventListeners() {
        this.worker.onmessage = (event: MessageEvent<WorkerMessage>) => {
            const { type, payload } = event.data;
            
            if (type === WorkerMessageType.ERROR) {
                this.handleError(payload as ErrorPayload);
                return;
            }

            const handler = this.messageHandlers.get(type);
            handler?.(payload);
        };

        this.worker.onerror = (error: ErrorEvent) => {
            this.handleError({
                code: 'WORKER_ERROR',
                message: error.message
            });
        };
    }
    private handleError(error: ErrorPayload) {
        console.error('Worker Error:', error);
    }

    public on<T>(type: WorkerMessageType, handler: (data: T) => void) {
        this.messageHandlers.set(type, handler);
    }

    public createMap() {
        this.on(WorkerMessageType.GENERATE, this.handleGenerateResponse.bind(this));
    }

    private handleGenerateResponse(data: GeneratePayload) {
        this.working = false;
        const { elapsed, numRiverTriangles} = data;
        
        this.updateTimingHistory(elapsed);
        this.updateGeometryBuffers(data);
        this.updateRender(numRiverTriangles);

        if (this.workRequested) {
            requestAnimationFrame(() => {
                this.workRequested = false;
                this.generate();
            });
        }
    }

    private updateTimingHistory(elapsed: number): void {
        const elapsedMs = elapsed | 0; // 转换为整数
        this.elapsedTimeHistory.push(elapsedMs);
        
        // 保持历史记录最多10条
        if (this.elapsedTimeHistory.length > 10) {
            this.elapsedTimeHistory.splice(0, 1);
        }
        const timingDiv = document.getElementById('timing');
        if (timingDiv) {
            timingDiv.innerText = `${this.elapsedTimeHistory.join(' ')} milliseconds`;
        }
    }

    private updateGeometryBuffers(data: GeneratePayload): void {
        const {
            quad_elements_buffer,
            a_quad_em_buffer,
            a_river_xyuv_buffer
        } = data;

        // 更新几何缓冲区
        this.render.geometryBuffers = {
            ...this.render.geometryBuffers,
            quad_elements: new Int32Array(quad_elements_buffer),
            a_quad_em: new Float32Array(a_quad_em_buffer),
            a_river_xyuv: new Float32Array(a_river_xyuv_buffer)
        };
    }

    private updateRender(numRiverTriangles: number): void {
        // 更新河流三角形数量
        this.render.numRiverTriangles = numRiverTriangles;
        // 更新地图数据
        this.render.updateMap();
        // 更新视图
        this.render.updateView(param.render);
    }

    public update(params: UpdatePayload) {
        this.postMessage(WorkerMessageType.UPDATE, params);
    }

    public postMessage(type: WorkerMessageType, payload: any , transferBuffers: Transferable[] = []) {
        try {
            this.worker.postMessage({ type, payload }, transferBuffers);
        } catch (error) {
            this.errorHandler({
                code: 'POST_MESSAGE_ERROR',
                message: error instanceof Error ? error.message : 'Unknown error'
            });
        }
    }

    public getWorker(){
        return this.worker;
    }

    public isWorking(){
        return this.working;
    }

    public startWork(){
        this.working = true;
    }

    public endWork(){
        this.working = false;
    }

    public requestWork(){
        this.workRequested = true;
    }

    public resetWorkState(){
        this.working = false;
        this.workRequested = false;
    }

    public dispose(): void {
        try {
            if (this.messageHandler) {
                this.worker?.removeEventListener('message', this.messageHandler);
            }
            if (this.errorHandler) {
                this.worker?.removeEventListener('error', this.errorHandler);
            }
            // 终止 Worker
            this.worker?.terminate();
            this.resetWorkState();

            // 清空引用
            this.messageHandler = undefined;
            this.errorHandler = undefined;
            this.messageHandlers.clear();

        } catch (error) {
            console.error('Error disposing MapWorker:', error);
        }
    }
}

export default MapWorkerService;
import "./Warning.css";
import warningIcon from "@/assets/warning.png";
import closeIcon from "@/assets/warning-close.png"
import { WarningProps } from "@/types";

function Warning({ isVisible, onCancel, onContinue,text }: WarningProps) {
    if (!isVisible) return null;

    const close = () => {
        onCancel && onCancel();
    }

    const ContinueHandler = () => {
        close();
        onContinue && onContinue();
    }

    return (
        <div className="warning-bg">
            <div className="waring-container flex flex-col">
                <header className="flex items-center justify-between">
                    <span className=" text-lg">Warning</span>
                    <img src={closeIcon} alt="close" width={24} height={24} className="cursor-pointer" onClick={close}/>
                </header>
                <main className="flex items-center flex-1">
                    <img src={warningIcon} alt="warning" width={24} height={24}/>
                    <span className="!max-w-full ml-4">{text}</span>
                </main>
                <footer className="flex justify-end warning-footer">
                    <button onClick={close}>Cancel</button>
                    <button className=" ml-2 continue" onClick={ContinueHandler}>Continue</button>
                </footer>
            </div>
        </div>
    );
};
export default Warning;
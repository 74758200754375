import { GenerateState, ImageList, Task } from "@/types";
import request from "@/lib/http";
import { message, Modal } from "antd";

function disabledButton(): void {
    const previewMain = document.getElementById('preview-main');
    if (!previewMain) return;

    previewMain.querySelectorAll('button').forEach(button => {
        button.disabled = true;
        button.style.cursor = "not-allowed";
    });
};

function enabledButton(): void {
    const previewMain = document.getElementById('preview-main');
    if (!previewMain) return;

    previewMain.querySelectorAll('button').forEach(button => {
        button.disabled = false;
        button.style.cursor = "pointer";
    });
}

const TOKENKEY = "token_key";

function setLocalToken(token: string): void {
    localStorage.setItem(TOKENKEY, token);
}

function getLocalToken(): string | null {
    return localStorage.getItem(TOKENKEY);
}

function clearLocalToken(): void {
    localStorage.removeItem(TOKENKEY);
    localStorage.removeItem("photoURL");
}

function toSocketSend(action: string, speed: number, ws: WebSocket | null): void {
    var json = JSON.stringify({
        "action": action,
        "speed": speed
    });
    console.log("json:", json);
    if (ws?.readyState === WebSocket.OPEN) {
        ws?.send(json);
    }
}

function GenerateUEProgress(currentState: number, taskAhead: number, renderAhead: number): string {
    if (currentState === 220) {
        return `Waiting to be processed in the queue, ${taskAhead} task(s) ahead.`
    } else if (currentState === 230) {
        return "Generating model file, step 1 / 2";
    } else if (currentState === 260) {
        return `Waiting to be processed in the queue, ${renderAhead} task(s) ahead.`
    }
    else if (currentState === 300) {
        return "Generating a 3D environment, step 2 / 2";
    } else {
        return "Generating..."
    }
}

const getAssetServer = (): string => {
    return import.meta.env.VITE_APP_BASE_URL + import.meta.env.VITE_APP_ASSET_FILE;
}

const getVideoServer = (): string => {
    return import.meta.env.VITE_APP_VIDEO_URL;
}

const getSFUServer = (): string => {
    return import.meta.env.VITE_APP_SFU_URL;
}

const getWebSocketServer = (): string => {
    if (window.location.protocol === 'https:') {
        return import.meta.env.VITE_APP_WSS;
    } else {
        return import.meta.env.VITE_APP_WS;
    }
}

const getWebSocketURL = (): string => {
    var taskId = localStorage.getItem('task_id')
    var url: string;
    if (window.location.protocol === 'http:') {
        url = import.meta.env.VITE_APP_WS + taskId;
    } else {
        url = import.meta.env.VITE_APP_WSS + taskId;
    }
    return url;
}

// 定义一个函数来根据 id 获取图像的 src
const getImageSrcById = (id: number, initImages: Array<ImageList>): string => {
    const image = initImages.find(image => image.id === id);
    return image ? image.src : initImages[0].src;
};

const BlockNavigation = (disabled: boolean): void => {
    let alist = document.getElementsByTagName('a');
    for (let i = 0; i < alist.length; i++) {
        alist[i].onclick = function (event: any) {
            if (disabled) {
                event.preventDefault();
                event.stopPropagation();
                message.warning("Task in progress, please do not switch pages");
            }
        }
    }
}

function loadImage(url: string): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
        const image = new Image();
        image.crossOrigin = 'anonymous';
        image.src = url;
        image.onload = function () {
            resolve(image);
        };
        image.onerror = function (error) {
            reject(error);
        }
    })
}


function loadLocalImage(file: any): Promise<any> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = function (e) {
            const img = new Image();
            img.onload = function () {
                resolve(img);
            };
            img.src = e.target?.result as string;
        };
        reader.onerror = function (error) {
            reject(error);
        }
        reader.readAsDataURL(file);
    })

}

async function loadImageFromBase64(base64: string): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = base64;

        img.onload = () => {
            resolve(img);  // Return img element when the image loads successfully
        };

        img.onerror = () => {
            reject(new Error('Image failed to load'));  // Return an error if the image fails to load
        };
    });
}

function loadReader(data: any): Promise<string> {
    return new Promise((resolve, reject) => {
        const blob = new Blob([data], { type: 'image/png' });
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = function (event) {
            let url = event.target?.result as string;
            resolve(url);
        };
        reader.onerror = function (error) {
            reject(error);
        };
    })
}

function filterData(arr: Array<Task>): Array<Task> {
    return arr.filter(item => !(item.status >= 240 && item.status <= 250) && item.status >= 210);
}

function reverseSort(data: Array<Task>): Array<Task> {
    data.sort((a, b) => {
        return parseInt(b.task_id) - parseInt(a.task_id);
    });
    return data;
}

function formatDateString(input: string): string {
    if (input.length < 8) {
        throw new Error("Input string is too short to format.");
    }

    const year = input.substring(0, 4);
    const month = input.substring(4, 6);
    const day = input.substring(6, 8);

    return `${year}-${month}-${day}`;
}

async function downloadCheck(task_id: string): Promise<any> {
    if (!task_id) {
        message.warning("No files to download.");
        return
    }
    let checkURL = import.meta.env.VITE_APP_CHECK_UELEVEL + task_id;
    let response;
    try {
        response = await request.get(checkURL);
    } catch (error) {
        message.warning("Server internal error");
    }

    if (!response?.data.result) {   // result=0 not ready, result=1 ready.
        message.warning("The file is not ready yet.");
        return false
    } else {
        return true;
    }
}

function updateState(state: GenerateState, taskTempStatus: any, type: string, status: number, incrementTime = 2): void {
    const isModel = type === "model";
    const isUE = type === "ue";

    if (state.currentState !== status) {
        state.currentState = status;
        taskTempStatus.current = status;

        if (isModel) {
            if (status !== 210) {
                state.time = 0;
                state.total_time = 0;
                state.taskAhead = 0;
            }
            state.text = "Generating .fbx";
        } else if (isUE) {
            if (status !== 360) {
                state.time = 0;
                state.taskAhead = 0;
                state.renderAhead = 0;
            }
            state.text = "Generate high-quality scenes";
        }
    } else {
        state.time += incrementTime;
        state.total_time += incrementTime;
    }

    if (state.value <= 96) {
        const incrementValue = isModel ? (100 / 120) * incrementTime : isUE ? (100 / 600) * incrementTime : 0;
        state.value += incrementValue;
    }
}

async function checkUserLogin(token: string): Promise<boolean> {
    let url = import.meta.env.VITE_APP_BASE_URL + import.meta.env.VITE_APP_CHECK_LOGIN;
    try {
        var response = await fetch(url, { method: "GET", headers: { "x-id-token": token } });
        if (response.status === 403) {
            return false
        }
        return true
    } catch (error) {
        message.error("Network error")
        return false
    }
}

function formatTimeUnit(unit: number) {
    return unit < 10 ? `0${unit}` : `${unit}`;
}

function convertToTime(value: number) {
    // Convert the value from hours to hours and minutes
    const hours = Math.floor(value);
    const minutes = Math.round((value - hours) * 60);

    // Format hours and minutes to two digits
    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');

    return `${formattedHours} : ${formattedMinutes} : 00`;
}

function taskSwitchConfirm() {
    return new Promise((resolve, _) => {
        Modal.confirm({
            title: "Tips",
            content: "Are you sure you want to switch to a new map? This will lose current drawings.",
            onOk: () => {
                resolve(true);
            },
            onCancel: () => {
                resolve(false);
            }
        });
    })
}

function getLocalEmail() {
    return localStorage.getItem("email");
}

async function checkUserWaitlist() {
    let data;
    try {
        let response = await fetch(import.meta.env.VITE_APP_BASE_URL + import.meta.env.VITE_APP_WAITUSER_CHECK, {
            headers: {
                'x-id-token': getLocalToken() as string
            }
        });
        data = await response.json();
        // 1 已经成功加入， 0 已经提交过了        
    } catch (e) {
        console.log(e);
    }
    return data;
}

async function checkUserWhitelist(token: string) {
    let response;
    try {
        response = await fetch(import.meta.env.VITE_APP_BASE_URL + import.meta.env.VITE_APP_TASK_LIST, {
            headers: {
                'x-id-token': token
            }
        });
        if (!response.ok) { // 检查响应是否是成功的
            if (response.status === 403) {
                return response.status
            }
            return response.status
        }
    } catch (e) {
        console.log(e);
    }
    return 200;
}

async function checkWaitlist(token: string) {
    let data;
    try {
        let response = await fetch(import.meta.env.VITE_APP_BASE_URL + import.meta.env.VITE_APP_WAITUSER_CHECK, {
            headers: {
                'x-id-token': token
            }
        });
        data = await response.json();
        // 1 已经成功加入， 0 已经提交过了        
    } catch (e) {
        console.log(e);
    }
    return data;
}

async function saveWaitList(data: any, token: string) {
    let result;
    try {
        let response = await fetch(import.meta.env.VITE_APP_BASE_URL + import.meta.env.VITE_APP_JOIN_NOW, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'x-id-token': token
            },
            body: JSON.stringify(data)
        });
        result = await response.json();
        // 1 已经成功加入， 0 已经提交过了        
    } catch (e) {
        console.log(e);
    }
    return result;
}


async function saveDefaultWaitList(token: string) {
    let result;
    try {
        let response = await fetch(import.meta.env.VITE_APP_BASE_URL + import.meta.env.VITE_APP_JOIN_NOW, {
            headers: {
                'Content-Type': 'application/json',
                'x-id-token': token
            }
        });
        result = await response.json();
        // 1 已经成功加入， 0 已经提交过了        
    } catch (e) {
        console.log(e);
    }
    return result;
}

function checkColorAllowed(file: File): Promise<boolean> {
    return new Promise((resolve, reject) => {
        const allowedColors = [
            { r: 249, g: 236, b: 189, a: 255 },
            { r: 20, g: 163, b: 251, a: 255 },
            { r: 92, g: 219, b: 52, a: 255 },
            { r: 155, g: 157, b: 155, a: 255 }
        ];

        const tolerance = 80; // 颜色容差
        const canvas = document.createElement('canvas');
        canvas.width = 512;
        canvas.height = 512;
        const context = canvas.getContext('2d');

        if (!context) {
            reject(new Error("无法创建 canvas 上下文"));
            return;
        }

        const img = new Image();
        img.onload = () => {
            context.drawImage(img, 0, 0, 512, 512);
            const imageData = context.getImageData(0, 0, 512, 512);
            const data = imageData.data;
            let validColorCount = 0;

            for (let i = 0; i < data.length; i += 4) {
                const r = data[i];
                const g = data[i + 1];
                const b = data[i + 2];
                const a = data[i + 3];
                // 检查颜色和透明度是否在容差范围内
                if (allowedColors.some(color =>
                    Math.abs(color.r - r) <= tolerance &&
                    Math.abs(color.g - g) <= tolerance &&
                    Math.abs(color.b - b) <= tolerance &&
                    color.a === a // 确保透明度完全匹配
                )) {
                    validColorCount++;
                }
            }

            const totalPixels = 512 * 512;
            resolve(totalPixels - validColorCount <= 4000);
        };

        img.onerror = () => {
            reject(new Error("图片加载失败"));
        };

        // 使用 FileReader 来读取 File 对象
        const reader = new FileReader();
        reader.onload = (e) => {
            img.src = e.target?.result as string;
        };
        reader.onerror = () => {
            reject(new Error("文件读取失败"));
        };
        reader.readAsDataURL(file);
    });
}

function sanitizeInput(input: string) {
    const tempElement = document.createElement('div');
    tempElement.innerText = input;
    return tempElement.innerHTML;
}

type CornerCoordinates = {
    top_left: [number, number];
    top_right: [number, number];
    bottom_left: [number, number];
    bottom_right: [number, number];
};

// 正方形缩放器
class SquareScaler {
    large_size: number;
    small_size: number;
    center_x: number;
    center_y: number;
    scale_ratio: number;
    half_small_length: number;
    corners: CornerCoordinates;

    constructor(large_size: number = 2048, small_size: number = 512) {
        /**
         * 初始化正方形缩放器
         * 
         * @param large_size - 大正方形的边长
         * @param small_size - 小正方形的边长
         * @throws Error - 当尺寸小于或等于0，或小正方形边长大于大正方形边长时抛出错误
         */
        if (large_size <= 0 || small_size <= 0) {
            throw new Error("正方形边长必须大于0");
        }
        if (small_size > large_size) {
            throw new Error("小正方形边长不能大于大正方形边长");
        }

        this.large_size = large_size;
        this.small_size = small_size;
        this.center_x = large_size / 2;
        this.center_y = large_size / 2;
        this.scale_ratio = small_size / large_size;
        this.half_small_length = small_size / 2;
        this.corners = this.calculateSmallSquareCorners();
    }

    private calculateSmallSquareCorners(): CornerCoordinates {
        /** 计算小正方形的四个顶点坐标 */
        return {
            top_left: [this.center_x - this.half_small_length, this.center_y + this.half_small_length],
            top_right: [this.center_x + this.half_small_length, this.center_y + this.half_small_length],
            bottom_left: [this.center_x - this.half_small_length, this.center_y - this.half_small_length],
            bottom_right: [this.center_x + this.half_small_length, this.center_y - this.half_small_length]
        };
    }
}


async function cropImage(
    imageBlob: Blob,
    cropSize: number
): Promise<Blob> {
    // 1. 动态选择目标裁剪尺寸
    const targetSize = cropSize <= 500 ? 256 : 385;

    // 2. 将 Blob 转换为 Image 对象
    const image = await loadBlobImage(imageBlob);

    // 3. 创建离屏 Canvas 来绘制裁剪图像
    const canvas = document.createElement("canvas");
    canvas.width = targetSize;
    canvas.height = targetSize;
    const ctx = canvas.getContext("2d");
    if (!ctx) throw new Error("无法获取 Canvas 的绘图上下文");

    // 4. 计算在原图中进行中心裁剪的位置
    const sourceCenterX = image.width / 2;
    const sourceCenterY = image.height / 2;
    const halfTargetSize = targetSize / 2;

    const startX = sourceCenterX - halfTargetSize;
    const startY = sourceCenterY - halfTargetSize;

    // 5. 在新 Canvas 上裁剪并绘制图片
    ctx.drawImage(
        image,
        startX,                     // 裁剪起始X坐标
        startY,                     // 裁剪起始Y坐标
        targetSize,                 // 裁剪宽度
        targetSize,                 // 裁剪高度
        0,                          // 绘制的X坐标
        0,                          // 绘制的Y坐标
        targetSize,                 // 绘制的宽度
        targetSize                  // 绘制的高度
    );

    // 6. 将 Canvas 内容转换为 Blob
    return new Promise<Blob>((resolve) => {
        canvas.toBlob((blob) => {
            if (blob) resolve(blob);
        }, "image/png");
    });
}

// 辅助函数：将 Blob 加载为 Image 对象
function loadBlobImage(blob: Blob): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
        const url = URL.createObjectURL(blob);
        const img = new Image();
        img.onload = () => {
            URL.revokeObjectURL(url);
            resolve(img);
        };
        img.onerror = (error) => {
            URL.revokeObjectURL(url);
            reject(error);
        };
        img.src = url;
    });
}

const convertToString = (data: {
    geography: string;
    mountain: string;
    isBarren: boolean;
    environments: string[];
}): string => {
    const parts = [
        data.geography.toLowerCase(),
        data.mountain.toLowerCase(),
        data.isBarren ? 'barren' : data.environments.map(env => env.toLowerCase()).join(',')
    ].filter(Boolean); // 过滤掉空值

    return parts.join(',');
};

const blobToBase64 = (blob: Blob): Promise<string> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            const base64String = reader.result as string;
            const base64Data = base64String.split(',')[1];
            resolve(base64Data);
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });
};


const resizeImageTo512 = async (blob: Blob): Promise<Blob> => {
    const canvas = document.createElement('canvas');
    canvas.width = 512;
    canvas.height = 512;
    const ctx = canvas.getContext('2d');

    if (!ctx) {
        throw new Error('Failed to get canvas context');
    }

    const img = new Image();
    const imageUrl = URL.createObjectURL(blob);

    return new Promise((resolve, reject) => {
        img.onload = () => {
            ctx.drawImage(img, 0, 0, 512, 512);

            canvas.toBlob((resultBlob) => {
                if (resultBlob) {
                    resolve(resultBlob);
                } else {
                    reject(new Error('Failed to convert to Blob'));
                }
            }, 'image/png');

            URL.revokeObjectURL(imageUrl);
        };

        img.onerror = () => {
            URL.revokeObjectURL(imageUrl);
            reject(new Error('Failed to load image'));
        };

        img.src = imageUrl;
    });
};

export {
    disabledButton,
    enabledButton,
    setLocalToken,
    getLocalToken,
    clearLocalToken,
    toSocketSend,
    GenerateUEProgress,
    getImageSrcById,
    getWebSocketURL,
    BlockNavigation,
    reverseSort,
    loadImage,
    loadReader,
    filterData,
    getWebSocketServer,
    getVideoServer,
    getSFUServer,
    getAssetServer,
    formatDateString,
    downloadCheck,
    updateState,
    checkUserLogin,
    formatTimeUnit,
    convertToTime,
    taskSwitchConfirm,
    getLocalEmail,
    checkUserWaitlist,
    checkUserWhitelist,
    checkWaitlist,
    saveWaitList,
    saveDefaultWaitList,
    loadLocalImage,
    checkColorAllowed,
    loadImageFromBase64,
    sanitizeInput,
    SquareScaler,
    cropImage,
    loadBlobImage,
    convertToString,
    blobToBase64,
    resizeImageTo512
}
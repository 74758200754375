import { getLocalToken } from "@/lib/utils";
import fullScreenIcon from "@/assets/fullscreen.svg";
import fullScreenExitIcon from "@/assets/fullscreen_exit.svg";
import downloadIcon from "@/assets/download.svg";
import { message } from "antd";

interface ModelPreviewHeaderProps {
  isModelFullscreen: boolean;
  genPersceneLoading: boolean;
  modelUrl: string;
  taskid: string;
  MODEL: string;
  enterModelFullscreen: () => void;
  exitModelFullscreen: () => void;
  generate: (taskId: string) => void;
}

const ModelPreviewHeader = ({
  isModelFullscreen,
  genPersceneLoading,
  modelUrl,
  taskid,
  MODEL,
  enterModelFullscreen,
  exitModelFullscreen,
  generate
}: ModelPreviewHeaderProps) => {
  return (
    <div className="flex justify-between mt-2">
      <p className="flex items-center text-white py-2 text-base font-light">
        <span>3D Preview</span>
      </p>
      <div className="flex items-center">
        <button 
          onClick={() => isModelFullscreen ? exitModelFullscreen() : enterModelFullscreen()} 
          className="outline-none"
        >
          <img 
            src={isModelFullscreen ? fullScreenExitIcon : fullScreenIcon} 
            alt="" 
            className="h-5" 
          />
        </button>
        <button
          className="mx-4"
          onClick={() => {
            if (!modelUrl) {
              message.warning("No files to download.");
              return;
            }
            const url = MODEL + taskid + `?token=${getLocalToken()}`;
            window.open(url, '_blank');
          }}
        >
          <img src={downloadIcon} alt="" />
        </button>
        <button
          className="gradient-border rounded-md text-base"
          style={{
            background: !genPersceneLoading 
              ? "linear-gradient(to right, #5FB3CE, #132976)" 
              : "linear-gradient(to right, rgba(95, 179, 206, 0.5), rgba(19, 41, 118, 0.5))"
          }}
          onClick={() => generate(taskid)}
        >
          {!genPersceneLoading ? "Generate 3D scene" : "Generating"}
        </button>
      </div>
    </div>
  );
};

export default ModelPreviewHeader;
import checkmark from "@/assets/checkmark.png";

interface StepThreeProps {
  isLoading: boolean;
  threeDMap: string;
  handleStepTwo: () => void;
  handleStepThree: () => void;
  buttonClassName: string;
  isRestart: React.MutableRefObject<boolean>;
}

function StepThree({ 
  isLoading, 
  threeDMap, 
  handleStepTwo, 
  handleStepThree, 
  buttonClassName, 
  isRestart 
}: StepThreeProps) {
    return (
        <>
            <img src={`data:image/png;base64,${threeDMap}`} alt="3dmap" width={600} height={600} />
            <div className="flex justify-between mt-4">
                <button
                    onClick={() => {
                        isRestart.current = true;
                        handleStepTwo();
                    }}
                    disabled={isLoading}
                    className={buttonClassName}
                >
                    {isLoading && !isRestart.current ? 'Generating preview...estimated time 2 min' : 'Restart'}
                </button>
                <button
                    onClick={handleStepThree}
                    disabled={isLoading}
                    className={`flex items-center ${buttonClassName}`}
                >
                    {!isLoading && <img src={checkmark} alt="checkmark" width={14} height={14} />}
                    <span className="ml-2">{isLoading && isRestart.current ? 'Generating...' : 'Next step'}</span>
                </button>
            </div>
        </>
    );
}

export default StepThree;
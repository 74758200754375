import { Tour, TourProps } from "antd";

import tutorial01 from "@/assets/mapgenTour/tutorial-01.mov";
import tutorial02 from "@/assets/mapgenTour/tutorial-02.mov";
import tutorial03 from "@/assets/mapgenTour/tutorial-03.mov";
import tutorial04 from "@/assets/mapgenTour/tutorial-04.mov";
import tutorial05 from "@/assets/mapgenTour/tutorial-05.mov";
import { useMount } from "ahooks";

interface IMapgenTourProps {
  openTour: boolean;
  setOpenTour: React.Dispatch<React.SetStateAction<boolean>>;
  currentTour: number;
  setCurrentTour: React.Dispatch<React.SetStateAction<number>>;
  step1Ref: React.RefObject<HTMLDivElement>;
  step2Ref: React.RefObject<HTMLDivElement>;
  step3Ref: React.RefObject<HTMLDivElement>;
  step4Ref: React.RefObject<HTMLDivElement>;
  step5Ref: React.RefObject<HTMLButtonElement>;
}

export default function MapgenTour(props: IMapgenTourProps) {
  const {
    openTour,
    setOpenTour,
    currentTour,
    step1Ref,
    step2Ref,
    step3Ref,
    step4Ref,
    step5Ref,
    setCurrentTour,
  } = props;

  useMount(() => {
    if (!localStorage.getItem('tour')) {
      setOpenTour(true);
      localStorage.setItem('tour', 'true');
    }
  })

  const nextButtonClickHandler = {
    onClick: () => {
      setCurrentTour(pre => ++pre)
    }
  }

  const prevButtonClickHandler = {
    onClick: () => {
      setCurrentTour(pre => --pre)
    },
    children: 'Back'
  }

  const steps: TourProps['steps'] = [
    {
      title: <>
        Templates and random
        <div className="subTitle">1/5</div>
      </>,
      description: 'Use templates to have a quick start. Hit the random button to generate more templates as examples',
      cover: <video src={tutorial01} autoPlay controls loop muted />,
      placement: 'right',
      target: () => step1Ref.current!,
      nextButtonProps: {
        ...nextButtonClickHandler,
      },
      prevButtonProps: {
        ...prevButtonClickHandler,
      }
    },
    {
      title: <>
        Map size & Advanced options
        <div className="subTitle">2/5</div>
      </>,
      description: 'Here, you can select the final size of the scene you generate. Advanced options allow you to quickly adjust the entire map, such as mountain height, river width, island density, and more.',
      cover: <video src={tutorial02} autoPlay controls loop muted />,
      placement: 'right',
      target: () => step2Ref.current!,
      nextButtonProps: {
        ...nextButtonClickHandler
      },
      prevButtonProps: {
        ...prevButtonClickHandler
      }
    },
    {
      title: <>
        Map editing
        <div className="subTitle">3/5</div>
      </>,
      description: 'You can draw mountains and rivers on the map and adjust the size of the drawing brush. The content on this canvas will be incorporated into the subsequent preview and scene generation.',
      cover: <video src={tutorial03} autoPlay controls loop muted />,
      placement: 'left',
      target: () => step3Ref.current!,
      nextButtonProps: {
        ...nextButtonClickHandler
      },
      prevButtonProps: {
        ...prevButtonClickHandler
      }
    },
    {
      title: <>
        Style of biomes
        <div className="subTitle">4/5</div>
      </>,
      description: 'Select a Biome, which will influence the environment generated later, including vegetation assets and textures.',
      cover: <video src={tutorial04} autoPlay controls loop muted />,
      placement: 'right',
      target: () => step4Ref.current!,
      nextButtonProps: {
        ...nextButtonClickHandler
      },
      prevButtonProps: {
        ...prevButtonClickHandler
      }
    },
    {
      title: <>
        Start generating your preview
        <div className="subTitle">5/5</div>
      </>,
      description: 'Everything is ready! Click the "Generate" button and wait for your scene to be created.',
      cover: <video src={tutorial05} autoPlay controls loop muted />,
      placement: 'right',
      target: () => step5Ref.current!,
      nextButtonProps: {
        ...nextButtonClickHandler
      },
      prevButtonProps: {
        ...prevButtonClickHandler
      }
    },
  ]
  return (
    <Tour
      rootClassName="mapgenTour"
      open={openTour}
      steps={steps}
      current={currentTour}
      onClose={() => setOpenTour(false)}
      indicatorsRender={() => (
        <button className="ant-tour-skip" onClick={() => setOpenTour(false)}>Skip</button>
      )}
    />
  )
}
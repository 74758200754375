
function GenerateBtn({ text, onClick, isLoading }: { text: string, onClick: () => void, isLoading: boolean }) {
    return (
        <div className="generate-style flex items-center rounded-md p-5 justify-end">
            <button className="button-3d-preview rounded-md text-base" onClick={onClick}>
                {isLoading ? "Generating" : text}
            </button>
        </div>
    )
}

export default GenerateBtn;
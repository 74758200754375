import { useState, useEffect } from 'react';

interface ImageCarouselProps {
    images: { url: string }[];
}

const ImageCarousel = ({ images }: ImageCarouselProps) => {
    const [currentIndex, setCurrentIndex] = useState(1);
    const [isTransitioning, setIsTransitioning] = useState(false);
    
    useEffect(() => {
        setCurrentIndex(1);
        setIsTransitioning(false);
    }, [images.length]);

    const handleTransitionEnd = () => {
        if (currentIndex === 0) {
            setIsTransitioning(false);
            setCurrentIndex(images.length);
        } else if (currentIndex === images.length + 1) {
            setIsTransitioning(false);
            setCurrentIndex(1);
        } else {
            setIsTransitioning(false);
        }
    };

    const handlePrevious = () => {
        if (isTransitioning) return;
        setIsTransitioning(true);
        setCurrentIndex(prev => prev - 1);
    };

    const handleNext = () => {
        if (isTransitioning) return;
        setIsTransitioning(true);
        setCurrentIndex(prev => prev + 1);
    };

    const goToSlide = (index: number) => {
        if (isTransitioning || index < 0 || index >= images.length) return;
        setIsTransitioning(true);
        setCurrentIndex(index + 1);
    };

    if (!images || images.length === 0) {
        return <div className="text-white opacity-20">No image</div>;
    }

    const extendedImages = [
        images[images.length - 1],
        ...images,
        images[0]
    ];

    return (
        <div className="relative w-full h-full overflow-hidden">
            <div 
                className="flex w-full h-full"
                style={{
                    transform: `translateX(-${currentIndex * 100}%)`,
                    transition: isTransitioning ? 'transform 300ms ease-in-out' : 'none'
                }}
                onTransitionEnd={handleTransitionEnd}
            >
                {extendedImages.map((image, index) => (
                    <img
                        key={index}
                        src={image.url}
                        alt={`slide-${index}`}
                        className="w-full h-auto object-contain flex-shrink-0"
                    />
                ))}
            </div>

            {images.length > 1 && (
                <>
                    <button
                        onClick={handlePrevious}
                        className="absolute left-4 top-1/2 -translate-y-1/2 w-10 h-10 rounded-full bg-black/50 hover:bg-black/70 flex items-center justify-center transition-colors"
                        aria-label="Previous image"
                    >
                        <svg 
                            className="w-6 h-6 text-white" 
                            fill="none" 
                            stroke="currentColor" 
                            viewBox="0 0 24 24"
                        >
                            <path 
                                strokeLinecap="round" 
                                strokeLinejoin="round" 
                                strokeWidth={2} 
                                d="M15 19l-7-7 7-7" 
                            />
                        </svg>
                    </button>

                    <button
                        onClick={handleNext}
                        className="absolute right-4 top-1/2 -translate-y-1/2 w-10 h-10 rounded-full bg-black/50 hover:bg-black/70 flex items-center justify-center transition-colors"
                        aria-label="Next image"
                    >
                        <svg 
                            className="w-6 h-6 text-white" 
                            fill="none" 
                            stroke="currentColor" 
                            viewBox="0 0 24 24"
                        >
                            <path 
                                strokeLinecap="round" 
                                strokeLinejoin="round" 
                                strokeWidth={2} 
                                d="M9 5l7 7-7 7" 
                            />
                        </svg>
                    </button>

                    <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex gap-2">
                        {images.map((_, index) => (
                            <button
                                key={index}
                                className={`w-2 h-2 rounded-full transition-colors ${
                                    index === (currentIndex - 1 + images.length) % images.length 
                                        ? 'bg-white' 
                                        : 'bg-gray-500'
                                }`}
                                onClick={() => goToSlide(index)}
                            />
                        ))}
                    </div>
                </>
            )}
        </div>
    );
};

export default ImageCarousel;
import { Modal, Button } from "antd";
import "./index.css";

interface MapResetModalProps {
  visible: boolean;
  priviewEl: string;
  onCancel: () => void;
  onGenerate: () => void;
  onLoading: boolean;
}

const MapResetModal = ({
  visible,
  priviewEl,
  onCancel,
  onGenerate,
  onLoading,
}: MapResetModalProps) => {
  return (
    <Modal
      className="customModal"
      title="Reset your map before generation"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>,
        <Button
          key="generate"
          type="primary"
          className={onLoading ? "opacity-50 cursor-not-allowed" : ""}
          onClick={onGenerate}
        >
          {onLoading ? "Generateing..." : "Yes, generate"}
        </Button>,
      ]}
    >
      <div>
        {priviewEl && (
          <img src={priviewEl} alt="Canvas Preview" style={{ width: "100%" }} />
        )}
        <p>
          We have found that the current map has moved out of the canvas due to
          rotation and scaling.
        </p>
        <p>
          To achieve better results, we will restore its rotation and
          translation before generating the 3D preview.
        </p>
        <p style={{ color: "red" }}>Please review the restored result.</p>
      </div>
    </Modal>
  );
};

export default MapResetModal;

import { UIKeyBind, SingleKey, TurboKey, KEY_BUTTONS, MOVE_ACTIONS, VIEW_ACTIONS } from "./keyctrl";
import { WebRTCClient } from "./pixelclient";

class UEPlayer {

    constructor({ ctrlUrl, videoUrl, streamID, video, map, mapUrl, move, weather, onActive, onLoss, onConnected }) {
        this.ctrlUrl = ctrlUrl;
        this.videoUrl = videoUrl;
        this.streamID = streamID;
        this.video = video;
        this.map = map;
        this.mapUrl = mapUrl;
        this.move = move;
        this.weather = weather;
        this.onActive = onActive;
        this.onLoss = onLoss;
        this.onConnected = onConnected;
        this.ws = null;
        this.singleKey = null;
        this.turboKey = null;
        this.streamTime = 0;
        this.streamTimer = null;
        this.heartbeatTimer = null;
        this.location = [0, 0];
        this.initCtrl();
        this.initVideo();
        this.initWeather();
        this.initMiniMap();
        this.initHeartbeat();
        this.open();
    }

    // Init move/view controller
    initCtrl() {
        // Move Controller
        this.singleKey = new SingleKey(Object.keys(MOVE_ACTIONS));
        this.singleKey.onKeydown(key => {
            this.sendAction(MOVE_ACTIONS[key], 5);
        });
        this.singleKey.onKeyup(key => {
            this.sendAction(MOVE_ACTIONS[key] + "_done", 5);
        });

        // View Controller
        this.turboKey = new TurboKey(Object.keys(VIEW_ACTIONS));
        this.turboKey.onKey(key => {
            this.sendAction(VIEW_ACTIONS[key], 5);
        });

        // UI key bind
        this.keyBind = new UIKeyBind();
        for (let btn of this.move.getElementsByTagName("button")) {
            let keyBtn = KEY_BUTTONS[btn.textContent];
            if (keyBtn) {
                let [key, code] = keyBtn;
                this.keyBind.addBind(btn, key, code);
            }
        }
        this.ws = new WebSocket(this.ctrlUrl);
        this.ws.binaryType = "arraybuffer";
    }

    initVideo() {
        this.videoClient = new WebRTCClient(
            {
                serverUrl: this.videoUrl,
                videoElement: this.video,
                streamerId: this.streamID,
                onLoss: (status) => {
                    console.log("Video Stream LOST");
                    this.onLoss(status);
                },
                onSend: (message) => { this.send(message) },
                onConnected: () => {
                    this.onConnected && this.onConnected();
                }
            }
        );
    }

    // Init weather controller
    initWeather() {
        let container = this.weather;
        let detailsElement = container.querySelector('details');
        let time = container.querySelector(".time input[type='range']");
        let weather = container.querySelectorAll(".weather input[type='radio']");
        let weatherLevel = container.querySelector(".weather input[type='range']");
        let wind = container.querySelector(".wind input[type='checkbox']");
        let windLevel = container.querySelector(".wind input[type='range']");
        let confirm = container.querySelector("button:last-of-type");
        let handler = () => {
            let data = {
                "task_id": "beijing_test",
                "command": "control_weather",
                "time": 1.0,
                "wind_force": 0,
                "precipitations_type": "",
                "precipitations_force": 0
            }
            data.time = parseFloat(time.value);
            for (let w of weather) {
                if (w.checked) {
                    data.precipitations_type = w.value;
                    data.precipitations_force = parseInt(weatherLevel.value);
                    break;
                }
            }
            if (wind.checked) data.wind_force = parseInt(windLevel.value);
            this.send(data);
            this.active();
            detailsElement.open = false;
        }
        confirm.addEventListener("click", handler);
        this.weatherDestroy = () => {
            confirm.removeEventListener("click", handler);
        }
    }

    // Init mini map
    initMiniMap() {
        this.map.innerHTML = "";
        let img = document.createElement("img");
        img.src = this.mapUrl;
        this.map.appendChild(img);
        this.b = document.createElement("b");
        this.map.appendChild(this.b);
        this.i = document.createElement("i");
        this.i.innerText = "V";
        this.b.appendChild(this.i);
    }

    // Send heartbeat to server
    initHeartbeat() {
        this.heartbeatTimer = setInterval(() => {
            this.send({ "command": "ping" });
            // console.log(`heartbeat: { "command": "ping" },${new Date().toISOString()}`);
        }, 1000 * 10);
    }

    // Check alive from stream time
    initStreamAlive() {
        if (this.streamTimer) return;
        this.streamTime = Date.now();
        this.streamTimer = setInterval(async () => {
            let lossTime = Date.now() - this.streamTime;
            if (lossTime > 1000 * 20) this.loss(0);
        }, 1000);
    }

    // Refresh location
    refreshLocation(data) {
        let [x0, y0] = this.location;
        let [x, y] = data;
        if (x == x0 && y == y0) return;
        let r = Math.atan2(y - y0, x - x0);
        let d = r * (180 / Math.PI) - 90;
        this.location = [x, y];
        let left = (x + 1) * 100 / 2 + "%";
        let top = (y + 1) * 100 / 2 + "%";
        this.b.style.left = left;
        this.b.style.top = top;
        this.i.style.transform = `rotate(${d.toFixed()}deg)`;
    }

    downLoadImg(base64, name) {
        const a = document.createElement('a');
        a.href = `data:image/png;base64,${base64}`;
        a.download = name;
        a.click();
    }

    // Trigger active
    active() {
        this.onActive && this.onActive.call(null);
    }

    // Loss stream
    loss(status) {
        // console.log(`loss: ${new Date().toISOString()}`);

        this.destroy();
        this.onLoss && this.onLoss.call(null, status);
    }

    // Send move/view control
    sendAction(action, speed) {
        this.send({ "command": "move", action, speed });
        // console.log(`sendAction: ${{ "command": "move", action, speed }}, ${new Date().toISOString()}`);
        this.active();
    }

    // Send screenshot control
    sendScreenshot() {
        this.send({ command: 'screenshot' })
    }

    // Send websocket data
    send(data) {
        // console.log(`ws.send: ${data}, ${new Date().toISOString()}`);
        try {
            if (!this.ws) return;
            if (this.ws.readyState != WebSocket.OPEN) return;
            this.ws.send(JSON.stringify(data));
        } catch (e) { }
    }

    formatDate() {
        const date = new Date();
        const pad = (num) => String(num).padStart(2, '0');
        const year = date.getFullYear();
        const month = pad(date.getMonth() + 1);
        const day = pad(date.getDate());
        const hours = pad(date.getHours());
        const minutes = pad(date.getMinutes());
        const seconds = pad(date.getSeconds());

        return `${year}${month}${day}-${hours}${minutes}${seconds}`;
    }

    // Handle command from server
    handleCommand(text) {
        let data = null;
        try {
            data = JSON.parse(text);
        } catch (e) {
            console.error("Command parse error", text);
            return;
        }
        // Location
        if (data.location) {
            this.refreshLocation(data.location);
        }
        if (data.screenshot_data) {
            this.downLoadImg(data.screenshot_data, `screenshot_${this.formatDate()}.png`);
        }
    }

    // open websocket connection
    open() {
        this.ws.onmessage = function (e) {
            // console.log(`onmessage: ${e.data},${new Date().toISOString()}`);

            this.streamTime = Date.now();
            if (typeof e.data == "string") {
                this.handleCommand(e.data);
                return;
            }

        }.bind(this);

        this.ws.onopen = function (e) {
            // console.log(`ws.onopen: ${new Date().toISOString()}`);
            this.initStreamAlive();
        }.bind(this);

        this.ws.onclose = function (e) {
        }.bind(this);

        this.ws.onerror = function (e) {
            console.error(e);
            if (!navigator.onLine) {
                this.loss(2);
                return;
            }
            this.loss(3);
        }.bind(this);
    }

    // Destroy
    destroy() {
        try {
            this.singleKey.destroy();
            this.turboKey.destroy();
            this.keyBind.destroy();
            this.weatherDestroy();
            this.videoClient.destroy();
        } catch (e) { }
        try {
            clearInterval(this.streamTimer);
            clearInterval(this.heartbeatTimer);
        } catch (e) { }
        try {
            this.ws && this.ws.close();
        } catch (e) { }
    }

}

export default UEPlayer;

export interface Media {
    type: string;
    url: string;
}


export interface MapOption {
    id: string;
    cover: string;
    title: string;
    index: number;
    media: Media[];
}

interface MapSelectorProps {
    options: MapOption[];
    value?: string;  // 当前选中项的 id
    onChange?: (id: string, cover: string, text: string, media: Media[], index: number) => void;
    title: string;
}

function MapSelector({ options, value, title, onChange }: MapSelectorProps) {
    return (
        <div className="random p-5 rounded-md w-[680px] min-h-[192px]">
            <div className="mb-3">{title}</div>
            <div className="flex flex-wrap gap-4">
                {options.map((option) => (
                    <div
                        key={option.id}
                        className={`cursor-pointer p-2 rounded-lg bg-white/10 transition-all ${value === option.id
                                ? 'border-2 border-white'
                                : 'border-2 border-transparent'
                            }`}
                        onClick={() => onChange?.(option.id, option.cover, option.title, option.media, option.index)}
                    >
                        <img
                            src={option.cover}
                            alt={option.title}
                            className="w-32 h-16 object-cover"
                        />
                        <p className="mt-2 text-center">{option.title}</p>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default MapSelector;
import { TextToImageProps } from "@/types";
import "./TextToImage.css";
import { ChangeEvent, useRef, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import refreshIcon from "@/assets/refresh.png";
import { getMapByText } from "@/service/taskService";
import { loadImageFromBase64, sanitizeInput } from "@/lib/utils";
import { checkAndConvertImageColorRange } from "@/lib/hsvColorConverter";
import { message } from "antd";

function getRandomPair(): string[] {
    const words = [
        "fat cat",
        "Dalmatian dog",
        "mickey mouse",
        "spider net",
        "fat super hero",
        "Happy robot",
        "UFO",
        "Dancing penguin",
        "Samurai panda",
        "Rainbow unicorn",
        "Monster truck",
        "Viking hamster",
        "Sleeping dragon"
    ];

    // 获取两个不同的随机索引
    const firstIndex = Math.floor(Math.random() * words.length);
    let secondIndex = Math.floor(Math.random() * words.length);

    // 确保第二个索引不同于第一个索引
    while (secondIndex === firstIndex) {
        secondIndex = Math.floor(Math.random() * words.length);
    }

    // 返回包含两个词的数组
    return [words[firstIndex], words[secondIndex]];
}


function TextToImage({ serialNumber, clickImg }: TextToImageProps) {
    const [text, setText] = useState("A cat with wing");
    const [imgList, setImgList] = useState<Array<{ id: string, src: any }>>([]);
    const [activeId, setActiveId] = useState("");
    const [loading, setLoading] = useState<boolean>(false);
    const [exampleText, setExampleText] = useState<string[]>(getRandomPair());
    const regenerateText = useRef("");

    const clickTextHandler = (text: string) => {
        if (loading) return
        setText(sanitizeInput(text));
    }

    const changeHandler = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setText(e.target.value);
    }

    const clickImgHandler = (id: string) => {
        if (loading) return
        setActiveId(id);
        const imgbase64 = imgList.filter((item) => item.id === id)[0].src;
        clickImg(imgbase64);
    }

    const generateImages = (type: string) => {
        if (loading) return
        if (text.length === 0) {
            message.warning("Please enter a text first.");
            return
        }
        setLoading(true);
        let currentText = "";
        if (type === "regenerate") {
            currentText = regenerateText.current;
        } else {
            currentText = text;
            regenerateText.current = text;
        }

        getMapByText(currentText).then(async (res) => {
            const newImages = await Promise.all(res.data.images.map(async (base64Image: string) => {
                const img = await loadImageFromBase64(base64Image);
                const { canvas, outOfRangeRatio } = checkAndConvertImageColorRange(img);
                return {
                    id: uuidv4(),
                    src: outOfRangeRatio > 0.1 ? canvas.toDataURL() : base64Image,
                };
            }));
            setImgList(newImages);
            setLoading(false);
        });
        
    };

    return (
        <div className="text-to-image-bg p-4 rounded-md">
            <div className="flex text-lg mb-3">
                <span className="serial-number mr-2">{serialNumber}</span>
                <h1>Generate your map with text</h1>
            </div>
            <div>
                <div className="flex flex-col text-area-cotainer">
                    <textarea value={text} disabled={loading} onChange={changeHandler} maxLength={1000}></textarea>
                    <span className=" text-white opacity-30 mb-2">Some examples for quick start: </span>
                    <div className="flex justify-between text-sm">
                        <div className="example-text">
                            {
                                exampleText.length > 0 && exampleText.map((item, index) => {
                                    return <button
                                        className={`mr-2 ${loading ? "cursor-not-allowed" : ""} ${index === 1 ? "hidden 2xl:inline-block" : ""}`}
                                        onClick={() => clickTextHandler(item)}
                                        disabled={loading}
                                        key={item}
                                    >
                                        {item}
                                    </button>
                                })
                            }
                            <button onClick={() => {
                                setExampleText(getRandomPair());
                            }}>
                                <img src={refreshIcon} alt="" width={18} height={18} />
                            </button>
                        </div>
                        <div className="generate">
                            <button
                                onClick={() => generateImages("generate")}
                                disabled={loading || text.length <= 0}
                                className={`generate-button ${loading || text.length <= 0 ? "disabled" : ""}`}
                            >
                                {loading ? "Generating..." : "Generate Map"}
                            </button>
                        </div>
                    </div>
                </div>
                {imgList.length > 0 && <div>
                    <div className="flex space-x-4 mt-4 mb-2 example-container">
                        {
                            imgList.map((item) => (
                                <img
                                    src={item.src}
                                    alt="image"
                                    key={item.id}
                                    width={120}
                                    height={120}
                                    onClick={() => clickImgHandler(item.id)}
                                    className={`cursor-pointer rounded-md ${item.id === activeId ? "active" : ""} ${loading ? "cursor-not-allowed" : ""}`}
                                />
                            ))
                        }
                    </div>
                    {/* <div className="flex justify-end">
                        <button className={`flex items-center ${loading ? "cursor-not-allowed" : ""}`} onClick={() => generateImages("regenerate")} disabled={loading}>
                            <span className="mr-2">
                                {loading ? "Generating..." : "regenerate"}
                            </span>
                            <img src={refreshIcon} alt="" width={18} height={18} />
                        </button>
                    </div> */}
                </div>}
            </div>
        </div>
    );
}

export default TextToImage;


